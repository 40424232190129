import { ErrorHandler, Injectable } from '@angular/core';

declare var newrelic: any;

@Injectable({
  providedIn: 'root'
})

export class NewRelicErrorHandler extends ErrorHandler {

  handleError(error: any): void {
    newrelic.noticeError(error);
  }
}
