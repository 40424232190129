import { DocumentType } from './documenttype.model';

export class Client {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  documentNumber: string;
  documentType: DocumentType;
  idClientType: string;
  hasActiveBooking = false;
  activeBookingAttentionType: string;
  /*If null, normal scheduling flow*/
  channel: string;
}
