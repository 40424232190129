<app-loading *ngIf="processing"></app-loading>
<div class="registration d-flex flex-column flex-grow-1">
  <section class="bg-two-colors-media flex-grow-1 d-flex flex-column">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="container-fluid h-100 d-flex flex-column p-0">
        <div class="row h-100 m-0">
          <div class="col bg-main d-none d-lg-flex flex-column p-0">
            <div class="p-5" *ngIf="shouldShowBusinessHeader">
              <img src="assets/image/logo.svg" alt="">
            </div>
            <div class="flex-grow-1 d-flex bg-login"></div>
          </div>
          <div class="col-12 col-lg-6 text-center text-lg-left d-flex flex-column text-center p-0">
            <div class="d-none d-lg-flex pt-3 px-3 w-auto">
              <a href="" (click)="goBack()">
                <span class="fas fa-arrow-left fa-lg text-main"></span>
              </a>
            </div>
            <div class="bg-main d-flex d-lg-none py-3 px-1 p-lg-0">
              <a href="" (click)="goBack()" class="col-6 text-left">
                <span class="fas fa-arrow-left fa-lg text-white"></span>
              </a>
              <div class="col-6 d-flex row fit-content text-right text-white text-nowrap p-0 justify-content-end logout-label">
                <div class="text-right fit-content mr-2" ><span (click)="goBack()">Salir</span></div>
                <div class="d-flex fit-content text-right justify-content-end p-0" (click)="goBack()">
                  <span class="icon icon-file-export-solid-2 w-auto text-white"></span>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 d-flex flex-column justify-content-center p-3">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-8 col-sm-8 col-md-8">
                  <div class="bg-white rounded-box shadow-custom overflow-hidden p-4">
                    <div class="d-lg-flex justify-content-center">
                      <p class="mb-3 text-left text-black-50 mx-3 text-title title-custom-font custom-main-title">
                        {{'registration.title' | translate}}
                      </p>
                    </div>
                    <form action="" class="w-100 flex-grow-1 d-flex flex-column" [formGroup]="clientForm"
                      (ngSubmit)="onSubmit()">
                      <div class="row">
                        <div class="col-12 py-2 px-4">
                          <div
                            class="border rounded-input d-flex  align-items-center overflow-hidden mb-3 unclickeable input-icon">
                            <span class="icon icon-address-card-solid mr-1"></span>
                            <input type="text" class="form-input bg-transparent border-0 form-control input-only-read" id="documentNumber"
                              name="documentNumber"  [readonly]="true"
                              value="{{storedClient.documentType.description}}: {{storedClient.documentNumber}}"
                            >
                          </div>
                          <ng-container *ngIf="!isDocumentTypeCuit()">
                            <div
                              class="border rounded-input d-flex justify-content-between align-items-center overflow-hidden mb-3"
                              [ngClass]="{'has-error': hasError(firstName)}">
                              <input type="text" class="form-input form-control bg-transparent border-0 rounded-input" id="firstName"
                                name="firstName" formControlName="firstName" placeholder="{{'registration.firstName' | translate}}"
                                     alphasOnly [control]="firstName">
                            </div>
                            <h6 class="text-error" *ngIf="hasError(firstName)">
                              {{'registration.errors.validations.firstName' | translate}}
                            </h6>
                            <div
                              class="border d-flex justify-content-between align-items-center overflow-hidden mb-3 rounded-input"
                              [ngClass]="{'has-error': hasError(lastName)}">
                              <input type="text" class="form-input form-control bg-transparent border-0" id="lastName"
                                name="lastName" formControlName="lastName" placeholder="{{'registration.lastName' | translate}}"
                                     alphasOnly [control]="lastName">
                            </div>
                            <h6 class="text-error" *ngIf="hasError(lastName)">
                              {{'registration.errors.validations.lastName' | translate}}
                            </h6>
                          </ng-container>
                          <ng-container *ngIf="isDocumentTypeCuit()">
                            <div
                              class="border rounded-input d-flex justify-content-between align-items-center overflow-hidden mb-3"
                              [ngClass]="{'has-error': hasError(businessName)}">
                              <input type="text" class="form-input form-control bg-transparent border-0 form-control" id="businessName"
                                name="businessName" formControlName="businessName" placeholder="{{'registration.businessName' | translate}}"
                                     alphasOnly [control]="businessName">
                            </div>
                            <h6 class="text-error" *ngIf="hasError(businessName)">
                              {{'registration.errors.validations.businessName' | translate}}
                            </h6>
                          </ng-container>
                          <div
                            class="border rounded-input d-flex align-items-center overflow-hidden mb-3 input-icon"
                            [ngClass]="{'has-error': hasError(email)}">
                            <span class="icon icon-envelope-open"></span>
                            <input type="mail" class="form-input bg-transparent border-0 rounded-input form-control" id="email" name="email"
                              formControlName="email" placeholder="{{'registration.email' | translate}}">
                          </div>
                          <h6 class="text-error" *ngIf="hasError(email)">
                            {{'registration.errors.validations.email' | translate}}
                          </h6>
                          <div
                            class="border rounded-input overflow-hidden d-flex align-items-center overflow-hidden mb-3 input-icon"
                            [ngClass]="{'has-error': hasError(phoneNumber)}">
                            <span class="icon icon-mobile-android"></span>
                            <input type="text" class="form-input bg-transparent border-0 form-control" id="phoneNumber"
                              name="phoneNumber" formControlName="phoneNumber"
                              placeholder="{{'registration.phone' | translate}}"
                                   numbersOnly [control]="phoneNumber"
                            (keyup)="setPhoneNumberHasActiveBookingToFalse()">
                          </div>
                          <h6 class="text-error" *ngIf="hasError(phoneNumber)">
                            <div *ngIf="!phoneNumberHasActiveBooking; else activeBookingMessage">
                              {{'registration.errors.validations.phone' | translate}}
                            </div>
                            <ng-template #activeBookingMessage>
                              {{'registration.errors.validations.phoneNumber-with-active-booking' | translate}}
                            </ng-template>
                          </h6>
                          <h6 class="text-left mb-2 mt-n2 px-1 text-black-50">
                            {{'registration.phoneNumberFormat' | translate}}
                          </h6>
                          <ng-container *ngIf="notificationChannels?.length > 0">
                            <div class="d-flex align-items-start justify-content-center my-3 px-1 info-box p-3">
                              <span class="fas fa-exclamation-circle pr-2 text-sec"></span>
                              <h6 class="text-left info-text" [innerHTML]="(message)"></h6>
                            </div>
                          </ng-container>

                          <div class="row justify-content-center">
                            <div class="col-12 col-lg-12">
                              <button id="registerBtn" class="btn btn-block btn-main py-2" type="submit"
                                [disabled]="!clientForm.valid">
                                Continuar
                              </button>
                            </div>
                          </div>

                          <div class="text-error d-flex align-items-start justify-content-center my-3 px-1"
                            *ngIf="connectionError">
                            <h6 class="text-left">
                              {{'error.message' | translate}}
                            </h6>
                          </div>
                          <div class="verification-phone-error text-error d-flex align-items-start justify-content-center my-3 px-1" *ngIf="displayVerificationPhoneError">
                            <span class="fa fa-times-circle pr-2"></span>
                            <h6 class="text-left">
                              {{'registration.errors.maxTry' | translate}}, <strong>{{'registration.errors.tryTomorrow' | translate}}.</strong>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
