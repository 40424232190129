export interface QEEDTemplate {
  Atencion: string;
  Transaccion: string;
  CAC: string;
  Fecha_Hora: string;
  Departamento: string;
  Canal: string;
  Id_Agendamiento: number;
}

export const BaseURL = 'https://claroperu.sjc1.qualtrics.com/jfe/form/SV_8ptoTgIAsTsGx8y?Q_EED=';
