<div id="itemList" class="process-cont">
  <div class="h-100 w-100 col-12 px-0 process-item" *ngFor="let item of this.items"
       (click)="this.onRadioContainerItemClick(item)">
    <div [attr.id]="'item' + this.identityFn(item)" [ngClass]="this.isItemSelected(item) ? 'process-selected' : 'process-deselected'">
      <div class="process flex-grow-1 d-flex align-items-center p-1">
        <h5 class="d-flex flex-grow-1 mb-0 px-4">
          <div class="d-flex flex-grow-1 flex-column">
            <div class="d-flex flex-column custom-control custom-radio d-flex p-3">
              <div class="d-flex flex-grow-1 flex-column">
                <input type="radio" name="customRadio" id="item_{{this.identityFn(item)}}" class="custom-control-input"
                       [value]="item" [(ngModel)]="this.selectedItem" (ngModelChange)="this.onSelectItem()"/>
                <label class="custom-control-label fixed" for="item_{{this.identityFn(item)}}">{{this.descriptionFn(item)}}</label>
              </div>
              <div *ngIf="this.detailsFn" class="d-flex address text-light-14 mt-2 ml-n2">
                <span class="icon icon-lg icon-map-marker-alt-solid-2 text-center"></span>
                {{this.detailsFn(item)}}
              </div>
            </div>
          </div>
        </h5>
        <div *ngIf="locationFn" class="text-sec px-4 text-right location">
          <span>{{ this.locationFn(item) }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
