import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { Paths } from '../global';
import { customizations } from '../../web-customizations/customizations';

@Injectable()
export class TermsAndConditionsGuard implements CanActivate {

  constructor(private router: Router,
              private clientService: ClientService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const hasTermsAndConditions = customizations.termsAndConditions.shouldShow;
    const hasAcceptedTermsAndConditions = this.clientService.hasAcceptedTermsAndConditions();

    if ((hasTermsAndConditions && hasAcceptedTermsAndConditions) || !hasTermsAndConditions) {
      return true;
    }

    return this.router.parseUrl(Paths.home);
  }
}
