<app-loading *ngIf="this.loading"></app-loading>
<div class="home d-flex flex-column flex-grow-1">
  <section class="flex-grow-1 d-flex flex-column bg-two-colors-media">
    <div class="container-fluid text-white h-100 d-flex flex-column p-0">
      <div class="row h-100 m-0">
        <div class="col bg-main d-none d-lg-flex flex-column p-0">
          <div class="p-5" *ngIf="shouldShowBusinessHeader">
            <img src="assets/image/logo.svg" alt="">
          </div>
          <div class="flex-grow-1 d-flex bg-login"></div>
        </div>
        <div class="col-12 col-lg-6 text-center text-lg-left h-100 d-flex flex-column text-center p-0">
          <div
            class="bg-main d-flex d-lg-none flex-column align-items-center justify-content-center text-center pt-5 px-3 p-lg-0">
            <img src="assets/image/logo.svg" alt="" class="pt-4" *ngIf="shouldShowBusinessHeader">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <p class="text-main-login text-center p-3 mt-2 title-custom-font">
                  Bienvenido a Agenda de cita
                </p>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 d-flex flex-column px-3 h-100 home-box justify-content-center">
            <div class="row justify-content-center d-none d-lg-flex">
              <div class="col-12 col-lg-8">
                <p class="text-main-login text-center p-3 mb-lg-5 title-custom-font">
                  Bienvenido a Agenda de cita
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-7">
                <div class="bg-white rounded-home shadow-custom overflow-hidden p-4">
                  <form action="" class="w-100 flex-grow-1 d-flex flex-column">
                    <div class="row">
                      <div class="col-12 py-2 px-4">
                        <p class="text-light-16 text-main home-subtitle title-custom-font text-center mb-4 my-2 py-2">
                          Si tienes una cuenta Mi Claro, podrás agendar tu cita de menera fácil y rápida <br>
                          ¿deseas agendar tu cita por ahí?
                        </p>

                        <ng-container *ngIf="this.isMobileAndAvailableNativeAppOS()">
                          <div class="row justify-content-center">
                            <div class="col-12">
                              <button class="btn btn-block btn-main py-2 mt-3 mb-3"
                                      (click)="this.gotToNativeApp()">
                                Continuar por Mi Claro APP
                              </button>
                            </div>
                          </div>
                        </ng-container>

                        <!-- BC-1449 cliente solicita deshabilitar la opción

                        <div class="row justify-content-center">
                          <div class="col-12">
                            <button class="btn btn-block btn-main py-2 mt-3 mb-3"
                                    (click)="this.goToLoginWeb()">
                              Continuar por Mi Claro Web
                            </button>
                          </div>
                        </div>
                        -->

                        <div class="row justify-content-center">
                          <div class="col-12">
                            <button class="btn btn-block btn-sec-border py-2 mt-3 mb-3"
                                    (click)="this.goToHome()">
                              Continuar por Agenda tu cita
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
