import { ConfigService } from "../config/config.service";
/**
 * Servicio para obtener el valor de origen desde donde se accede a la aplicación de Agendamiento.
 * Nace con el objetivo de ser enviado como información a GTM para el cliente Entel.
 */
export abstract class OriginDetectorService {

    constructor(protected configService: ConfigService) { }

    /**
     * Obtiene el valor del origen guardado en sesión. En caso de no existir en sesión, se procede a obtenerlo invocando al
     * metodo getOrigin() de la implementación.
     * Si se obtiene, se guarda su valor en sesión y se retorna el resultado.
     * Si no se obtiene, se guarda un valor por defecto y se retorna el mismo valor por defecto.
     */
    inSessionOrGet(): string {
        const inSessionOrigin = this.configService.getOrigin();
        if (inSessionOrigin) {
            return inSessionOrigin;
        } else {
            const origin = this.getOrigin();
            if (origin) {
                this.configService.setOrigin(origin);
                return origin;
            }
            const defaultOrigin = this.defaultOrigin();
            this.configService.setOrigin(this.defaultOrigin());
            return defaultOrigin;
        }
    };

    /**
     * Obtiene el valor de origen según la implementación.
     */
    protected abstract getOrigin(): string;

    /**
     * Valor por defecto de origen según la implementación.
     */
    protected abstract defaultOrigin(): string;
}
