export const Paths = {
  home: 'home',
  registration: 'register',
  termsAndConditions: 'terms-and-conditions',
  phoneVerification: 'phone-verification',
  activeBooking: 'booking/active',
  cancellationByCode: 'booking/cancellation',
  attentionService: 'booking/attentionService',
  process: 'booking/process',
  attentionType: 'booking/attention-type',
  departmentSelection: 'booking/department-selection',
  bookingRoot: 'booking',
  nearbyCustomerCenter: 'booking/customerCenter/nearby',
  customerCenter: 'booking/customerCenter',
  bookingSchedule: 'booking/schedule',
  bookingConfirmation: 'booking/confirmation',
  error: 'error',
  confirmationReminder: 'confirmation',
  confirmationSuccess: 'confirmation/success',
  confirmationExpired: 'confirmation/expired',
  reminderActiveBooking: 'booking/active/confirmation',
  reminderActiveBookingSuccess: 'booking/active/confirmation-success',
  externalClient: 'booking/external-client/:identifier',
  welcome: 'welcome'
};
