import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ActiveBookingComponent } from './pages/active-booking/active-booking.component';
import { PhoneVerificationComponent } from './pages/phone-verification/phone-verification.component';
import { ProcessComponent } from './pages/process/process.component';
import { CustomerCenterComponent } from './pages/customer-center/customer-center.component';
import { BookingScheduleComponent } from './pages/booking-schedule/booking-schedule.component';
import { BookingConfirmationComponent } from './pages/booking-confirmation/booking-confirmation.component';
import { BookingSuccessComponent } from './pages/booking-success/booking-success.component';
import { ErrorComponent } from './pages/error/error.component';
import { Paths } from './global';
import { AuthGuard } from './security/auth.guard';
import { TermsAndConditionsGuard } from './security/terms-and-conditions.guard';
import { ActiveBookingGuard } from './security/active-booking.guard';
import { NoActiveBookingGuard } from './security/no-active-booking.guard';
import { RegistrationGuard } from './security/registration.guard';
import { PhoneVerificationEnabledGuard } from './security/phone-verification-enabled-guard.service';
import { PhoneVerifiedGuard } from './security/phone-verified.guard';
import { CustomerCenterGuard } from './security/customer-center.guard';
import { ScheduleGuard } from './security/schedule.guard';
import { BookingConfirmedGuard } from './security/booking-confirmed.guard';
import { BookingConfirmationGuard } from './security/booking-confirmation.guard';
import { ConfirmationReminderComponent } from './pages/confirmation-reminder/confirmation-reminder.component';
import { ConfirmationSuccessComponent } from './pages/confirmation-success/confirmation-success.component';
import { ConfirmationExpiredComponent } from './pages/confirmation-expired/confirmation-expired.component';
import { ReminderCancelGuard } from './security/reminder-cancel.guard';
import { ReminderSuccessGuard } from './security/reminder-success.guard';
import { ReminderActiveBookingComponent } from './pages/reminder-active-booking/reminder-active-booking.component';
import { ReminderActiveBookingSuccessComponent } from './pages/reminder-active-booking-success/reminder-active-booking-success.component';
import { ReminderActiveBookingGuard } from './security/reminder-active-booking.guard';
import { ClientDepartmentComponent } from './pages/client-location/client-department.component';
import { CanNavigateDepartmentSelectionGuard } from './security/can-navigate-department-selection.guard';
import { DepartmentSelectedGuard } from './security/department-selected.guard';
import { ExternalClientComponent } from './pages/external-client/external-client.component';
import { CpeWelcomePageComponent } from './pages/cpe-langing-page/cpe-welcome-page.component';
import { AttentionTypeComponent } from './pages/attention-type/attention-type.component';
import { AttentionTypeSelectedGuard } from './security/attention-type-selected-guard.service';
import { NearbyCustomerCentersComponent } from './pages/nearby-customer-centers/nearby-customer-centers.component';
import { BookingCancellationByCodeComponent } from './pages/booking-cancellation-by-code/booking-cancellation-by-code.component';
import { BookingCancellationByCodeGuard } from './security/cancellation-by-code.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: Paths.home,
    pathMatch: 'full'
  },
  {
    path: Paths.home,
    component: HomeComponent
  },
  {
    path: Paths.welcome,
    component: CpeWelcomePageComponent
  },
  {
    path: Paths.activeBooking,
    component: ActiveBookingComponent,
    canActivate: [
      AuthGuard,
      ActiveBookingGuard
    ]
  },
  {
    path: Paths.cancellationByCode,
    component: BookingCancellationByCodeComponent,
    canActivate: [
      AuthGuard,
      BookingCancellationByCodeGuard
    ]
  },
  {
    path: Paths.termsAndConditions,
    component: TermsAndConditionsComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard
    ]
  },
  {
    path: Paths.registration,
    component: RegistrationComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard
    ]
  },
  {
    path: Paths.phoneVerification,
    component: PhoneVerificationComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerificationEnabledGuard
    ]
  },
  {
    path: Paths.process,
    component: ProcessComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard
    ]
  },
  {
    path: Paths.attentionType,
    component: AttentionTypeComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard
    ]
  },
  {
    path: Paths.departmentSelection,
    component: ClientDepartmentComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard,
      CanNavigateDepartmentSelectionGuard,
      AttentionTypeSelectedGuard
    ]
  },
  {
    path: Paths.nearbyCustomerCenter,
    component: NearbyCustomerCentersComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard,
      CustomerCenterGuard,
      DepartmentSelectedGuard
    ]
  },
  {
    path: Paths.customerCenter,
    component: CustomerCenterComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard,
      CustomerCenterGuard,
      DepartmentSelectedGuard,
      AttentionTypeSelectedGuard
    ]
  },
  {
    path: Paths.bookingSchedule,
    component: BookingScheduleComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard,
      ScheduleGuard,
      DepartmentSelectedGuard,
      AttentionTypeSelectedGuard
    ]
  },
  {
    path: Paths.bookingConfirmation,
    component: BookingConfirmationComponent,
    canActivate: [
      AuthGuard,
      NoActiveBookingGuard,
      TermsAndConditionsGuard,
      RegistrationGuard,
      PhoneVerifiedGuard,
      BookingConfirmationGuard,
      DepartmentSelectedGuard,
      AttentionTypeSelectedGuard
    ]
  },
  {
    path: Paths.bookingRoot,
    component: BookingSuccessComponent,
    canActivate: [AuthGuard, NoActiveBookingGuard, BookingConfirmedGuard]
  },
  {
    path: Paths.externalClient,
    component: ExternalClientComponent
  },
  {
    path: Paths.confirmationReminder,
    component: ConfirmationReminderComponent
  },
  {
    path: Paths.confirmationSuccess,
    component: ConfirmationSuccessComponent,
    canActivate: [ReminderSuccessGuard]
  },
  {
    path: Paths.confirmationExpired,
    component: ConfirmationExpiredComponent,
    canActivate: [ReminderCancelGuard]
  },
  {
    path: Paths.reminderActiveBooking,
    component: ReminderActiveBookingComponent,
    canActivate: [ReminderActiveBookingGuard]
  },
  {
    path: Paths.reminderActiveBookingSuccess,
    component: ReminderActiveBookingSuccessComponent
  },
  {path: Paths.error, component: ErrorComponent},
  {path: '**', component: ErrorComponent, pathMatch: 'full'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    ActiveBookingGuard,
    NoActiveBookingGuard,
    TermsAndConditionsGuard,
    RegistrationGuard,
    PhoneVerificationEnabledGuard,
    PhoneVerifiedGuard,
    CustomerCenterGuard,
    ScheduleGuard,
    BookingConfirmationGuard,
    BookingConfirmedGuard,
    ReminderCancelGuard,
    ReminderSuccessGuard,
    ReminderActiveBookingGuard,
    CanNavigateDepartmentSelectionGuard,
    DepartmentSelectedGuard,
    BookingCancellationByCodeGuard,
    DepartmentSelectedGuard,
    AttentionTypeSelectedGuard
  ]
})
export class AppRoutingModule {
}
