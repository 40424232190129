<app-modal-cancel-booking></app-modal-cancel-booking>
<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="bg-main bg-gradient d-flex flex-column">
    <app-header [showGoBack]="false"></app-header>
    <div class="container-xl  d-flex flex-column my-3">
      <div class="d-flex flex-column justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column align-items-center align-self-center">
          <span class="icon icon-64 icon-white icon-check align-items-center w-auto"></span>
          <h1 class="my-2 text-white text-center custom-main-title">
            {{'success.title' | translate}}
          </h1>
          <ng-container *ngIf="notificationChannels?.length > 0">
            <p class="my-1 text-white text-center text-light title-custom-font" [innerHTML]="successNotificationInfo">
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 px-4">
          <div class="bg-white rounded-box shadow-custom overflow-hidden px-4 pb-4 pt-2 mt-2">
            <div *ngIf="reservation" class="d-flex flex-column">
              <div class="mt-4 d-flex flex-column justify-content-center">
                <h3 class="text-main text-center mb-2 summary-title"> {{'success.summaryTitle' | translate}}</h3>
                <div class="flex-column w-auto h-auto icon-primary">
                  <div id="dateContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-schedule-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'success.dateLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2 booking-date">
                          {{reservation.reservationDate | longDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="timeContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-clock-nine-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'success.timeLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2">
                          {{reservation.reservationDate | shortTime}}.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="processContainer" class="d-flex justify-content-center bd-highlight my-1"
                       *ngIf="showProcessInformation">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-clipboard-notes-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'success.reasonLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0 text-wrap text-break">
                        <p class="ml-3 ml-lg-0 mt-2">
                          {{reservation.process.name}} <br>
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container id="customerCenterContainer" *ngIf="!isVirtualBooking">
                    <div class="d-flex justify-content-center bd-highlight my-1">
                      <div class="d-flex col-12 col-sm-8">
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span
                            class="icon icon-lg icon-map-marker-alt-solid-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 px-1 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                            {{'success.customerCenterLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2">
                            {{reservation.customerCenter.name}} <br>
                            <small>{{buildCustomerCenterAddress(reservation.customerCenter)}}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container id="attentionTypeAndPhoneContainer" *ngIf="isVirtualBooking">
                    <div class="d-flex justify-content-center bd-highlight my-1">
                      <div class="d-flex col-12 col-sm-8">
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span
                            class="icon icon-lg icon-headphone d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                            {{'success.attentionTypeLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2">
                            {{'success.attentionTypeValue' | translate}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="this.shouldShowPhoneNumber()">
                      <div class="d-flex justify-content-center bd-highlight my-1">
                        <div class="d-flex col-12 col-sm-8">
                          <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                            <span
                              class="icon icon-lg icon-mobile-android d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 p-0"></span>
                            <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                              {{hiddenPhoneNumber()}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <ng-container *ngIf="shouldShowReminder()">
                  <div class="row d-flex py-2 justify-content-center">
                    <div *ngFor="let item of reminderInfo; index as i" class="col-12 col-md-10 my-1 d-flex flex-grow-1"
                         [ngClass]="{'justify-content-center': reminderInfo.length == 1}">
                      <div *ngIf="shouldShowDot(i)" class="color-dot mr-3 mt-2"></div>
                      <div class="d-flex w-auto text-left">
                        <p [innerHTML]="item"></p>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="shouldShowVirtualReminder()">
                  <div class="flex-grow-1 d-flex py-2 justify-content-center">
                    <div class="col-12 col-sm-10 my-1 px-lg-5 ml-lg-2 d-flex flex-grow-1 justify-content-center">
                      <div class="color-dot mr-3 mt-2"></div>
                      <div class="d-flex w-auto text-left">
                        <p class="text-light-16" [innerHTML]="('success.virtualReminder') | translate"></p>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="shouldShowSurvey()">
                  <div class="row py-2 justify-content-center">
                    <div class="mr-3 mt-2"></div>
                    <p [innerHTML]=surveyURL></p>
                  </div>
                </ng-container>

                <ng-container *ngIf="shouldShowSuccessInfo()">
                  <div class="row justify-content-center p-3">
                    <div class="col-12 col-lg-8 info-box p-3">
                      <h4 class="text-center info-text p-1 " [innerHTML]="successInfo"></h4>
                    </div>
                  </div>
                </ng-container>

                <ng-container [ngSwitch]="this.businessClient">
                  <app-cpe-booking-success-mobile-buttons *ngSwitchCase="'cpe'">
                  </app-cpe-booking-success-mobile-buttons>

                  <app-default-booking-success-mobile-buttons *ngSwitchDefault
                                                              [showCancelModal]="this.showCancelModal">
                  </app-default-booking-success-mobile-buttons>
                </ng-container>

              </div>
            </div>
          </div>

          <ng-container [ngSwitch]="this.businessClient">
            <app-cpe-booking-success-desk-buttons *ngSwitchCase="'cpe'">
            </app-cpe-booking-success-desk-buttons>

            <app-default-booking-success-desk-buttons *ngSwitchDefault
                                                      [showCancelModal]="this.showCancelModal">
            </app-default-booking-success-desk-buttons>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</section>
