import { ConfigService } from './config.service';
import { BookingsService } from '../bookings/bookings.service';
import { ConfigResponse } from '../../model/config/configResponse.model';
import { Injectable } from '@angular/core';
import { ClientService } from '../client/client.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  constructor(private configService: ConfigService,
              private bookingService: BookingsService,
              private clientService: ClientService) {
  }

  loadConfiguration(onSuccessCallback: (response?: ConfigResponse) => void,
                    onErrorCallback: () => void,
                    onCompleteCallback?: () => void): void {
    this.bookingService
      .loadConfig()
      .pipe(finalize(
        () => {
          if (onCompleteCallback) {
            onCompleteCallback();
          }
        }))
      .subscribe(
        (response: ConfigResponse) => {
          this.saveConfig(response);
          onSuccessCallback(response);
        },
        onErrorCallback
      );
  }

  private saveConfig(response: ConfigResponse): void {
    this.clientService.setDocTypes(response.availableDocumentTypes);
    this.clientService.setCountryPhoneRule(response.phonePattern);
    this.configService.setRegistrationNotificationChannels(response.registrationNotificationChannels);
    this.configService.setResendRegistrationNotificationChannels(response.resendRegistrationNotificationChannels);
    this.configService.setCancellationCodeNotificationChannels(response.cancellationCodeNotificationChannels);
    this.configService.setUniquePhoneNumberRestriction(response.uniquePhoneNumberEnabled);
    this.configService.setTimeZone(response.timeZone);
    this.configService.setShouldVerifyPhoneNumber(response.verifyPhoneNumber);
    this.configService.setDepartmentSelectionEnabled(response.departmentSelectionEnabled);
    this.configService.setExternalClientIntegrationEnabled(response.externalClientIntegrationEnabled);
    this.configService.setNearbyCustomerCentersEnabled(response.nearbyCustomerCentersEnabled);
    this.configService.setBookingCancellationCodeEnabled(response.bookingCancellationCodeEnabled);
    this.configService.setAppBackVersion(response.appVersion);
  }
}
