import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { ConfigService } from "../../config/config.service";
import { OriginDetectorService } from "../origin-detector.service";

export class EntelCLOriginDetectorService extends OriginDetectorService {

    constructor(private activatedRoute: ActivatedRoute,
        configService: ConfigService) {
        super(configService);
    }

    protected getOrigin(): string {
        return this
            .activatedRoute
            .snapshot
            .queryParamMap
            .get('origen');
    }

    protected defaultOrigin(): string {
        return environment.defaultOrigin;
    }
}