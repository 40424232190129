import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { Reservation } from '../../model/reservation/reservation.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private readonly reservationStorage: string = 'reservationStorage';
  private readonly editingStorage: string = 'editingStorage';
  private readonly processInformation: string = 'processInformationStorage';

  constructor(private sessionStorageService: SessionStorageService) {
  }

  getReservation(): Reservation {
    let reservation: Reservation = this.sessionStorageService.getItem(this.reservationStorage);

    if (reservation && reservation.reservationDate) {
      reservation.reservationDate = new Date(reservation.reservationDate);
    }
    return reservation;
  }

  setReservation(reservation: Reservation) {
    this.sessionStorageService.setItem(this.reservationStorage, reservation)
  }

  clearReservation() {
    this.sessionStorageService.removeItem(this.reservationStorage);
  }

  setEditing() {
    this.sessionStorageService.setItem(this.editingStorage, true);
  }

  isEditing() {
    return this.sessionStorageService.getItem(this.editingStorage);
  }

  clearEditing() {
    this.sessionStorageService.removeItem(this.editingStorage);
  }

  getProcessInformation(){
    return this.sessionStorageService.getItem(this.processInformation);
  }

  showProcessInformation() {
    this.sessionStorageService.setItem(this.processInformation, true);
  }

  hideProcessInformation() {
    this.sessionStorageService.setItem(this.processInformation, false);
  }
}
