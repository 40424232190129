import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { Paths } from '../global';
import { ConfigService } from '../services/config/config.service';
import { IllegalComponentBehaviourNotifier } from '../utils/illegal-component-behaviour-notifier.service';

@Injectable()
export class RegistrationGuard implements CanActivate {

  constructor(private router: Router,
              private clientService: ClientService,
              private configService: ConfigService,
              private illegalComponentBehaviourNotifier: IllegalComponentBehaviourNotifier) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const externalClientIntegrationEnabled = this.configService.getExternalClientIntegrationEnabled();
    const externalClientIdentifier = this.clientService.getExternalClientIdentifier();
    const accessFromExternalClientIntegration = this.configService.getAccessFromExternalClientIntegration();

    if (externalClientIntegrationEnabled && accessFromExternalClientIntegration) {
      if (externalClientIdentifier) {
        return true;
      } else {
        this.illegalComponentBehaviourNotifier.notifyIllegalGuardAccess(
          'RegistrationGuard',
          this.router.url,
          'ExternalClient integration flag enabled but no identifier detected');
        return this.router.parseUrl(Paths.home);
      }
    }

    const newClient = this.clientService.getNewClient();

    if (newClient
      && newClient.docType
      && newClient.docNumber
      && newClient.phoneNumber
      && newClient.email
      && (newClient.businessName || (newClient.firstName && newClient.lastName))) {
      return true;
    }

    this.illegalComponentBehaviourNotifier.notifyIllegalGuardAccess(
      'RegistrationGuard',
      this.router.url,
      'No client information in session');
    return this.router.parseUrl(Paths.home);
  }
}
