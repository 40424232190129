import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ReservationService } from '../services/reservation/reservation.service';
import { Paths } from '../global';


@Injectable()
export class BookingConfirmedGuard implements CanActivate {

  constructor(private router: Router, private reservationService: ReservationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const booking = this.reservationService.getReservation();

    if (booking
      && booking.client
      && booking.process
      && booking.customerCenter
      && booking.reservationDate
      && booking.currentlyConfirmed) {
      return true;
    }

    return this.router.parseUrl(Paths.home);
  }
}
