import { Component, HostBinding } from '@angular/core';
import { ClientService } from 'src/app/services/client/client.service';
import { NewClientRequest } from 'src/app/model/client/newClientRequest.model';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html'
})
export class PhoneVerificationComponent {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  client: NewClientRequest;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.client = this.clientService.getNewClient();
  }
}
