import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ConfigService } from '../services/config/config.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Se elimino variable constante timeZone debido a que con las nuevas inyecciones requeridas por los GTM Publisher de Entel
 * esta clase se construye antes de que se cargue en sesion el valor del timeZone.
 * Por esta razón, el primer llamado a un método de esta utilidad, siempre encontraba su valor en nulo.
 *
 * Invocar la variable timeZone directamente desde el sessionStorage soluciona parcialmente el error, ya que no garantiza
 * de ninguna forma que su valor haya sido guardado previamente.
 *
 * No debería inyectarse ni utilizarse esta clase en un ciclo de vida de la aplicación anterior a la carga del timeZone
 * en sesion. (Home Page)
 */
export class DateUtils {

  constructor(private configService: ConfigService,
              private datePipe: DatePipe) {
  }

  toZonedDate(date: Date): Date {
    return new Date(this.toZonedMoment(date).format('YYYY/MM/DD HH:mm'));
  }

  toZonedDateAtStartOfDay(date: string): Date {
    const utcDate = this.toZonedMoment(date);
    return new Date(utcDate.startOf('day').format('YYYY/MM/DD HH:mm'));
  }

  icsCalendarDateFormat(date: Date) {
    const d = new Date(date);
    return `${moment(d).utc().format('YYYYMMDDTHHmmss')}Z`;
  }

  newDateToISOString() {
    return new Date().toISOString();
  }

  toZonedDateWithFormat(date: Date, format: string) {
    return this.datePipe.transform(date, format, this.configService.getTimeZone());
  }

  private toZonedMoment(date: any): moment.Moment {
    return moment.utc(date).tz(this.configService.getTimeZone());
  }
}
