import { Injectable } from '@angular/core';
import { ExternalClientService } from '../../../../services/externalClient/external-client.service';
import { ExternalClientChannel } from '../../../../services/externalClient/model/ExternalClientChannel';
import { ErrorPageExitButtonVisibility } from './error-page-exit-button-visibility';
import { DeviceDetectorService } from '../../../../utils/device-detector.service';

@Injectable()
export class CPEErrorPageExitButtonVisibility extends ErrorPageExitButtonVisibility {

  constructor(private externalClientService: ExternalClientService,
              private deviceDetectorService: DeviceDetectorService) {
    super();
  }

  /**
   * Validación CPE para mostrar u oculutar el botón Salir:
   *
   * 1 - Si se trata de un Agendamiento Express (Flujo normal), el botón debe ser visible.
   * 2 - Si se trata de un Agendamiento Integrado:
   * 2.a - Si el cliente ingreso desde la APP WEB, no hay un comportamiento definido de salida, no debe mostrarse.
   * 2.b - Si el cliente ingreso desde una APP NATIVA:
   * 2.b.1 - Si el dispositivo es Android o IOS el botón se muestra.
   * 2.b.2 - Si el dispositivo no es Android o IOS, se trata de un escenario no contemplado. Se opta por ocultar el botón.
   * 2.c - Si el canal del cliente no es APP_NATIVA o APP_WEB, se trata de un escenario no contemplado, que nunca debería
   * suceder. Se opta por ocultar el botón.
   */
  showExitButton(): boolean {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      return this.externalClientService.clientChannel() === ExternalClientChannel.NATIVE_APP
        && (this.deviceDetectorService.isIOS() || this.deviceDetectorService.isAndroid());
    } else {
      return true;
    }
  }
}
