export abstract class Loading {

  loading = false;

  showLoading(): void {
    this.loading = true;
  }

  hideLoading(): void {
    this.loading = false;
  }

  isLoading(): boolean {
    return this.loading;
  }
}
