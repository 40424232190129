import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Reservation } from '../../model/reservation/reservation.model';
import { customizations } from '../../../web-customizations/customizations';
import { BookingsService } from 'src/app/services/bookings/bookings.service';
import { ClientService } from 'src/app/services/client/client.service';
import { isVirtualBooking } from '../../utils/Utils';
import { Client } from '../../model/client/client.model';
import { ConfigService } from '../../services/config/config.service';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { NotificationChannel } from 'src/app/model/config/notificationChannel.model';
import { getConcatenatedWithAndOne, getConcatenatedWithOr, getNameFrom } from 'src/app/utils/joiners/MessageJoiner';
import { ModalCancelBookingComponent } from '../modal-cancel-booking/modal-cancel-booking.component';
import { ResendNotificationRequest } from '../../services/bookings/http/ResendNotificationRequest';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html'
})
export class BookingDetailsComponent implements OnInit {

  @Input() reservation: Reservation;
  @ViewChild(ModalCancelBookingComponent) modalCancelBooking: ModalCancelBookingComponent;
  client: Client;
  isVirtualBooking: boolean;
  messageSent = false;
  resendNotificationChannels: NotificationChannel[];
  registerNotificationChannels: NotificationChannel[];
  resendRequested = false;
  resending = false;
  detailInfo = '';
  contactInfo = '';
  resendButtonMessage = '';
  lowerInfo = '';
  retryMessage = '';

  readonly showLowerInfo = customizations.active.showLowerInfo;

  constructor(private bookingService: BookingsService,
              private clientService: ClientService,
              private configService: ConfigService,
              private translateService: TranslateService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loadNotificationChannels();
    this.resolveMessages();
    this.resolveLowerInfoMessage();
    this.client = this.clientService.getClient();
    this.isVirtualBooking = isVirtualBooking(this.client.activeBookingAttentionType);
  }

  resendNotification() {
    this.resending = true;
    this.resendRequested = true;
    const resendNotificationRequest = new ResendNotificationRequest(this.client.documentType.enumName, this.client.documentNumber);
    this.bookingService.resendNotification(resendNotificationRequest)
      .subscribe(
        () => {
          this.messageSent = true;
          this.resending = false;
        },
        (e) => {
          if (e.status === 504) {
            this.messageSent = false;
            this.resending = false;
          } else {
            this.router.navigate([Paths.error]);
          }
        }
      );
  }

  retryResendNotification(event) {
    event.preventDefault();
    this.resendNotification();
  }

  hasChannels() {
    return this.resendNotificationChannels?.length > 0;
  }

  hasContactInfo(): boolean {
    return this.contactInfo.length > 0;
  }

  hasRegisterNotificationChannels() {
    return this.registerNotificationChannels?.length > 0;
  }

  private loadNotificationChannels() {
    this.resendNotificationChannels = this.configService.getResendRegistrationNotificationChannels();
    this.registerNotificationChannels = this.configService.getRegistrationNotificationChannels();
  }

  private resolveMessages() {
    if (this.hasMoreThatOneChannel()) {
      this.setMultiChannelInfoMessage();
      this.setMultiChannelRetryMessage();
    } else if (this.isSingleChannel()) {
      const channel = this.singleChannel();
      const channelParam = {
        channel: getNameFrom(channel)
      };
      this.setSingleChannelInfoMessages(channel);
      this.setSingleChannelResendButtonMessage(channelParam);
      this.setSingleChannelRetryMessage(channelParam);
    }
  }

  private setMultiChannelInfoMessage() {
    const channelParam = {
      channels: getConcatenatedWithAndOne(this.resendNotificationChannels)
    };
    this.translateService
      .get('details.multiInfo', channelParam)
      .subscribe(msg => this.detailInfo = msg);
    this.translateService
      .get('details.multiChannelButtonMessage')
      .subscribe(msg => this.resendButtonMessage = msg);
  }

  private setMultiChannelRetryMessage() {
    this.translateService
      .get('details.retry.multi')
      .subscribe(msg => this.retryMessage = msg);
  }

  private setSingleChannelRetryMessage(channelParam: any) {
    this.translateService
      .get('details.retry.single', channelParam)
      .subscribe(msg => this.retryMessage = msg);
  }

  private setSingleChannelInfoMessages(channel: NotificationChannel) {
    this.translateService
      .get(`details.info.${channel}`)
      .subscribe(msg => this.detailInfo = msg);
    switch (channel) {
      case NotificationChannel.SMS:
        this.contactInfo = this.reservation.client.phoneNumber;
        break;
      case NotificationChannel.EMAIL:
        this.contactInfo = this.reservation.client.email;
        break;
    }
  }

  private setSingleChannelResendButtonMessage(channelParam: any) {
    this.translateService
      .get('details.singleChannelButtonMessage', channelParam)
      .subscribe(msg => this.resendButtonMessage = msg);
  }

  private isSingleChannel(): boolean {
    return this.resendNotificationChannels?.length === 1;
  }

  private hasMoreThatOneChannel(): boolean {
    return this.resendNotificationChannels?.length > 1;
  }

  private singleChannel(): NotificationChannel {
    return this.resendNotificationChannels[0];
  }

  private resolveLowerInfoMessage() {
    if (this.hasRegisterNotificationChannels()) {
      const channelParam = {
        channels: getConcatenatedWithOr(this.registerNotificationChannels)
      };
      this.translateService
        .get('details.info', channelParam)
        .subscribe(msg => this.lowerInfo = msg);
    }
  }

  showCancelModal = () => {
    this.modalCancelBooking.show();
  }
}
