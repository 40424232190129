import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BookingsService } from '../../services/bookings/bookings.service';
import { ClientService } from '../../services/client/client.service';
import { Paths } from '../../global';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { ModalsComponent } from '../modals/modals.component';
import { CancelBookingRequest } from '../../services/bookings/http/CancelBookingRequest';
import { Observable } from 'rxjs';
import { ExternalClientService } from '../../services/externalClient/external-client.service';
import { CancelExternalClientBookingRequest } from '../../services/bookings/http/CancelExternalClientBookingRequest';
import { BookingsCaptchaService } from '../../services/captcha/BookingsCaptchaService';

declare var $: any;

@Component({
  selector: 'app-modal-cancel-booking',
  templateUrl: './modal-cancel-booking.component.html'
})
export class ModalCancelBookingComponent {
  constructor(
    private router: Router,
    private bookingsService: BookingsService,
    private clientService: ClientService,
    private externalClientService: ExternalClientService,
    private sessionStorageService: SessionStorageService,
    private bookingsCaptchaService: BookingsCaptchaService,
  ) {
  }

  @ViewChild(ModalsComponent) defaultModal: ModalsComponent;
  readonly modalId: string = 'modalcanceldate';

  @Input() encryptedId: string;

  public show(): void {
    this.defaultModal.show();
  }

  public cancelBookingOnClick(): void {
    this.bookingsCaptchaService
      .execute('CancelBooking',
        this.onCancelBooking,
        this.goToError);
  }

  private onCancelBooking = (): void => {
    this.defaultModal.hide();

    const service = this.resolveServiceUrl();

    service.subscribe(
      () => {
        this.resolveRedirection();
      },
      () => {
        this.router.navigate([Paths.error]);
      }
    );
  }

  private resolveServiceUrl(): Observable<any> {
    if (this.encryptedId) {
      return this.bookingsService.cancelBookingByEncryptedId(this.encryptedId);
    } else if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const request = new CancelExternalClientBookingRequest(this.clientService.getExternalClientIdentifier());
      return this.bookingsService.cancelExternalClientBooking(request);
    } else {
      const client = this.clientService.getClient();
      const request = new CancelBookingRequest(client.documentType.enumName, client.documentNumber);
      return this.bookingsService.cancelBooking(request);
    }
  }

  private resolveRedirection(): void {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const identifier = this.clientService.getExternalClientIdentifier();
      this.sessionStorageService.clearSessionStorage();

      this.router.navigate([Paths.externalClient, {identifier: identifier}]);
    } else {
      this.sessionStorageService.clearSessionStorage();
      this.router.navigate([Paths.home]);
    }
  }

  private goToError = (): void => {
    this.router.navigate([Paths.error]);
  }
}
