import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DateUtils } from '../date-utils.service';

@Pipe({
  name: 'longDate'
})
export class DateToStringPipe implements PipeTransform {

  readonly locale: string;

  constructor(private dateUtils: DateUtils) {
    this.locale = environment.locale;
  }

  // Output format example: "Lunes 25 de Marzo"
  transform(date: Date): string {
    return this.getDateAsLongString(date);
  }

  private getDateAsLongString(date: Date): string {
    const zonedDate = this.dateUtils.toZonedDate(date);
    const dayName = zonedDate.toLocaleString(this.locale, {weekday: 'long'}).replace(/^[a-z]/, function (x) {
      return x.toUpperCase();
    });
    const dayNumber = zonedDate.getDate();
    const monthName = zonedDate.toLocaleString(this.locale, {month: 'long'}).replace(/^[a-z]/, function (x) {
      return x.toUpperCase();
    });
    return dayName + ' ' + dayNumber + ' de ' + monthName;
  }
}
