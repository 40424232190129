<div *ngIf="this.showFinalizeButton()" class="row justify-content-center">
  <div
    class="flex-grow-1 d-flex row d-lg-none justify-content-center btn-cont mt-3">
    <button class="btn btn-main d-flex justify-content-center align-items-center col-10"
            (click)="this.finalize()">
      Finalizar
    </button>
  </div>
</div>
<div *ngIf="this.showAddToCalendarButton()" class="row justify-content-center">
  <div
    class="flex-grow-1 d-flex row d-lg-none justify-content-center btn-cont mt-3">
    <button
      [ngClass]="this.showFinalizeButton() ? 'btn-link' : 'btn-main'"
      class="btn d-flex justify-content-center align-items-center col-10"
      (click)="this.addToCalendar()">
      <span
        class="icon icon-lg icon-save-on-calendar w-auto d-flex align-self-center mr-2"
        [ngClass]="{'text-white':!this.showFinalizeButton()}"></span>
      Agregar a tu calendario
    </button>
  </div>
</div>
