import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCancelBookingComponent } from 'src/app/components/modal-cancel-booking/modal-cancel-booking.component';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { ReminderResponse } from 'src/app/model/booking/reminderResponse.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Paths } from 'src/app/global';

@Component({
  selector: 'app-confirmation-success',
  templateUrl: './confirmation-success.component.html'
})
export class ConfirmationSuccessComponent implements OnInit {
  @ViewChild(ModalCancelBookingComponent)
  cancelModal: ModalCancelBookingComponent;

  reminderInformation: ReminderResponse;
  encryptedId: string;
  date: Date;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.reminderInformation = this.sessionStorageService.getItem('reminderInfo');
    if (!this.reminderInformation) {
      this.homePageRedirection();
    }
    this.encryptedId = this.activatedRoute.snapshot.queryParamMap.get('t');
    this.buildDate(this.reminderInformation);
  }

  buildDate(info: ReminderResponse) {
    this.date = new Date (info.from);
  }

  public attentionTypeIsVirtual(): boolean {
    return this.reminderInformation.attentionType === 'VIRTUAL';
  }

  homePageRedirection() {
    this.sessionStorageService.clearSessionStorage();
    this.router.navigate([Paths.home]);
  }

  showCancelModal() {
    this.cancelModal.show();
  }

}
