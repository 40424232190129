import { Router } from '@angular/router';
import { ExternalClientService } from '../../../../services/externalClient/external-client.service';
import { DeviceDetectorService } from '../../../../utils/device-detector.service';
import { CPEOuterFunctionService } from '../../../../utils/cpe-outer-function.service';
import { IllegalComponentBehaviourNotifier } from '../../../../utils/illegal-component-behaviour-notifier.service';
import { ErrorPageExitButtonBehaviour } from './error-page-exit-button-behaviour';
import { environment } from '../../../../../environments/environment';
import { CPEErrorPageExitButtonBehaviour } from './cpe-error-page-exit-button-behaviour';
import { DefaultErrorPageExitButtonBehaviour } from './default-error-page-exit-button-behaviour';

export function errorPageExitButtonBehaviourFactory(router: Router,
                                                    externalClientService: ExternalClientService,
                                                    deviceSelectorService: DeviceDetectorService,
                                                    cpeOuterFunctionService: CPEOuterFunctionService,
                                                    icbNs: IllegalComponentBehaviourNotifier): ErrorPageExitButtonBehaviour {

  if (environment.environmentName === 'cpe') {
    return new CPEErrorPageExitButtonBehaviour(
      router,
      externalClientService,
      deviceSelectorService,
      cpeOuterFunctionService,
      icbNs);
  }
  return new DefaultErrorPageExitButtonBehaviour(router);
}
