import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BookingCancellationCodeRequest } from '../../services/bookings/http/BookingCancellationCodeRequest';
import { delay, finalize } from 'rxjs/operators';
import { Paths } from '../../global';
import { Client } from '../../model/client/client.model';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client/client.service';
import { BookingsService } from '../../services/bookings/bookings.service';
import { Loading } from '../../utils/interfaces/loading';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BookingCancellationByCodeRequest } from '../../services/bookings/http/BookingCancellationByCodeRequest';
import { ConfigService } from '../../services/config/config.service';
import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { customizations } from '../../../web-customizations/customizations';
import { Joiner } from '../../utils/joiners/Joiner';
import { concatenateStringsWithJoiner, getNameFrom } from '../../utils/joiners/MessageJoiner';
import { SingleButtonModalComponent } from '../modals/single-button-modal/single-button-modal.component';
import { ExternalClientService } from '../../services/externalClient/external-client.service';
import { ExternalClientBookingCancellationCodeRequest } from '../../services/bookings/http/ExternalClientBookingCancellationCodeRequest';
import { ExternalClientBookingCancellationByCodeRequest } from '../../services/bookings/http/ExternalClientBookingCancellationByCodeRequest';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';

@Component({
  selector: 'app-cancel-booking-codebox',
  templateUrl: './cancel-booking-codebox.component.html'
})
export class CancelBookingCodeboxComponent extends Loading implements OnInit, OnDestroy {

  @ViewChild('modalDialog') modalDialog: SingleButtonModalComponent;

  private readonly channelsJoiner: Joiner = customizations.cancellationByCode.channelsJoiner;
  private readonly resendDelay = 1000 * 15;
  private resendEnabler$ = new BehaviorSubject('');
  private resendEnablerSubscription: Subscription;

  client: Client;
  code: string
  channels: NotificationChannel[];
  splitCode: string[] = new Array(6);
  errorStatus: string;
  enableResendOption: boolean = false;

  constructor(private router: Router,
              private configService: ConfigService,
              private clientService: ClientService,
              private externalClientService: ExternalClientService,
              private bookingsService: BookingsService,
              private sessionStorageService: SessionStorageService) {
    super();
  }

  ngOnInit(): void {
    this.client = this.clientService.getClient();
    this.channels = this.configService.getCancellationCodeNotificationChannels();
    this.requestNewCode();

    this.resendEnablerSubscription = this.resendEnabler();
  }

  ngOnDestroy(): void {
    this.resendEnablerSubscription.unsubscribe();
  }

  private resendEnabler = () => {
    return this.resendEnabler$.pipe(
      delay(this.resendDelay)
    ).subscribe(_ => this.enableResendOption = true);
  }

  getChannelsAndContact(): any {
    const channels = this.channels
      .map(channel => this.formatChannel(channel));

    return {
      channels: concatenateStringsWithJoiner(this.channelsJoiner, channels)
    };
  }

  private formatChannel(channel: NotificationChannel) {
    const clientChannelContact = this.getClientChannelContact(this.client, channel);

    if (clientChannelContact) {
      return `${getNameFrom(channel)} (${clientChannelContact})`
    }

    return getNameFrom(channel);
  }

  private getClientChannelContact(client: Client, channel: NotificationChannel) {
    switch (channel) {
      case NotificationChannel.SMS:
      case NotificationChannel.WHATSAPP: {
        return client.phoneNumber;
      }
      case NotificationChannel.EMAIL: {
        return client.email;
      }
      default: {
        return null;
      }
    }
  }

  requestNewCode(): void {
    this.showLoading();
    this.resetError();

    this.resolveRequestCodeUrl()
      .pipe(finalize(() => this.afterNewCode()))
      .subscribe(response => {
        if (response.code) {
          this.splitCode.fill('');
          this.fillCodeInput(response.code);
        } else {
          this.errorStatus = response.status;

          if (!this.hasReachedMaxAttempts()) {
            this.navigateToError();
          }
        }
      }, () => this.navigateToError())
  }

  private resolveRequestCodeUrl() {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const externalClientRequest = new ExternalClientBookingCancellationCodeRequest(this.clientService.getExternalClientIdentifier());
      return this.bookingsService.requestExternalClientCodeToCancel(externalClientRequest);
    }

    const request = new BookingCancellationCodeRequest(
      this.client.documentType.enumName,
      this.client.documentNumber);

    return this.bookingsService.requestCodeToCancel(request);
  }

  private fillCodeInput(code: string) {
    this.code = code;
    [...code].forEach((pV, index) => this.splitCode[index] = pV);
  }

  private afterNewCode = () => {
    this.hideLoading();
    this.enableResendOption = false;
    this.resendEnabler$.next('');
  }

  resendVerificationCode = (event) => {
    event.preventDefault();
    this.requestNewCode();
  }

  cancelBooking() {
    this.code = this.splitCode.join('');

    this.requestCancelBooking();
  }

  private requestCancelBooking() {
    this.showLoading();
    this.resolveCancelByCodeUrl()
      .pipe(finalize(() => this.hideLoading()))
      .subscribe(response => {
          switch (response.status) {
            case 'CANCELLED': {
              this.modalDialog.success();
              break;
            }
            case 'CODE_EXPIRED':
            case 'INVALID_CODE':
            case 'MAX_ATTEMPTS_REACHED': {
              this.errorStatus = response.status
              break;
            }
            default: {
              this.navigateToError();
            }
          }
        },
        e => {
          this.navigateToError();
        }
      )
  }

  private resolveCancelByCodeUrl() {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const externalClientRequest = new ExternalClientBookingCancellationByCodeRequest(
        this.clientService.getExternalClientIdentifier(),
        this.code);
      return this.bookingsService.externalClientCancelByCode(externalClientRequest);
    }

    const request = new BookingCancellationByCodeRequest(
      this.client.documentType.enumName,
      this.client.documentNumber,
      this.code);

    return this.bookingsService.cancelByCode(request);
  }

  hasReachedMaxAttempts(): boolean {
    return this.errorStatus === 'MAX_ATTEMPTS_REACHED';
  }

  resolveRedirection = (): void => {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const identifier = this.clientService.getExternalClientIdentifier();
      this.sessionStorageService.clearSessionStorage();

      this.router.navigate([Paths.externalClient, {identifier: identifier}]);
    } else {
      this.sessionStorageService.clearSessionStorage();
      this.router.navigate([Paths.home], { replaceUrl: true });
    }
  }

  private navigateToError(): void {
    this.router.navigate([Paths.error]);
  }

  get isCodeComplete(): boolean {
    const completeCode = this.splitCode.join('');
    return this.isNumber(completeCode) && completeCode.length === 6;
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipBoardData = event.clipboardData?.getData('text') || '';
    this.autocomplete(clipBoardData);
  }

  autocomplete(data: string): void {
    if (data.length == 1) {
      return;
    }

    let newCode;

    switch (data.length) {
      case 3: {
        newCode = this.code.substr(0, 3) + data;
        break;
      }
      case 6: {
        newCode = data;
        break;
      }
      default: {
        return;
      }
    }

    this.fillCodeInput(newCode);
  }

  autoTab(currentPosition: number, afterDeletePosition: number, afterChangePosition: number) {
    if (this.splitCode.join('').length < 6) {
      const target = this.itemIsEmpty(currentPosition) ? afterDeletePosition : afterChangePosition;
      this.focusItem(target);
    }

    this.resetError();
  }

  private focusItem(target: number) {
    const element = document.getElementById(`code${target}`);
    if (element)
      element.focus();
  }

  private resetError() {
    this.errorStatus = null;
  }

  disableNanChars(event: KeyboardEvent) {
    if (!this.isNumber(event.key)) {
      event.preventDefault();
    }
  }

  private isNumber(value) {
    return !isNaN(Number(value));
  }

  private itemIsEmpty(index: number) {
    return !!!this.splitCode[index]
  }

  fillNextValue(event: KeyboardEvent, currentItem, nextItem, targetAfterChange) {
    const value = this.isNumber(event.key) ? event.key : '';

    if (value.length > 0 && !this.itemIsEmpty(currentItem) && this.itemIsEmpty(nextItem)) {
      this.splitCode[nextItem] = value;
      this.focusItem(targetAfterChange);
    }
  }
}
