<app-modal-cancel-booking></app-modal-cancel-booking>
<app-loading *ngIf="processing"></app-loading>
<div class="bg-main bg-gradient d-flex flex-column">
  <app-header [showLogout]="true" [showGoBack]="true"></app-header>
  <div class="container-xl">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
        <span class="icon icon-64 icon-white icon-schedule-2-2 align-items-center w-auto"></span>
        <h1 class="mt-4 text-white text-center text-light">
          {{'active.title' | translate}}
        </h1>
        <p class="mt-3 text-white text-center text-lighter-16" [innerHtml]="('autoConfirmation.activeConfirmationDirective' | translate)">
        </p>
      </div>
    </div>
  </div>
</div>
<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="d-flex flex-column">
            <div class="rounded-box mt-4 d-flex flex-column justify-content-center w-100">
              <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
                <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
                  <div class="bg-white rounded-box shadow-custom overflow-hidden p-2 mb-2 h-100 w-100 flex-grow-1 box-height">
                    <div class="flex-grow-1 d-flex flex-column mb-5">
                      <div class="container-xl">
                        <div class="row justify-content-center">
                          <div class="col-12 col-lg-8">

                            <ng-container *ngIf="hasResendChannels()">
                            <div class="bg-grey rounded-info-box px-4 py-5 mb-2 mt-4 mt-lg-5 text-lighter-16 w-100 d-flex flex-column justify-content-center">
                              <div *ngIf="resending" class="p-5">
                                <div class="spinner-border text-sec"></div>
                              </div>

                              <div *ngIf="!messageSent && requestedSend && !resending">
                                <span class="far fa-times-circle fa-2x text-danger my-2"></span>
                                <h3 class="text-danger">{{'details.retry.sms' | translate}}
                                  <a href="" (click)="retryResendNotification($event)" class="font-weight-bold text-danger">
                                    {{'details.retry.here' | translate}}
                                  </a>
                                </h3>
                              </div>

                              <div *ngIf="!messageSent && !resending && !requestedSend">
                                <p class="mb-4">
                                  {{resendMessage}}
                                </p>
                                <ng-container *ngIf="hasContactInfo()">
                                <span class="text-nowrap"><strong>( {{contactInfo}} )</strong></span>
                                </ng-container>
                                <div class="mt-4 btn-cont">
                                  <button (click)="resendNotification()" class="btn btn-xl px-4 text-sec py-2 px-4 underline">
                                    {{ 'autoConfirmation.resendButton' | translate }}
                                  </button>
                                </div>
                              </div>

                              <div *ngIf="messageSent && !resending">
                                <div class="mb-3">
                                  <span class="icon icon-32 icon-check align-items-center"></span>
                                </div>
                                <p class="mb-4">
                                  {{ 'autoConfirmation.resendMessageSuccess' | translate }}
                                </p>
                                <ng-container *ngIf="hasContactInfo()">
                                <span class="text-nowrap"><strong>({{contactInfo}})</strong></span>
                                </ng-container>
                              </div>

                            </div>

                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center pb-4">
                      <div class="col-8 px-4">
                        <button (click)="confirmBooking()" class="btn btn-block btn-main py-2">
                          {{ 'autoConfirmation.bookingConfirmation' | translate }}
                        </button>
                      </div>
                      <div class="mt-3 col-8">
                        <button (click)="showCancelModal()" class="btn btn-link btn-xl px-4" data-toggle="modal" data-target="#modalcanceldate">
                          {{ 'autoConfirmation.bookingCancelation' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
