<app-loading *ngIf="processing"></app-loading>
<app-modal-conditions></app-modal-conditions>
<div class="home d-flex flex-column flex-grow-1">
  <section class="flex-grow-1 d-flex flex-column bg-two-colors-media">
    <div class="container-fluid text-white h-100 d-flex flex-column p-0">
      <div class="row h-100 m-0">
        <div class="col bg-main d-none d-lg-flex flex-column p-0">
          <div class="p-5" *ngIf="shouldShowBusinessHeader">
            <img src="assets/image/logo.svg" alt="">
          </div>
          <div class="flex-grow-1 d-flex bg-login"></div>
        </div>
        <div class="col-12 col-lg-6 text-center text-lg-left h-100 d-flex flex-column text-center p-0">
          <div
            class="bg-main d-flex d-lg-none flex-column align-items-center justify-content-center text-center pt-5 px-3 p-lg-0">
            <img src="assets/image/logo.svg" alt="" class="pt-4" *ngIf="shouldShowBusinessHeader">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <p class="text-main-login text-center p-3 mt-2 title-custom-font custom-main-title alt"
                   [innerHTML]="('home.title' | translate)"></p>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 d-flex flex-column px-3 h-100 home-box justify-content-center">
            <div class="row justify-content-center d-none d-lg-flex">
              <div class="col-12 col-lg-8">
                <p class="text-main-login text-center p-3 mb-lg-5 title-custom-font custom-main-title alt"
                   [innerHTML]="('home.title' | translate)"></p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-7">
                <div class="bg-white rounded-home shadow-custom overflow-hidden p-4">
                  <form action="" class="w-100 flex-grow-1 d-flex flex-column">
                    <div class="row">
                      <div class="col-12 py-2 px-4">
                        <p class="text-light-16 text-main home-subtitle title-custom-font text-center mb-4 my-2 py-2">
                          {{'home.subTitle' | translate}}
                        </p>
                        <div
                          class="datepicker-selector-group overflow-hidden border rounded d-flex justify-content-between align-items-center mb-3 rounded-input">
                          <select class="form-input browser-default custom-select border-0 bg-transparent rounded-input"
                                  id="selectedDocumentType"
                                  [(ngModel)]="selectedDocumentType" name="selectedDocumentType"
                                  (change)="clearDocumentNumber()">
                            <option *ngFor="let docType of validDocumentTypes" [ngValue]="docType">
                              {{docType.description}}
                            </option>
                          </select>
                          <div class="icon-select ml-n1">
                            <span class="fas fa-chevron-down text-sec"></span>
                          </div>
                        </div>
                        <h6 class="text-left mb-2 mt-n2 px-1 text-main"
                            *ngIf="isLegalPerson()">
                          CUIT sólo para personas jurídicas. Para personas físicas seleccioná otro tipo de documento.
                        </h6>
                        <div
                          class="border rounded-input d-flex overflow-hidden justify-content-between align-items-center mb-3 input-icon"
                          [ngClass]="{'has-error': this.documentHasError }">
                          <span class="icon icon-address-card-solid"></span>
                          <input type="text" class="form-input form-control bg-transparent border-0 rounded-input ml-1"
                                 [(ngModel)]="documentNumber"
                                 (ngModelChange)="validateDocumentFormat()" name="documentNumber"
                                 placeholder="{{getDocNumberPlaceholder()}}">
                        </div>
                        <h6 class="text-error" *ngIf="documentHasError">El número ingresado es incorrecto </h6>
                        <div class="row justify-content-center">
                          <div class="col-12">
                            <button id="loginBtn" (click)="loginOnClick()"
                                    [disabled]="documentHasError || processing || !documentNumber"
                                    class="btn btn-block btn-main py-2 mt-3 mb-3">
                              {{'home.btnText' | translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
