import { Injectable } from '@angular/core';
import { Client } from '../../model/client/client.model';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { NewClientRequest } from 'src/app/model/client/newClientRequest.model';
import { DocumentType } from '../../model/client/documenttype.model';
import { PhoneVerificationRequest } from 'src/app/model/sms/phoneVerificationRequest.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private readonly clientStorageName: string = 'clientStorage';
  private readonly newClientStorageName: string = 'newClientStorage';
  private readonly docTypesStorage: string = 'docTypesStorage';
  private readonly countryPhoneRule: string = 'countryPhoneRuleStorage';
  private readonly phoneVerification: string = 'phoneVerificationStorage';
  private readonly externalClientIdentifier: string = 'externalClientIdentifier';
  private readonly hasAcceptedTermsAndConditionsStorageName: string = 'hasAcceptedTermsAndConditionsStorage';

  constructor(private sessionStorageService: SessionStorageService) {
  }

  setDocTypes(docTypes: DocumentType[]) {
    this.sessionStorageService.setItem(this.docTypesStorage, docTypes);
  }

  getDocTypes(): DocumentType[] {
    return this.sessionStorageService.getItem(this.docTypesStorage);
  }

  setCountryPhoneRule(phoneRule: string) {
    this.sessionStorageService.setItem(this.countryPhoneRule, phoneRule);
  }

  getCountryPhoneRule(): string {
    return this.sessionStorageService.getItem(this.countryPhoneRule);
  }

  getClient(): Client {
    return this.sessionStorageService.getItem(this.clientStorageName);
  }

  setClient(client: Client) {
    this.sessionStorageService.setItem(this.clientStorageName, client);
  }

  setNewClient(newClient: NewClientRequest) {
    this.sessionStorageService.setItem(this.newClientStorageName, newClient);
  }

  getNewClient(): NewClientRequest {
    return this.sessionStorageService.getItem(this.newClientStorageName);
  }

  clearNewClient(): void {
    this.sessionStorageService.removeItem(this.newClientStorageName);
  }

  setPhoneVerification(phoneVerification: PhoneVerificationRequest) {
    this.sessionStorageService.setItem(this.phoneVerification, phoneVerification);
  }

  getPhoneVerification(): PhoneVerificationRequest {
    return this.sessionStorageService.getItem(this.phoneVerification);
  }

  clearPhoneVerification(): void {
    this.sessionStorageService.removeItem(this.phoneVerification);
  }

  setHasAcceptedTermsAndConditions(hasAcceptedTermsAndConditions: boolean) {
    this.sessionStorageService.setItem(this.hasAcceptedTermsAndConditionsStorageName, hasAcceptedTermsAndConditions);
  }

  hasAcceptedTermsAndConditions(): boolean {
    return this.sessionStorageService.getItem(this.hasAcceptedTermsAndConditionsStorageName);
  }

  setExternalClientIdentifier(externalClientIdentifier: string): void {
    this.sessionStorageService.setItem(this.externalClientIdentifier, externalClientIdentifier);
  }

  getExternalClientIdentifier(): string {
    return this.sessionStorageService.getItem(this.externalClientIdentifier);
  }
}
