import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionStorageService } from '../services/session-storage/session-storage.service'
import { Paths } from '../global';

@Injectable()
export class ReminderCancelGuard implements CanActivate {
 
  constructor(private router: Router, private sessionStorage: SessionStorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const reminder = this.sessionStorage.getItem('reminderInfo');
    
    if (reminder &&
      (reminder.bookingStatus === 'CANCELADA'
      || reminder.bookingStatus === 'VENCIDA'
      || reminder.bookingStatus === 'REPLANIFICADA')) {
      return true;
    }

    return this.router.parseUrl(Paths.home);
  }
}
