import { NewClientRequest } from 'src/app/model/client/newClientRequest.model';
import { Reservation } from 'src/app/model/reservation/reservation.model';

export class GTMBookingEventRequest {
  booking: Reservation;
  client: NewClientRequest;
  bookingResult: {
    successful: boolean;
    errorMessage?: string;
    errorCode?: string;
  };
}
