import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { Joiner } from './Joiner';
import { customizations } from '../../../web-customizations/customizations';

export const isAnd = (joiner: Joiner) => {
 return joiner === Joiner.AND;
}

export const getConcatenatedWithJoiner = (joiner: Joiner, nC: NotificationChannel[]) => {
  switch(joiner) {
    case Joiner.AND: {
      return getConcatenatedWithAndOne(nC);
    }
    case Joiner.AND_OR: {
      return getConcatenatedWithAndOr(nC);
    }
    case Joiner.OR:
    default: {
      return getConcatenatedWithOr(nC);
    }
  }
}

export const concatenateStringsWithJoiner = (joiner: Joiner, nC: string[]) => {
  switch(joiner) {
    case Joiner.AND: {
      return getConcatenated(nC, ', un', 'y un');
    }
    case Joiner.AND_OR: {
      return getConcatenated(nC, ',', 'y/o');
    }
    case Joiner.OR:
    default: {
      return getConcatenated(nC, ',', 'o el');
    }
  }
}

export const getConcatenatedWithAnd = (nC: NotificationChannel[]) => {
 return getConcatenated(nC.map(nc => getNameFrom(nc)), ',', 'y por');
};

export const getConcatenatedWithAndOne = (nC: NotificationChannel[]) => {
 return getConcatenated(nC.map(nc => getNameFrom(nc)), ', un', 'y un');
};

export const getConcatenatedWithOr = (nC: NotificationChannel[]) => {
 return getConcatenated(nC.map(nc => getNameFrom(nc)), ',', 'o el');
};

export const getConcatenatedWithAndOr = (nC: NotificationChannel[]) => {
  return getConcatenated(nC.map(nc => getNameFrom(nc)), ',', 'y/o');
};

export const getConcatenated = (str: string[], joiner: string, lastJoiner: string) => {
 return str
   .join(`${joiner} `)
   .replace(new RegExp(`(${joiner})(?!.*[\\r\\n]*.*\\1)`), ` ${lastJoiner}`);
};

export const getNameFrom = (nC: NotificationChannel) => {
  return customizations.channels[nC] || nC;
};
