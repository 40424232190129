import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { Paths } from '../global';


@Injectable()
export class NoActiveBookingGuard implements CanActivate {
  constructor(private router: Router, private clientService: ClientService) {
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const client = this.clientService.getClient();

    if (client && client.hasActiveBooking) {
      return this.router.parseUrl(Paths.home);
    }

    return true;
  }
}
