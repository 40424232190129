import { Component, HostBinding, OnInit, Optional, ViewChild } from '@angular/core';
import { customizations } from '../../../web-customizations/customizations';
import { Reservation } from '../../model/reservation/reservation.model';
import { ReservationService } from '../../services/reservation/reservation.service';
import { hidePhoneNumber, isVirtualBooking } from 'src/app/utils/Utils';
import { ClientService } from '../../services/client/client.service';
import { ConfigService } from '../../services/config/config.service';
import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { TranslateService } from '@ngx-translate/core';
import { getConcatenatedWithAndOne, getConcatenatedWithJoiner } from 'src/app/utils/joiners/MessageJoiner';
import { environment } from 'src/environments/environment';
import { ModalCancelBookingComponent } from 'src/app/components/modal-cancel-booking/modal-cancel-booking.component';
import { NewClientRequest } from '../../model/client/newClientRequest.model';
import { BookingSuccessSurvey } from './survey/booking-success-survey';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.css']
})
export class BookingSuccessComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';
  @ViewChild(ModalCancelBookingComponent) modalCancelBooking: ModalCancelBookingComponent;
  reservation: Reservation;
  client: NewClientRequest;
  isVirtualBooking: boolean;
  showProcessInformation: boolean;
  readonly showReminder = customizations.success.showReminder;
  readonly showVirtualReminder = customizations.success.showVirtualReminder;
  readonly showLowerInfo = customizations.success.showLowerInfo;
  private readonly includeZoneInAddress = customizations.success.includeZoneInAddress;
  readonly businessClient = environment.environmentName;
  notificationChannels: NotificationChannel[];
  successNotificationInfo = '';
  successInfo = '';
  reminderInfo = [];
  surveyURL = '';

  constructor(private reservationService: ReservationService,
              private clientService: ClientService,
              private configService: ConfigService,
              private translateService: TranslateService,
              @Optional() private clientSurvey: BookingSuccessSurvey) {
  }

  ngOnInit(): void {
    this.loadNotificationChannels();
    this.resolveSuccessNotificationInfo();
    this.resolveSuccessInfo();
    this.resolveReminderInfo();
    this.reservation = this.reservationService.getReservation();
    this.client = this.clientService.getNewClient();
    this.isVirtualBooking = isVirtualBooking(this.reservation.selectedAttention?.attentionType);
    this.showProcessInformation = this.reservationService.getProcessInformation();
    this.resolveSurvey();
  }

  hiddenPhoneNumber() {
    return hidePhoneNumber(this.client.phoneNumber);
  }

  private loadNotificationChannels() {
    this.notificationChannels = this.configService.getRegistrationNotificationChannels();
  }

  private resolveReminderInfo() {
    this.translateService.get('success.reminder').subscribe(
      msg => {
        this.reminderInfo = [...msg];
      });
  }

  private resolveSurvey() {
    if (this.shouldShowSurvey()) {
      this.surveyURL = this.clientSurvey?.surveyMessage();
    }
  }

  shouldShowSurvey(): boolean {
    return !!this.clientSurvey;
  }

  shouldShowDot(index: number): boolean {
    return this.reminderInfo.length === 1 || index !== 0;
  }

  private resolveSuccessNotificationInfo() {
    if (this.notificationChannels?.length > 0) {
      const channelParam = {
        channels: getConcatenatedWithAndOne(this.notificationChannels)
      };
      this.translateService.get('success.notificationInfo', channelParam).subscribe(
        msg => this.successNotificationInfo = msg);
    }
  }

  private resolveSuccessInfo() {
    if (this.notificationChannels?.length > 0) {
      const channelParam = {
        channels: getConcatenatedWithJoiner(customizations.success.infoMessageJoiner, this.notificationChannels)
      };
      this.translateService.get('success.info', channelParam).subscribe(
        msg => this.successInfo = msg);
    }
  }

  shouldShowSuccessInfo() {
    return this.showLowerInfo && !this.isVirtualBooking && this.notificationChannels?.length > 0;
  }

  shouldShowVirtualReminder() {
    return this.showVirtualReminder && this.isVirtualBooking;
  }

  shouldShowReminder() {
    return this.showReminder && !this.isVirtualBooking;
  }

  showCancelModal = () => {
    this.modalCancelBooking.show();
  }

  shouldShowPhoneNumber(): boolean {
    const newClient = this.clientService.getNewClient();
    return !!newClient && !!newClient.phoneNumber;
  }

  buildCustomerCenterAddress(customerCenter: CustomerCenter): string {
    return CustomerCenter.buildFullAddress(customerCenter, this.includeZoneInAddress);
  }
}
