import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config/config.service';
import { Paths } from '../global';
import { ClientService } from '../services/client/client.service';
import { ReservationService } from '../services/reservation/reservation.service';

/**
 * Validar que pueda navegarse a la pantalla de cancelación de reserva
 * <p>
 * Si la funcionalidad no esta activa, no puede navegarse a la pantalla, por lo que redirecciona a home.
 * <p>
 * Si la funcionalidad esta activa (los siguientes casos redireccionan a error ya que se concidera que faltan datos vitales para el uso de la pantalla):
 * <p>
 * <ul>
 *  <li>Si no hay un objeto <code>Reservation</code> con <code>currentlyConfirmed</code> true en sesion, se redirecciona a error.</li>
 *  <li>Si no hay un objeto <code>Client</code> con <code>hasActiveBooking</code> true en sesion, se redirecciona a error.</li>
 * </ul>
 */
@Injectable()
export class BookingCancellationByCodeGuard implements CanActivate {

  constructor(private router: Router,
              private configService: ConfigService,
              private reservationService: ReservationService,
              private clientService: ClientService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree {
    const cancellationCodeEnabled = this.configService.getBookingCancellationCodeEnabled();
    if (cancellationCodeEnabled) {
      const reservation = this.reservationService.getReservation();
      const bookingCurrentlyConfirmed = !!reservation && reservation.currentlyConfirmed
      const client = this.clientService.getClient();
      const clientHasActiveBooking = client && client.hasActiveBooking;

      if (bookingCurrentlyConfirmed || clientHasActiveBooking) {
        return true;
      }

      return this.router.parseUrl(Paths.error);
    }
    return this.router.parseUrl(Paths.home);
  }
}
