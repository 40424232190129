export class NewClientRequest {
  firstName: string;
  lastName: string;
  businessName: string;
  docType: string;
  docNumber: string;
  phoneNumber: string;
  email: string;
  departmentId: number;
  departmentName: string;

  static create(event: {
    firstName: string,
    lastName: string,
    businessName: string,
    documentType: string,
    documentNumber: string,
    phoneNumber: string,
    email: string,
    departmentId: number,
    departmentName: string
  }) {
    return {
      firstName: event.firstName,
      lastName: event.lastName,
      businessName: event.businessName,
      documentType: event.documentType,
      documentNumber: event.documentNumber,
      phoneNumber: event.phoneNumber,
      email: event.email,
      departmentId: event.departmentId,
      departmentName: event.departmentName
    };
  }
}
