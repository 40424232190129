import { Component, HostBinding, OnInit } from '@angular/core';
import { customizations } from '../../../web-customizations/customizations';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { ConfigLoaderService } from '../../services/config/ConfigLoaderService';
import { IllegalComponentBehaviourNotifier } from '../../utils/illegal-component-behaviour-notifier.service';
import { DeviceDetectorService } from '../../utils/device-detector.service';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'app-cpe-welcome-page',
  templateUrl: './cpe-welcome-page.component.html'
})
export class CpeWelcomePageComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly shouldShowBusinessHeader = customizations.home.showBusinessHeader;

  loading = true;

  constructor(private router: Router,
              private loadConfigService: ConfigLoaderService,
              private configService: ConfigService,
              private illegalComponentBehaviourNotifier: IllegalComponentBehaviourNotifier,
              private deviceDetectorService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this
      .loadConfigService
      .loadConfiguration(
        this.checkPagePermissions,
        this.goToError,
        this.hideLoading);
  }

  goToHome = () => {
    this.router.navigate([Paths.home]);
  }

  gotToNativeApp() {
    window.top.location.href = 'https://miclaroapplication.page.link/home_miclaro_pe';
  }

  goToLoginWeb() {
    window.top.location.href = 'https://mi.claro.com.pe/wps/portal/miclaro';
  }

  isMobileAndAvailableNativeAppOS() {
    return this.deviceDetectorService.isMobile()
      && (this.deviceDetectorService.isAndroid() || this.deviceDetectorService.isIOS());
  }

  private checkPagePermissions = () => {
    const externalClientIntegrationEnabled = this.configService.getExternalClientIntegrationEnabled();
    if (!externalClientIntegrationEnabled) {
      this.illegalComponentBehaviourNotifier
        .notifyIllegalComponentAccess(
          'CpeWelcomePageComponent',
          'the flag externalClientIntegrationEnabled is false');
      this.goToHome();
    } else {
      if (!this.isMobileAndAvailableNativeAppOS()) {
        this.goToHome();
      }
    }
  }

  private hideLoading = () => {
    this.loading = false;
  }

  private goToError = () => {
    this.router.navigate([Paths.error]);
  }
}
