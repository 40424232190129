<app-modal #modal [modalId]="modalId">
  <div class="modal-body p-4">
    <div class="p-3">
      <p class="text-secondary text-center" [innerHTML]="('confirmation.errors.timeNotAvailable' | translate)"></p>
    </div>
    <div class="p-3 px-5">
      <button id="retryBtn" class="btn btn-block btn-main" data-dismiss="modal" data-backdrop="static" (click)="retrySchedule()">
        Aceptar
      </button>
    </div>
  </div>
</app-modal> 