import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ERROR_INTERCEPTOR_API_WHITE_LIST } from '../../../environments/urls';

@Injectable()
export class ErrorHandlerHttpInterceptor implements HttpInterceptor {

  constructor(private errorHandler: ErrorHandler,
              @Inject(ERROR_INTERCEPTOR_API_WHITE_LIST) private errorInterceptorAPIWhiteList: string[]) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIntercept(req.url)) {
      return next
        .handle(req)
        .pipe(catchError(e => {
          this.errorHandler.handleError(e);
          throw e;
        }));
    } else {
      return next.handle(req);
    }
  }

  private shouldIntercept(requestedURL: string): boolean {
    for (const url of this.errorInterceptorAPIWhiteList) {
      const regExp = new RegExp(url);
      if (regExp.test(requestedURL)) {
        return true;
      }
    }
    return false;
  }
}
