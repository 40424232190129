import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  validateAndExecute(locationGrantedEvent: (position) => void, locationDeniedEvent: () => void) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => locationGrantedEvent(position),
        () => locationDeniedEvent()
      )
    } else {
      locationDeniedEvent();
    }
  }
}
