import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() {
  }

  setItem(key: string, data: any) {
    if (data) {
      sessionStorage.setItem(key, JSON.stringify(data));
    } else {
      sessionStorage.setItem(key, data);
    }
  }

  getItem(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : item;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }
}
