import { ExternalClientService } from '../../../../services/externalClient/external-client.service';
import { DeviceDetectorService } from '../../../../utils/device-detector.service';
import { ErrorPageExitButtonVisibility } from './error-page-exit-button-visibility';
import { environment } from '../../../../../environments/environment';
import { CPEErrorPageExitButtonVisibility } from './cpe-error-page-exit-button-visibility';
import { DefaultErrorPageExitButtonVisibility } from './default-error-page-exit-button-visibility';

export function errorPageExitButtonVisibilityFactory(externalClientService: ExternalClientService,
                                                     deviceDetectorService: DeviceDetectorService): ErrorPageExitButtonVisibility {
  if (environment.environmentName === 'cpe') {
    return new CPEErrorPageExitButtonVisibility(externalClientService, deviceDetectorService);
  }
  return new DefaultErrorPageExitButtonVisibility();
}
