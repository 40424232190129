<app-loading *ngIf="processing"></app-loading>
<div class="terms-and-conditions d-flex flex-column flex-grow-1">
  <section class="bg-two-colors-media flex-grow-1 d-flex flex-column">
      <div class="container-fluid h-100 d-flex flex-column p-0">
        <div class="row h-100 m-0">
          <div class="col bg-main d-none d-lg-flex flex-column p-0">
            <div class="p-5" *ngIf="shouldShowBusinessHeader">
              <img src="assets/image/logo.svg" alt="">
            </div>
            <div class="flex-grow-1 d-flex bg-login"></div>
          </div>
          <div class="col-12 col-lg-6 text-lg-left h-100 d-flex flex-column p-0">
            <a href="" (click)="goBack()" class="d-none d-lg-flex pt-3 px-3">
              <span class="fas fa-arrow-left fa-lg text-main"></span>
            </a>
            <div class="bg-main d-flex d-lg-none py-3 px-3 p-lg-0">
              <a href="" (click)="goBack()" class="">
                <span class="fas fa-arrow-left fa-lg text-white"></span>
              </a>
            </div>
            <div class="flex-grow-1 d-flex flex-column justify-content-center px-3 py-0">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                  <div class="bg-white rounded-box shadow-custom terms-and-conditions pb-3">
                    <div class="d-lg-flex justify-content-center modal-header">
                      <p class="my-1 text-left text-light-18 text-main text-title title-custom-font">
                        {{'termsAndConditions.title' | translate}}
                      </p>
                    </div>
                    <div  class="custom-scroll terms-and-conditions-scroll p-3" (scroll)="removeColorStyle($event)"
                          [ngClass]="{'more-content-style': !endOfScroll}">
                      <div *ngFor="let term of terms" class="px-3 terms-and-conditions-text">
                        <ng-container *ngIf="term.title.length !== 0">
                          <p class="font-weight-bold">{{term.title}}</p>
                        </ng-container>
                        <p [innerHTML]='(term.description)'></p>
                        <br><br>
                      </div>
                    </div>
                    <div class="justify-content-center px-2 footer">
                      <div class="px-2">
                        <div class="col-12 justify-content-center d-flex p-0">
                          <div class="custom-control custom-checkbox py-3">
                            <input type="checkbox"
                                   class="custom-control-input d-flex mb-2"
                                   id="readAndAccepted"
                                   name="readAndAccepted"
                                   [checked]="readAndAccepted"
                                   (change)="readAndAccepted = !readAndAccepted"/>
                            <label class="custom-control-label flex-grow-1 pl-1" for="readAndAccepted">
                              <div [innerHTML]="'termsAndConditions.acceptanceText' | translate"></div>
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="row justify-content-center mb-3">
                              <div class="col-10">
                                <button id="registerBtn" class="btn btn-block btn-main py-2"
                                        [disabled]="!readAndAccepted"
                                        (click)="acceptTermsAndConditions()"> {{'termsAndConditions.nextButtonText'  | translate}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
</div>
