import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client/client.service';
import { Reservation } from 'src/app/model/reservation/reservation.model';
import { Client } from 'src/app/model/client/client.model';
import { BookingsService } from 'src/app/services/bookings/bookings.service';
import { Paths } from 'src/app/global';
import { ModalCancelBookingComponent } from 'src/app/components/modal-cancel-booking/modal-cancel-booking.component';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { ConfigService } from '../../services/config/config.service';
import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { TranslateService } from '@ngx-translate/core';
import { HttpStatusCode } from '../../utils/HttpStatusCode';
import { finalize } from 'rxjs/operators';
import { getConcatenatedWithAndOne } from 'src/app/utils/joiners/MessageJoiner';
import { ConfirmBookingRequest } from '../../services/bookings/http/ConfirmBookingRequest';
import { ResendNotificationRequest } from '../../services/bookings/http/ResendNotificationRequest';

@Component({
  selector: 'app-reminder-active-booking',
  templateUrl: './reminder-active-booking.component.html'
})
export class ReminderActiveBookingComponent implements OnInit {
  @ViewChild(ModalCancelBookingComponent)
  cancelModal: ModalCancelBookingComponent;
  reservation: Reservation;
  client: Client;
  resending: boolean = false;
  messageSent: boolean = false;
  requestedSend: boolean = false;
  processing: boolean = false;
  resendNotificationsChannels: NotificationChannel[];
  contactInfo: string = '';
  resendMessage: string = '';

  constructor(
    private router: Router,
    private clientService: ClientService,
    private bookingsService: BookingsService,
    private sessionStorageService: SessionStorageService,
    private configService: ConfigService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.loadClient();
    this.loadResendNotificationsChannels();
    this.resolveResendMessages();
  }

  resendNotification() {
    if (!this.requestedSend) {
      this.resending = true;
      this.requestedSend = true;
      const resendNotificationRequest = new ResendNotificationRequest(this.client.documentType.enumName, this.client.documentNumber);
      this.bookingsService
        .resendNotification(resendNotificationRequest)
        .pipe(finalize(() => this.resending = false))
        .subscribe(
          () => this.messageSent = true,
          (e) => {
            if (e.status === HttpStatusCode.GATEWAY_TIMEOUT && this.hasSingleResendChannel()) {
              this.messageSent = false;
            } else {
              this.router.navigate([Paths.error]);
            }
          }
        );
    }
  }

  retryResendNotification(event) {
    event.preventDefault();
    this.resendNotification();
  }

  confirmBooking() {
    this.processing = true;
    const request = new ConfirmBookingRequest(this.client.documentType.enumName, this.client.documentNumber);

    this.bookingsService
      .confirmBooking(request)
      .pipe(finalize(() => {
        this.sessionStorageService.clearSessionStorage();
        this.processing = false;
      }))
      .subscribe(
        () => this.router.navigate([Paths.reminderActiveBookingSuccess]),
        () => this.router.navigate([Paths.error])
      );
  }

  showCancelModal() {
    this.cancelModal.show();
  }

  hasResendChannels() {
    return this.resendNotificationsChannels?.length > 0;
  }

  hasContactInfo(): boolean {
    return this.contactInfo?.length > 0;
  }

  private resolveResendMessages() {
    if (this.hasSingleResendChannel()) {
      const channel = this.getSingleResendChannel();
      this.translateService
        .get(`autoConfirmation.resendMessage.${channel}`)
        .subscribe(msg => this.resendMessage = msg);
      this.contactInfo = this.contactInfoFor(channel);
    } else if (this.hasMultiResendChannels()) {
      const param = {
        channels: getConcatenatedWithAndOne(this.resendNotificationsChannels)
      };
      this.translateService
        .get('autoConfirmation.resendMessage', param)
        .subscribe(msg => this.resendMessage = msg);
    }
  }

  private loadClient() {
    this.client = this.clientService.getClient();
  }

  private loadResendNotificationsChannels() {
    this.resendNotificationsChannels = this.configService.getResendRegistrationNotificationChannels();
  }

  private hasSingleResendChannel(): boolean {
    return this.resendNotificationsChannels?.length === 1;
  }

  private hasMultiResendChannels(): boolean {
    return this.resendNotificationsChannels?.length > 1;
  }

  private getSingleResendChannel(): NotificationChannel {
    return this.resendNotificationsChannels[0];
  }

  private contactInfoFor(nC: NotificationChannel): string {
    switch (nC) {
      case NotificationChannel.SMS:
        return this.client.phoneNumber;
      case NotificationChannel.EMAIL:
        return this.client.email;
      default:
        return '';
    }
  }
}
