import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Term } from '../model/termsAndConditions/term.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { customizations } from '../../web-customizations/customizations';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  constructor(private router: Router,
              private httpClient: HttpClient,
              private errorHandler: ErrorHandler) {
  }

  shouldLoadTermsAndConditions(): boolean {
    return customizations.termsAndConditions.shouldShow;
  }

  retrieveTermsAndConditions(): Observable<Term[]> {
    return this.httpClient.get<Term[]>('../../assets/conditions/terms-and-conditions.json')
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  private catchErrorException = (e: any) => {
    this.errorHandler.handleError(e);
    throw e;
  }
}
