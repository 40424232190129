<accordion [isAnimated]="true" [closeOthers]="true" class="process-accordion">
  <ng-container *ngFor="let group of groups">
    <accordion-group id="{{'accordion' + group.id}}'" #accordion class="accordion-group"
                     panelClass="rounded-box accordion-group-item mx-3 mt-3"
                     [ngClass]="{'opened': accordion.isOpen}">
      <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
        <label class="float-left text-left text-main">{{ group.name }}</label>
        <div class="icon-select float-right">
          <span class="fas mr-2 mt-2" [ngClass]="getAccordionIcon(accordion.isOpen)"></span>
        </div>
        <ng-container *ngIf="group.description">
          <br>
          <h6 class="text-left w-100 float-left text-black-50 mt-n2">{{ group.description }}</h6>
        </ng-container>
      </button>
      <app-process-list #list [processes]="filterProcessesByGroup(group.id)" (onSelect)="clearSelection(list, $event); onSelect($event)"></app-process-list>
    </accordion-group>
  </ng-container>
</accordion>
