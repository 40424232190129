import { Component, HostBinding, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { NewClientRequest } from '../../model/client/newClientRequest.model';
import { ClientService } from 'src/app/services/client/client.service';
import { phoneNumberValidator } from './validators/phoneNumberValidator';
import { clientNameValidator } from './validators/clientNameValidator';
import { businessNameValidator } from './validators/businessNameValidator';
import { PhoneVerificationRequest } from 'src/app/model/sms/phoneVerificationRequest.model';
import { BookingsService } from 'src/app/services/bookings/bookings.service';
import { ReservationService } from '../../services/reservation/reservation.service';
import { ConfigService } from '../../services/config/config.service';
import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { TranslateService } from '@ngx-translate/core';
import { emailValidator } from './validators/emailValidator';
import { getConcatenatedWithJoiner } from 'src/app/utils/joiners/MessageJoiner';
import { customizations } from '../../../web-customizations/customizations';
import { finalize } from 'rxjs/operators';
import { ActiveBookingByPhoneNumberResponse } from '../../model/booking/activeBookingByPhoneNumberResponse';
import { FindByPhoneNumberRequest } from '../../services/bookings/http/FindByPhoneNumberRequest';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  @HostBinding('class') componentClass = 'flex-grow-1 d-lg-flex flex-column';

  readonly shouldShowBusinessHeader;
  readonly infoMessageJoiner;
  readonly storedClient = this.clientService.getClient();
  readonly validPhoneRule: string = this.clientService.getCountryPhoneRule();

  code = '';
  processing = false;
  displayVerificationPhoneError = false;
  connectionError = false;
  notificationChannels: NotificationChannel[];
  message = '';
  phoneNumberHasActiveBooking = false;

  client: NewClientRequest = {
    docType: null,
    docNumber: '',
    firstName: '',
    lastName: '',
    businessName: '',
    phoneNumber: '',
    email: '',
    departmentId: null,
    departmentName: null
  };

  clientForm: FormGroup;

  constructor(private router: Router,
              private clientService: ClientService,
              private reservationService: ReservationService,
              private location: Location,
              private bookingService: BookingsService,
              private configService: ConfigService,
              private translateService: TranslateService) {
    this.shouldShowBusinessHeader = customizations.registration.showBusinessHeader;
    this.infoMessageJoiner = customizations.registration.infoMessageJoiner;
  }

  ngOnInit(): void {
    this.loadNotificationsChannels();
    this.resolveInfoMessage();
    this.loadClientInfo();
    this.clientForm = new FormGroup(this.createValidators());
  }

  private loadClientInfo() {
    const clientLoaded = this.clientService.getNewClient();
    if (clientLoaded) {
      this.client.firstName = clientLoaded.firstName;
      this.client.lastName = clientLoaded.lastName;
      this.client.email = clientLoaded.email;
      this.client.phoneNumber = clientLoaded.phoneNumber;
    }
  }

  createValidators() {
    const documentTypeControl = new FormControl(this.storedClient.documentType, Validators.required);

    let allValidators;
    const mainValidators = {
      email: new FormControl(this.client.email, [
        Validators.email,
        emailValidator()
      ]),
      phoneNumber: new FormControl(this.client.phoneNumber, [
        phoneNumberValidator(this.validPhoneRule)
      ]),
    };

    if (this.storedClient.documentType.enumName !== 'CUIT') {
      allValidators = {
        ...mainValidators,
        firstName: new FormControl(this.client.firstName,
          clientNameValidator(documentTypeControl.value, 'requiredFirstName')),
        lastName: new FormControl(this.client.lastName,
          clientNameValidator(documentTypeControl.value, 'requiredLastName')),
      };
    } else {
      allValidators = {
        ...mainValidators,
        businessName: new FormControl(this.client.businessName,
          businessNameValidator(documentTypeControl.value)),
      };
    }

    return allValidators;
  }

  get documentType() {
    return this.clientForm.get('documentType');
  }

  get documentNumber() {
    return this.clientForm.get('documentNumber');
  }

  get firstName() {
    return this.clientForm.get('firstName');
  }

  get lastName() {
    return this.clientForm.get('lastName');
  }

  get businessName() {
    return this.clientForm.get('businessName');
  }

  get email() {
    return this.clientForm.get('email');
  }

  get phoneNumber() {
    return this.clientForm.get('phoneNumber');
  }

  hasError(validator) {
    return validator.invalid && (validator.touched || validator.dirty);
  }

  onSubmit() {
    this.clientForm.markAllAsTouched();

    if (this.clientForm.valid) {
      const unassignedClient = new NewClientRequest();
      unassignedClient.firstName = this.clientForm.value.firstName;
      unassignedClient.lastName = this.clientForm.value.lastName;
      unassignedClient.businessName = this.clientForm.value.businessName;
      unassignedClient.docType = this.storedClient.documentType.enumName;
      unassignedClient.docNumber = this.storedClient.documentNumber;
      unassignedClient.phoneNumber = this.clientForm.value.phoneNumber;
      unassignedClient.email = this.clientForm.value.email;

      this.clientService.setNewClient(unassignedClient);

      const loggedClient = this.clientService.getClient();
      loggedClient.documentType.enumName = unassignedClient.docType;
      loggedClient.documentNumber = unassignedClient.docNumber;
      this.clientService.setClient(loggedClient);
      this.clientService.clearPhoneVerification();
      this.reservationService.clearReservation();
      this.validateIfPhoneNumberHasActiveBooking(unassignedClient.phoneNumber, this.configService.getShouldVerifyPhoneNumber());
    }
  }

  validateIfPhoneNumberHasActiveBooking(phoneNumber: string,
                                        shouldVerifyPhoneNumber: boolean) {
    if (this.configService.getUniquePhoneNumberRestriction()) {
      this.showLoading();
      const request = new FindByPhoneNumberRequest(phoneNumber);
      this.bookingService
        .findActiveBookingByPhoneNumber(request)
        .pipe(finalize(this.hideLoading))
        .subscribe((response: ActiveBookingByPhoneNumberResponse) => {
            response.hasBooking
              ? this.setPhoneNumberWithActiveBooking()
              : this.goToNextStep(shouldVerifyPhoneNumber);
          },
          this.goToErrorPage);
    } else {
      this.goToNextStep(shouldVerifyPhoneNumber);
    }
  }

  private goToNextStep(shouldVerifyPhoneNumber: boolean) {
    shouldVerifyPhoneNumber
      ? this.getPhoneVerification()
      : this.router.navigate([Paths.process]);
  }

  private getPhoneVerification() {
    this.showLoading();
    this.displayVerificationPhoneError = false;
    this.connectionError = false;
    const newClient = this.clientService.getNewClient();
    const phoneVerificationRequest: PhoneVerificationRequest = {
      documentType: newClient.docType,
      documentNumber: newClient.docNumber,
      phoneNumber: newClient.phoneNumber,
      code: null,
      isVerified: false
    };
    this.clientService.clearPhoneVerification();

    this.bookingService.getPhoneVerificationCode(phoneVerificationRequest)
      .pipe(finalize(this.hideLoading))
      .subscribe(
        response => {
          phoneVerificationRequest.code = response.code;
          this.clientService.setPhoneVerification(phoneVerificationRequest);
          this.router.navigate([Paths.phoneVerification]);
        },
        e => {
          switch (e.status) {
            case 409:
              this.displayVerificationPhoneError = true;
              break;
            case 504:
              this.connectionError = true;
              break;
            default:
              this.goToErrorPage();
          }
        });
  }

  goBack(): boolean {
    this.location.back();
    return false;
  }

  setPhoneNumberHasActiveBookingToFalse = () => {
    this.phoneNumberHasActiveBooking = false;
  }

  private showLoading = () => {
    this.processing = true;
  }
  private hideLoading = () => {
    this.processing = false;
  }

  private goToErrorPage = () => {
    this.router.navigate([Paths.error]);
  }

  private setPhoneNumberWithActiveBooking() {
    this.phoneNumberHasActiveBooking = true;
    this.phoneNumber.setErrors({
      incorrect: true
    });
  }

  private resolveInfoMessage() {
    if (this.notificationChannels?.length > 0) {
      const channelParam = {
        channels: getConcatenatedWithJoiner(this.infoMessageJoiner, this.notificationChannels)
      };
      this.translateService.get('registration.info', channelParam).subscribe(
        msg => this.message = msg);
    }
  }

  private loadNotificationsChannels() {
    this.notificationChannels = this.configService.getRegistrationNotificationChannels();
  }

  isDocumentTypeCuit(): boolean {
    return this.storedClient.documentType?.enumName === 'CUIT';
  }
}
