import { ReservationService } from '../../services/reservation/reservation.service';
import { CalendarIcsFileService } from './calendar-ics-file-service';
import { ICSContent } from './ics-content';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BookingRegisteredCalendarService {

  constructor(private reservationService: ReservationService,
              private calendarIcsFileService: CalendarIcsFileService) {
  }

  download(): void {
    const booking = this.reservationService.getReservation();
    const ics = new ICSContent(
      booking.client.email,
      booking.reservationDate,
      booking.selectedAttention.estimatedDurationMinutes,
      `Turno ${environment.clientName}`,
      `${booking.process.name} - ${booking.customerCenter.name}`,
      booking.customerCenter.address);

    this.calendarIcsFileService.generateAndDownloadICSFile(ics);
  }
}
