<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1"
  [ngClass]="{'in': visibleAnimate, 'show': visible}"
  [ngStyle]="{'display': visible ? 'flex' : 'none', 'opacity': visibleAnimate ? 1 : 0}" id="{{modalId}}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-box">
      <ng-content select=".modal-header"></ng-content>
      <ng-content select=".modal-body"></ng-content>
      <ng-content select=".modal-footer"></ng-content>
    </div>
  </div>
</div>
