import { Component } from '@angular/core';
import { customizations } from '../../../web-customizations/customizations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  readonly currentDate = new Date();

  public getYear(date): number {
    return date.getFullYear();
  }

  public showFooter(): boolean {
    return customizations.showFooter;
  }

}
