<app-loading *ngIf="processing"></app-loading>

<section class="flex-grow-1 d-flex flex-column">
  <div class="d-flex flex-column container-sup bg-main-color">
    <app-header [title]="title"></app-header>
    <div class="info-cont w-100 justify-content-center container-xl">
      <div class="container-xl text-white">
        <div class="row h-100 justify-content-center mt-2 mb-3 d-lg-none" *ngIf="isVirtualBooking && showProcessInformation">
          <div class="col-12 col-lg-8 d-flex flex-column">
            <div class="bg-white rounded-info-box shadow-custom overflow-hidden px-3 py-2 animated-box">
              <p class="d-flex text-light-14 align-items-start icon-primary">
                <span class="icon icon-32 icon-headphone text-center mr-2" ></span>
                <span class="virtual-info text-dark" [innerHTML]="('schedule.virtualProcessWarning' | translate)"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="row px-4 justify-content-center">
          <div class="col-12 col-lg-4 pl-0 text-wrap text-break" *ngIf="showProcessInformation">
            <div class="info-cont flex-grow-1 text-left">
              <h4 class="mb-2 text-white-50" >
                {{'schedule.processTitle' | translate}}
              </h4>
              <h3>
                {{reservation.process.name}}
              </h3>
            </div>
          </div>
          <div class="col-12 pl-0" [ngClass]="showProcessInformation ? 'col-lg-4' : 'col-lg-8'">
            <div class="row h-100 justify-content-center d-none d-lg-block" *ngIf="isVirtualBooking && showProcessInformation">
              <div class="d-flex flex-column">
                <div class="bg-white rounded-info-box shadow-custom overflow-hidden px-3 py-2 animated-box">
                  <p class="d-flex text-light-14 align-items-start icon-primary">
                    <span class="icon icon-32 icon-headphone text-center mr-2" ></span>
                    <span class="virtual-info text-dark" [innerHTML]="('schedule.virtualProcessWarning' | translate)"></span>
                  </p>
                </div>
                <span class="animated-icon d-none justify-content-center mt-3 fas fa-arrow-up text-white"></span>
              </div>
            </div>
            <div class="info-cont flex-grow-1 text-left" *ngIf="!isVirtualBooking">
              <h4 class="mb-2">
                <span class="text-white-50 mr-1">{{'schedule.customerCenterTitle' | translate}}</span>
                <span class="text-white d-sm-none">{{reservation.customerCenter.name}}</span>
              </h4>
              <h3 class="mt-2">
                <span class="text-white d-none d-sm-block">
                  <span class="icon icon-map-marker-alt-solid-2 text-center icon-white"></span>
                  {{reservation.customerCenter.name}} - <span class="text-light-18 text-white title-custom-font"> {{buildCustomerCenterAddress(reservation.customerCenter)}} </span>
                </span>
                <span class="text-white d-sm-none">
                  <span class="icon icon-map-marker-alt-solid-2 icon-white col-auto" style="
                  margin-left: -13px"></span>
                  <span>{{buildCustomerCenterAddress(reservation.customerCenter)}}</span>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl flex-grow-1 mb-3">
      <div class="row h-100 justify-content-center align-content-end">
        <div class="col-12 col-lg-8 d-flex flex-column">
          <h1 *ngIf="showMainTitle" class="mt-5 mb-1 ml-4 mx-lg-1 text-left text-white text-title custom-main-title">
            {{'schedule.mainTitle' | translate}}
          </h1>
          <h1 class="mb-1 ml-4 mx-lg-1 mt-3 text-white-50">
            {{'schedule.title' | translate}}
          </h1>
          <div class="d-flex flex-column">
            <div class="row">
              <div class="col-12">
                <div id="datepickerInputContainer" class="datepicker-selector-group rounded-box shadow-custom overflow-hidden d-flex justify-content-between align-items-center p-2 mb-2">
                  <input name="datePicker"
                        type="text"
                        class="form-control text-main border-0 w-100 datepicker-text"
                        #datePicker="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="datePickerConfig"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [(ngModel)]="datepickerModel"
                        [daysDisabled]="daysDisabled"
                        [datesDisabled]="disabledDates"
                        (bsValueChange)="onDateChange($event)"
                        placement="{{getPlacement()}}"
                        (onShown)="setAsOpened()"
                        (onHidden)="setAsHidden()"
                        readonly>
                  <button class="btn btn-link icon-primary" (click)="datePicker.toggle()">
                    <span class="icon icon-schedule-2 icon-lg w-auto text-sec"></span>
                  </button>
                </div>
                <div *ngIf="validDay" class="d-flex align-items-start justify-content-center my-3 px-1">
                  <span class="fas fa-exclamation-circle pr-2 icon"></span>
                  <h6 class="text-left">
                    El número de documento ingresado tiene disponibilidad para generar un turno los días <span class="font-weight-bold"> {{validDay.name}}</span>.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="flex-grow-1 d-flex flex-column py-2 shorter-gradient container-sub">
    <div class="container-xl flex-grow-1">
      <div class="row h-100 justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column">
          <div class="flex-grow-1 d-flex flex-column">

            <div class="available-date-hours flex-grow-1 d-flex flex-column mh-100">
              <ng-container *ngIf="availableTimes && availableTimes.length > 0">
                <h1 class="pt-2 pb-2 mx-auto ml-lg-1 mt-lg-0 text-main text-title">
                  {{'schedule.hoursTitle' | translate}}
                </h1>
                <div class="time-box position-relative py-3 px-4 flex-grow-1" >
                  <div class="d-flex flex-grow-1 flex-column align-items-center align-items-lg-start position-relative overflow-auto px-4 py-1 h-100 custom-scroll">
                    <div class="position-absolute overflow-auto d-flex flex-column justify-content-center px-4 text-center">
                      <div class="d-flex custom-control custom-radio d-flex justify-content-center align-items-center py-3" *ngFor="let time of availableTimes; let i = index">
                        <input type="radio" name="customRadio" id="availableTime_{{i}}" class="custom-control-input" [value]="time" [(ngModel)]="selectedTime"/>
                        <label class="custom-control-label" for="availableTime_{{i}}">{{time | stringToDate | shortTime }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-flex justify-content-center justify-content-lg-end mt-4 mb-3 mr-lg-2">
                  <div
                    class="flex-grow-1 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start">
                    <button class="btn btn-main btn-xl col-10 col-md-5" (click)="onSelectTime()" [disabled]="!selectedTime">
                      Continuar
                    </button>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="availableTimes == null || availableTimes.length == 0"
                   class="flex-grow-1 d-flex flex-column justify-content-center align-items-center my-2">
                <span class="far fa-calendar-alt fa-4x text-main"></span>
                <h2 class=" text-center py-3 px-1" [innerHTML]="
                  isValidDayError ? ('schedule.invalidDay' | translate: validDay) : ('schedule.noResults' | translate)">
                </h2>
                <div class="row d-flex justify-content-center w-100">
                  <div
                    class="col-10 col-sm-8 d-flex flex-row justify-content-center my-3">
                    <button class="btn btn-main btn-block col-10 col-md-5" (click)="datePicker.toggle()">
                      {{'schedule.changeDay' | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
