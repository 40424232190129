import { BookingSuccessSurvey } from '../booking-success-survey';
import { ReservationService } from '../../../../services/reservation/reservation.service';
import { ClientService } from '../../../../services/client/client.service';
import { Injectable } from '@angular/core';
import { BaseURL, QEEDTemplate } from './QEED-template';
import { toURLSafeBase64 } from '../../../../utils/Utils';

@Injectable()
export class CPEBookingSuccessSurvey extends BookingSuccessSurvey {

  templateData = {
    message: 'Califica tu experiencia <a href="url" target="_blank"><b><u>Aquí</u></b></a>',
    defaultChannel: 'Cita Express',
    attentionTypes: {
      FACE_TO_FACE: 'Presencial',
      VIRTUAL: 'Virtual'
    }
  };

  constructor(private reservationService: ReservationService,
              private clientService: ClientService) {
    super();
  }

  surveyMessage(): string {
    const reservation = this.reservationService.getReservation();
    const newClient = this.clientService.getNewClient();
    const client = this.clientService.getClient();

    const template: QEEDTemplate = {
      Atencion: this.templateData.attentionTypes[reservation.selectedAttention.attentionType],
      Transaccion: reservation.process.name,
      CAC: reservation.customerCenter.name || null,
      Fecha_Hora: reservation.reservationDate.toISOString(),
      Departamento: newClient.departmentName || null,
      Canal: client.channel || this.templateData.defaultChannel,
      Id_Agendamiento: reservation.id
    };

    const JsonQEEDData = JSON.stringify(template);
    const QEEDBase64EncodedData = toURLSafeBase64(JsonQEEDData);

    return this.buildURL(BaseURL + QEEDBase64EncodedData);
  }

  private buildURL(url: string) {
    return this.templateData.message.replace('url', url);
  }
}
