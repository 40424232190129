<app-loading *ngIf="isLoadingData()"></app-loading>
<section class="bg-two-colors bg-two-colors-media flex-grow-1 d-flex flex-column">
  <div class="bg-main d-flex flex-column">
    <app-header [title]="title"></app-header>
    <div class="info-cont w-100">
      <div class="container-xl text-white">
        <div class="row">
          <div class="col text-center">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2">
    <div class="container-xl d-flex flex-column flex-grow-1">
      <div class="row h-100 justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column flex-grow-1">
          <div class="row mb-3 text-wrap text-break" *ngIf="showProcessInformation">
            <div class="col-12">
              <div class="info-cont flex-grow-1 d-none d-lg-block text-left text-white">
                <h4 class="text-white-50">
                  {{ 'customerCenter.reasonLabel' | translate }}
                </h4>
                <h3>
                  {{reservation.process.name}} <br>
                </h3>
              </div>
              <div class="info-cont flex-grow-1 d-lg-none text-left">
                <h4 class="mb-2 text-white-50">
                  {{ 'customerCenter.reasonLabel' | translate }}
                </h4>
                <h3 class="text-white">
                  {{reservation.process.name}} <br>
                </h3>
              </div>
            </div>
          </div>

          <ng-container *ngIf="shouldDisplayLocationsSelector; then locationSelect; else locationName"></ng-container>

          <ng-template #locationSelect>
            <div
                 class="datepicker-selector-group rounded-box shadow-custom d-flex justify-content-between align-items-center p-2 mb-3">
              <select class="browser-default custom-select border-0 bg-transparent" name="region"
                      [ngModel]="selectedLocation" (ngModelChange)="onSelectLocation($event)">
                <option *ngIf="locations.length > 1" [ngValue]="undefined" [disabled]="true">
                  {{'customerCenter.location.placeholder' | translate}}
                </option>
                <option *ngFor="let location of locations" [ngValue]="location">
                  {{location.name}}
                </option>
              </select>

              <div class="icon-select">
                <span class="fas fa-chevron-down mr-2"></span>
              </div>
            </div>
          </ng-template>
          <ng-template #locationName>
            <h3 class="text-white mb-3">
              {{selectedLocation.name}} <br>
            </h3>
          </ng-template>


          <div class="h-100 d-flex flex-column flex-grow-1">
            <div class="justify-content-left d-sm-block">
              <h1 class="mb-3 text-left text-white text-title text-title-white custom-main-title">
                {{'customerCenter.title.' + reservation.selectedAttention.attentionType | translate}}
              </h1>
            </div>
            <div class="ios-content-filler d-flex flex-grow-1 d-flex flex-column">
              <div class="bg-white rounded-box shadow-custom overflow-hidden d-flex flex-grow-1 d-flex flex-column pb-3 mb-3 process-box">
                <div class="flex-grow-1 d-flex flex-column">
                  <ng-container *ngIf="selectedLocation; then customerCentersItems; else emptyMessage"></ng-container>
                  <ng-template #emptyMessage>
                    <div class="flex-grow-1 d-flex flex-column justify-content-center align-items-center my-2">
                      <span class="fas fa-exclamation-circle fa-4x text-main"></span>
                      <h2 class=" text-center py-3 px-1">
                        {{ ('customerCenter.location.no-location' | translate) }}
                      </h2>
                    </div>
                  </ng-template>

                  <ng-template #customerCentersItems>
                    <div class="flex-grow-1 d-flex flex-column align-items-center position-relative px-2 my-1"
                         style="overflow-y: auto !important;">
                      <div
                        class="process-container position-absolute overflow-auto d-flex flex-column flex-grow-1 justify-content-left w-100">
                        <div class="process-list-container px-2 pt-1">
                          <app-item-list [items]="customerCenters"
                                         [identityFn]="customerCenterId"
                                         [descriptionFn]="customerCenterName"
                                         [detailsFn]="customerCenterAddress"
                                         (selectItem)="selectCustomerCenter($event)">
                          </app-item-list>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center justify-content-lg-end mr-lg-4">
                      <div
                        class="flex-grow-1 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start">
                        <button class="btn btn-main btn-xl col-10 col-md-5" (click)="confirmSelection()"
                                [disabled]="!isCustomerCenterSelected()">
                          Continuar
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>

              <ng-container *ngIf="isNearbyCustomerCentersEnabled() && !this.isExternalClientMobileIntegration()">
                <ng-container *ngIf="locationGranted; then locationGrantedBanner; else locationDeniedBanner"></ng-container>

                <ng-template #locationGrantedBanner>
                  <div class="row d-flex flex-column">
                    <div class="col justify-content-center text-center">
                      {{'customerCenter.nearbyCustomerCenters.granted.message' | translate}}
                      <a class="text-sec font-weight-bold" href="" (click)="goToNearbyCustomerCenters(false)">
                        {{'customerCenter.nearbyCustomerCenters.granted.link' | translate}}
                      </a>
                    </div>
                  </div>
                </ng-template>
                <ng-template #locationDeniedBanner>
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-start justify-content-center my-3 px-1 info-box p-3">
                      <span class="fas fa-exclamation-circle pr-2 text-sec" aria-hidden="true"></span>
                      <h6 class="text-left info-text">
                        {{ 'customerCenter.nearbyCustomerCenters.denied.message' | translate }}
                        <a href="" (click)="goToNearbyCustomerCenters(false)" class="font-weight-bolder">
                          {{'customerCenter.nearbyCustomerCenters.denied.link' | translate}}
                        </a>
                      </h6>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
