import { Component, HostBinding, OnInit } from '@angular/core';
import { Reservation } from '../../model/reservation/reservation.model';
import { Router } from '@angular/router';
import { ReservationService } from '../../services/reservation/reservation.service';
import { BookingsService } from '../../services/bookings/bookings.service';
import { Coordinate } from '../../model/customerAttention/coordinate.model';
import { finalize } from 'rxjs/operators';
import { Paths } from '../../global';
import { NearbyCustomerCenter } from '../../model/customerAttention/nearbyCustomerCenter.model';
import { LocationService } from '../../services/customerAttention/location.service';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';
import { ConfigService } from '../../services/config/config.service';
import { customizations } from '../../../web-customizations/customizations';

@Component({
  selector: 'app-nearby-customer-centers',
  templateUrl: './nearby-customer-centers.component.html'
})
export class NearbyCustomerCentersComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  loading = true;
  booking: Reservation;
  showProcessInformation: boolean;
  nearbyCustomerCenters: NearbyCustomerCenter[];
  selectedCustomerCenter: NearbyCustomerCenter;

  private readonly includeZoneInAddress = customizations.nearbyCustomerCenters.includeZoneInAddress;

  constructor(private router: Router,
              private configService: ConfigService,
              private locationService: LocationService,
              private reservationService: ReservationService,
              private bookingsService: BookingsService) {
  }

  ngOnInit(): void {
    if (this.configService.getNearbyCustomerCentersEnabled()) {
      this.showProcessInformation = this.reservationService.getProcessInformation();
      this.booking = this.reservationService.getReservation();
      this.requestLocationAndProcess();
    } else {
      this.goToCustomerCenters(true);
    }
  }

  private requestLocationAndProcess(): void {
    this.showLoading();
    this.locationService.validateAndExecute(
      (position) => this.getCustomerCentersByClientLocation(position),
      () => this.goToCustomerCenters(true)
    );
  }

  private getCustomerCentersByClientLocation(position) {
    const coordinate: Coordinate = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };

    this.loadNearbyCustomerCenters(this.booking?.selectedAttention?.id, coordinate);
  }

  private loadNearbyCustomerCenters(attentionId: number, coordinate: Coordinate): void {
    this.bookingsService.getNearbyCustomerCenters(attentionId, coordinate)
      .pipe(finalize(this.hideLoading))
      .subscribe(
        response => {
          if (!!!response || response.length == 0) {
            this.goToCustomerCenters(true);
          }

          this.nearbyCustomerCenters = response;
        },
        () => this.goToError()
      );
  }

  private showLoading = (): void => {
    this.loading = true;
  };

  private hideLoading = (): void => {
    this.loading = false;
  };

  private goToError(): void {
    this.router.navigate([Paths.error]);
  }

  goToCustomerCenters(deleteFromHistory: boolean): boolean {
    this.router.navigate([Paths.customerCenter], { replaceUrl: deleteFromHistory });
    return false;
  }

  private goToBookingSchedule(): void {
    this.router.navigate([Paths.bookingSchedule]);
  }

  customerCenterId = (nearbyCustomerCenter: NearbyCustomerCenter): number => {
    return nearbyCustomerCenter.customerCenter.id;
  };

  customerCenterName = (nearbyCustomerCenter: NearbyCustomerCenter): string => {
    return nearbyCustomerCenter.customerCenter.name;
  };

  customerCenterAddress = (nearbyCustomerCenter: NearbyCustomerCenter): string => {
    return CustomerCenter.buildFullAddress(nearbyCustomerCenter.customerCenter, this.includeZoneInAddress);
  };

  customerCenterDistance = (nearbyCustomerCenter: NearbyCustomerCenter): string => {
    return nearbyCustomerCenter.distanceDescription;
  };

  selectCustomerCenter(nearbyCustomerCenter: NearbyCustomerCenter): void {
    this.selectedCustomerCenter = nearbyCustomerCenter;
  }

  isCustomerCenterSelected(): boolean {
    return !!this.selectedCustomerCenter;
  }

  confirmSelection(): void {
    this.updateReservationCustomerCenter(this.selectedCustomerCenter.customerCenter);
    this.goToBookingSchedule();
  }

  private updateReservationCustomerCenter(customerCenter: CustomerCenter): void {
    this.booking.customerCenter = customerCenter;
    this.booking.reservationDate = null;
    this.reservationService.setReservation(this.booking);
  }
}
