import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../date-utils.service';
import { ICSContent } from './ics-content';

@Injectable({
  providedIn: 'root'
})
export class CalendarIcsFileService {

  constructor(private dateUtils: DateUtils) {}

  private static download(icsContent: string): void {
    const fileName = `${environment.applicationTitle}.ics`;
    const blob = new Blob([icsContent], {
      type: window.navigator.msSaveOrOpenBlob ? 'text/calendar;charset=utf-8' : 'text/calendar'
    });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const anchor = window.document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = fileName;
      anchor.click();
    }
  }

  generateICSContent(content: ICSContent) {
    const currentDate = this.dateUtils.newDateToISOString();
    const uid = `${currentDate}${content.email ? content.email : ''}`;
    let icsFormat = '';
    icsFormat += 'BEGIN:VCALENDAR\r\n';
    icsFormat += 'VERSION:2.0\r\n';
    icsFormat += 'PRODID:Aloha Reservas\r\n';
    icsFormat += 'CALSCALE:GREGORIAN\r\n';
    icsFormat += 'BEGIN:VEVENT\r\n';
    icsFormat += `DTSTAMP:${currentDate}\r\n`;
    icsFormat += `UID:${uid}\r\n`;
    icsFormat += `DTSTART:${this.dateUtils.icsCalendarDateFormat(content.from)}\r\n`;
    icsFormat += `DURATION:PT${content.durationInMinutes}M\r\n`;
    icsFormat += `SUMMARY:${content.summary}\r\n`;
    icsFormat += `DESCRIPTION:${content.description}\r\n`;
    icsFormat += `LOCATION:${content.location}\r\n`;
    icsFormat += 'END:VEVENT\r\n';
    icsFormat += 'END:VCALENDAR\r\n';
    return icsFormat;
  }

  generateAndDownloadICSFile(icsInfo: ICSContent): void {
    const icsContent = this.generateICSContent(icsInfo);
    CalendarIcsFileService.download(icsContent);
  }
}
