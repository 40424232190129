import { Component, OnInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})
export class ModalsComponent implements OnInit {

  @Input() modalId: string;
  public visible: boolean = false;
  public visibleAnimate: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    $(`#${this.modalId}`).modal('hide');
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }

}
