import { EntelCLGTMEvent } from './entel-cl-gtm-event';

/**
 * Propiedades requeridas a ser enviadas para Entel CL dado un evento de registración de reserva.
 */
export class EntelCLGTMBookingEvent extends EntelCLGTMEvent {

  /**
   * Numero de documento
   */
  rut: string;
  /**
   * Nombre y apellido
   */
  nombre: string;
  email: string;
  // tslint:disable-next-line:variable-name
  telefono_cliente: string;
  /**
   * Motivo de atencion.
   */
  variant: string;
  /**
   * Localidad
   */
  region: string;
  /**
   * Zona del CAC
   */
  comuna: string;
  /**
   * Se solicita dirección encriptada como si fuese datos del usuario.
   * Reservas no maneja datos del usuario. Se enviará dirección del CAC.
   */
  direccion: string;
  /**
   * Nombre del CAC
   */
  variant2: string;
  /**
   * Fecha de la reserva
   */
  date: string;
  /**
   * Hora de la reserva
   */
  horario: string;
  /**
   * Informa si fue exitoso o no la generación de la reserva.
   * Valores:
   * exito
   * error
   */
    // tslint:disable-next-line:variable-name
  page_type: string;
  /**
   * Mensaje de resultado de reserva.
   * Mensaje de exito o código con mensaje de error.
   */
  variant3: string;
  // tslint:disable-next-line:variable-name
  p_interacciones: string;
}
