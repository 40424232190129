import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExternalClientBookingValidationRequest } from './http/ExternalClientBookingValidationRequest';
import { Observable } from 'rxjs';
import { Client } from '../../model/client/client.model';
import { ClientService } from '../client/client.service';
import { NewClientRequest } from '../../model/client/newClientRequest.model';
import { TermsAndConditionsService } from '../../utils/terms-and-conditions.service';
import { LoginResponse } from '../../model/client/loginResponse.model';
import { ExternalClientSessionValuesDTO } from './model/ExternalClientSessionValuesDTO';
import { ConfigService } from '../config/config.service';
import { ExternalClientChannel } from './model/ExternalClientChannel';
import { DeviceDetectorService } from '../../utils/device-detector.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalClientService {

  private readonly validateExternalClientBookingURL = environment.services.externalClient.validateBooking;
  private readonly CLIENT_NATIVE_APP_CHANNEL = 'MI_CLARO_APP';
  private readonly CLIENT_WEB_APP_CHANNEL = 'MI_CLARO_WEB';

  constructor(private httpClient: HttpClient,
              private clientService: ClientService,
              private configService: ConfigService,
              private termsAndConditionsService: TermsAndConditionsService,
              private deviceDetectorService: DeviceDetectorService) {
  }

  isValidForExternalClientBookingOperation(): boolean {
    const externalClientIdentifier = this.clientService.getExternalClientIdentifier();

    return this.configService.getExternalClientIntegrationEnabled()
      && this.configService.getAccessFromExternalClientIntegration()
      && !!externalClientIdentifier;
  }

  validateExternalClientBooking(request: ExternalClientBookingValidationRequest): Observable<LoginResponse> {
    return this
      .httpClient
      .post<LoginResponse>(this.validateExternalClientBookingURL, request);
  }

  saveSessionValues(dto: ExternalClientSessionValuesDTO): void {
    const client = new Client();
    const newClientRequest = new NewClientRequest();
    const loginResponse = dto.loginResponse;
    client.hasActiveBooking = loginResponse.hasBooking;
    client.phoneNumber = loginResponse.phoneNumber;
    client.email = loginResponse.email;
    client.channel = loginResponse.clientChannel;
    this.clientService.setClient(client);
    this.clientService.setNewClient(newClientRequest);
    this.clientService.setExternalClientIdentifier(dto.identifier);
    this.setTermsAndConditionsFlag();
    this.configService.setAccessFromExternalClientIntegration(true);
  }

  clientChannel(): ExternalClientChannel {
    const actualClientChannel = this.clientService.getClient().channel;
    if (actualClientChannel) {
      if (actualClientChannel.toUpperCase() === this.CLIENT_NATIVE_APP_CHANNEL.toUpperCase()) {
        return ExternalClientChannel.NATIVE_APP;
      }
      if (actualClientChannel.toUpperCase() === this.CLIENT_WEB_APP_CHANNEL.toUpperCase()) {
        return ExternalClientChannel.WEB_APP;
      }
    }
    return ExternalClientChannel.LEGACY;
  }

  isExternalClientIntegrationEnabled(): boolean {
    return this.configService.getExternalClientIntegrationEnabled();
  }

  isExternalClientMobileAppIntegration(): boolean {
    return this.isExternalClientIntegrationEnabled()
      && this.configService.getAccessFromExternalClientIntegration()
      && this.clientChannel() === ExternalClientChannel.NATIVE_APP
      && this.deviceDetectorService.isAndroidOrIOS();
  }

  private setTermsAndConditionsFlag() {
    this
      .clientService
      .setHasAcceptedTermsAndConditions(this.termsAndConditionsService.shouldLoadTermsAndConditions());
  }
}
