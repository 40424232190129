import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageExitButtonBehaviour } from './exit-button/behaviour/error-page-exit-button-behaviour';
import { ErrorPageExitButtonVisibility } from './exit-button/visibility/error-page-exit-button-visibility';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  message: string;
  title: string;

  showExitButton = true;

  constructor(private router: Router,
              private errorPageExitButtonBehaviour: ErrorPageExitButtonBehaviour,
              private errorPageExitButtonVisibility: ErrorPageExitButtonVisibility) {

    const state = this.router.getCurrentNavigation().extras.state;

    this.message = state?.message;
    this.title = state?.title;
    if (state?.explicitlyHideExitButton) {
      this.showExitButton = false;
    } else {
      this.showExitButton = this.errorPageExitButtonVisibility.showExitButton();
    }
  }

  exit() {
    this.errorPageExitButtonBehaviour.exitButton();
  }
}
