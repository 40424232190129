import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

import {ActivatedRoute} from '@angular/router';
import {environment} from '../environments/environment';
import {ConfigService} from './services/config/config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: { 'class': 'flex-grow-1 d-flex flex-column' }
})
export class AppComponent implements OnInit {
  title = 'bookings-front';
  readonly appVersion = environment.appVersion;
  backAppVersion = 'undefined';

  showAppVersion: boolean;

  constructor(private translateService: TranslateService,
              private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private configService: ConfigService
  ) {
    this.translateService.setDefaultLang('messages');
    this.titleService.setTitle(environment.applicationTitle);
    this.backAppVersion = this.configService.getAppBackVersion();
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const versionParam = params.get('version');
      this.showAppVersion = versionParam?.toLowerCase() === 'true';
    });
  }
}
