<div class="modal fade show loading" id="loading" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: block;">
  <div class="d-flex h-100 w-100 flex-column justify-content-center align-items-center">
    <div class="spinner mb-4">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div class="loadingMessage px-3 text-center" *ngIf="message">
      <h3 class="">
        Procesando
      </h3>
      <p class="txt-19">
        {{message}}
      </p>
    </div>
  </div>
</div>
