import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { ModalsComponent } from '../modals/modals.component';
import { customizations } from '../../../web-customizations/customizations';

declare var $: any;

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html'
})
export class ModalLogoutComponent {

  @ViewChild(ModalsComponent) defaultModal: ModalsComponent;
  readonly modalId: string = 'modalsession';
  private readonly logoutCustomUrl = customizations.header.logout.customUrl;

  constructor(private router: Router,
              private sessionStorageService: SessionStorageService,
              private document: Document) {
  }

  show() {
    this.defaultModal.show();
  }

  logout() {
    this.sessionStorageService.clearSessionStorage();

    if (this.logoutCustomUrl) {
      this.document.location.href = this.logoutCustomUrl;
    } else {
      this.router.navigate([Paths.home]);
    }
  }
}
