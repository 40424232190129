export const includes = (searchCollection, searchElement) => searchCollection.indexOf(searchElement) >= 0;

export const replaceAll = (text, originalChar, replacementChar) => text.split(originalChar).join(replacementChar);

export const isVirtualBooking = (attentionType: string) => attentionType === 'VIRTUAL';

export const hidePhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/(\+?\d{3})(\d+)(\d{3})/g, function(match, start, middle, end) {
    return start + "*".repeat(middle.length) + end;
  });

export const validatePattern = (value, pattern): boolean => pattern.test(value);
export const replaceText = (value, pattern): string => value.replace(pattern, "");

export const appendFacebookVerificationMetadata = (facebookDomainCode) => {
  if (facebookDomainCode) {
    let meta = document.createElement('meta');
    meta.name = 'facebook-domain-verification';
    meta.content = facebookDomainCode;

    let heads = document.getElementsByTagName('head');
    heads[0].appendChild(meta);
  }
};

export const toURLSafeBase64 = (content) => {
  return btoa(unescape(encodeURIComponent(content)));
};
