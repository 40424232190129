import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalsComponent } from '../modals/modals.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Bullet } from '../../model/booking/bullet.model';
import { BulletDetailModel } from '../../model/booking/bullet-detail.model';

declare var $: any;

@Component({
  selector: 'app-modal-conditions',
  templateUrl: './modal-conditions.component.html',
  styleUrls: ['./modal-conditions.component.css']
})
export class ModalConditionsComponent implements OnInit {
  @ViewChild(ModalsComponent) defaultModal: ModalsComponent;

  readonly modalId: string = 'modalInfo';
  bullets: BulletDetailModel[];
  html: string;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.defaultModal['onContainerClicked'] = () => {};
    setTimeout(() => this.obtainBullets());
  }

  show() {
    this.defaultModal.show();
  }

  private obtainBullets() {
    const requestOptions = {
      headers: this.generateHeaders()
    };
    this.httpClient.get<Bullet>('../../../assets/conditions/bullets.json', requestOptions)
      .subscribe(response => {
          this.bullets = response.content;
          if (this.hasBullets()) {
            this.show();
          } else {
            this.obtainHTML();
          }
        },
        () => { this.obtainHTML() });
  }

  hasBullets() {
    return this.bullets && this.bullets.length;
  }

  private obtainHTML() {
    this.httpClient.get('../../../assets/conditions/conditions.html', { headers: this.generateHeaders(), responseType: 'text' })
      .subscribe(response => {
          this.html = response;
          if (this.hasHtml()) {
            this.show();
          }
        });
  }

  hasHtml() {
    return this.html && this.html.length;
  }

  private generateHeaders() {
    return new HttpHeaders({
      'Cache-Control': 'public, s-maxage=600, must-revalidate'
    });
  }

}
