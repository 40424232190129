import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalClientService } from '../../../../services/externalClient/external-client.service';
import { Paths } from '../../../../global';
import { DeviceDetectorService } from '../../../../utils/device-detector.service';
import { ExternalClientChannel } from '../../../../services/externalClient/model/ExternalClientChannel';
import { ErrorPageExitButtonBehaviour } from './error-page-exit-button-behaviour';
import { CPEOuterFunctionService } from '../../../../utils/cpe-outer-function.service';
import { IllegalComponentBehaviourNotifier } from '../../../../utils/illegal-component-behaviour-notifier.service';

@Injectable()
export class CPEErrorPageExitButtonBehaviour extends ErrorPageExitButtonBehaviour {

  constructor(private router: Router,
              private externalClientService: ExternalClientService,
              private deviceDetectorService: DeviceDetectorService,
              private cpeOuterFunctionsService: CPEOuterFunctionService,
              private icbNs: IllegalComponentBehaviourNotifier) {
    super();
  }

  /**
   * Se asume que pueden existir dos casos posibles de invocación de este método para CPE:
   * 1 - El cliente proviene de Agendamiento Express (Flujo de Agendamiento normal)
   * En ese caso, debe redirigir a la página de welcome. (Welcome solo resuelve si debe mostrarse o ocultarse
   * y redirigir a la home, por lo tanto, por mas que no este habilitada la integración se puede desplegar igual)
   *
   * 2 - El cliente proviene de un Agendamiento Integrado (Desde APP Mi Claro Nativa). Se asume que para Mi Claro APP Web
   * el botón no debe estar disponible. Se deberá validar que tipo de dispositivo es el del cliente
   * (Solo Android/iOS tiene escenario) e invocar las correspondientes funciones externas expuestas por Claro.
   */
  exitButton(): void {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      if (this.externalClientService.clientChannel() === ExternalClientChannel.NATIVE_APP) {
        if (this.deviceDetectorService.isAndroid()) {
          this.cpeOuterFunctionsService.callAndroidOuterFunction();
        } else if (this.deviceDetectorService.isIOS()) {
          this.cpeOuterFunctionsService.callIOSOuterFunction();
        } else {
          this.icbNs.notify(
            'Botón Salir CPE',
            'CPEErrorPageExitButtonBehaviour',
            // tslint:disable-next-line:max-line-length
            `Acceso ilegal al botón salir de pantalla de error genérica. Se esperan dispositivos Android o IOS. Dispositivo del cliente: ${this.deviceDetectorService.getDeviceType()}`);
        }
      } else {
        this.icbNs.notify(
          'Botón Salir CPE',
          'CPEErrorPageExitButtonBehaviour',
          // tslint:disable-next-line:max-line-length
          `Acceso ilegal al botón salir de pantalla de error genérica. Se esperan canal de usuario NATIVE_APP. Canal del cliente: ${this.externalClientService.clientChannel().toString()}`);
      }
    } else {
      if (this.externalClientService.isExternalClientIntegrationEnabled()) {
        this.router.navigate([Paths.welcome]);
      } else {
        this.router.navigate([Paths.home]);
      }
    }
  }
}
