<app-loading *ngIf="this.loading"></app-loading>
<section class="bg-two-colors bg-two-colors-media flex-grow-1 d-flex flex-column">
  <div class="bg-main d-flex flex-column">
    <app-header [showGoBack]="true"></app-header>
    <div class="info-cont w-100">
      <div class="container-xl text-white">
        <div class="row">
          <div class="col text-center">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2 mt-3">
    <div class="container-xl d-flex flex-column flex-grow-1">
      <div class="row h-100 justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column flex-grow-1">

          <div class="row mb-3">
            <div class="col-12">
              <div class="info-cont flex-grow-1 d-none d-lg-block text-left text-white">
                <h4 class="text-white-50">
                  {{ 'clientLocation.reasonLabel' | translate }}
                </h4>
                <h3>
                  {{processName()}} <br>
                </h3>
              </div>
              <div class="info-cont flex-grow-1 d-lg-none text-left">
                <h4 class="mb-2 text-white-50">
                  {{ 'clientLocation.reasonLabel' | translate }}
                </h4>
                <h3 class="text-white">
                  {{processName()}} <br>
                </h3>
              </div>
            </div>
          </div>

          <div class="h-100 d-flex flex-column flex-grow-1">
            <div class="justify-content-left d-none d-sm-block mb-3 text-white-50 text-title">
              <h1 class="text-left">
                {{'clientLocation.title' | translate}}
              </h1>
            </div>
            <div
              class="ios-content-filler bg-white rounded-box shadow-custom overflow-hidden d-flex flex-grow-1 d-flex flex-column pb-3 mb-3 process-box">
              <h1 class="justify-content-center text-center text-black-50 text-title d-sm-none my-4">
                {{'clientLocation.title' | translate}}
              </h1>
              <div class="flex-grow-1 d-flex flex-column">
                <ng-container>
                  <div class="flex-grow-1 d-flex flex-column align-items-center position-relative px-2 my-1"
                       style="overflow-y: auto !important;">
                    <div
                      class="process-container position-absolute overflow-auto d-flex flex-column flex-grow-1 justify-content-left w-100">
                      <div class="process-list-container px-2 pt-1">
                        <app-item-list [items]="this.activeDepartments | orderDepartmentByName"
                                       [identityFn]="departmentIdFn"
                                       [descriptionFn]="departmentDescriptionFn"
                                       (selectItem)="selectDepartment($event)">
                        </app-item-list>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center justify-content-lg-end mr-lg-4">
                    <div
                      class="flex-grow-1 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start">
                      <button class="btn btn-main btn-xl col-10 col-md-5" (click)="confirmDepartmentSelected()"
                              [disabled]="!this.isDepartmentSelected()">
                        Continuar
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</section>
