import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingsService } from '../../services/bookings/bookings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReminderResponse } from 'src/app/model/booking/reminderResponse.model';
import { Paths } from 'src/app/global';
import { ModalCancelBookingComponent } from 'src/app/components/modal-cancel-booking/modal-cancel-booking.component';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { ConfigLoaderService } from '../../services/config/ConfigLoaderService';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-reminder',
  templateUrl: './confirmation-reminder.component.html'
})
export class ConfirmationReminderComponent implements OnInit {
  @ViewChild(ModalCancelBookingComponent)
  cancelModal: ModalCancelBookingComponent;

  encryptedId: string;
  processing = true;
  reminderInfo: ReminderResponse;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bookingsService: BookingsService,
    private sessionStorageService: SessionStorageService,
    private loadConfigService: ConfigLoaderService) {
  }

  ngOnInit(): void {
    this.showLoading();
    this.loadConfigService
      .loadConfiguration(
        this.findReminderInfo,
        this.goToError
      );
  }

  confirm() {
    this.bookingsService
      .confirmBookingByEncryptionId(this.encryptedId)
      .pipe(finalize(this.hideLoading))
      .subscribe(
        () => {
          this.reminderInfo.bookingStatus = 'CONFIRMADA';
          this.sessionStorageService.setItem('reminderInfo', this.reminderInfo);
          this.router.navigate([Paths.confirmationSuccess], {queryParams: {t: this.encryptedId}});
        },
        this.goToError
      );
  }

  showCancelModal() {
    this.cancelModal.show();
  }

  private findReminderInfo = () => {
    this.encryptedId = this.activatedRoute.snapshot.queryParamMap.get('t');
    this.loadReminderInfo();
  }

  private loadReminderInfo() {
    this.bookingsService
      .findBookingByEncryptedId(this.encryptedId)
      .pipe(finalize(this.hideLoading))
      .subscribe(
        (response: ReminderResponse) => {
          this.buildReminder(response);
          this.sessionStorageService.setItem('reminderInfo', this.reminderInfo);
          this.statusHandler(this.reminderInfo.bookingStatus);
        },
        this.goToError
      );
  }

  private statusHandler(status: string) {
    switch (status) {
      case 'CANCELADA':
      case 'VENCIDA':
      case 'REPLANIFICADA':
        this.router.navigate([Paths.confirmationExpired]);
        break;
      case 'FINALIZADA':
        this.router.navigate([Paths.home]);
        break;
      case 'CONFIRMADA':
        this.router.navigate([Paths.confirmationSuccess], {queryParams: {t: this.encryptedId}});
        break;
      case 'PLANIFICADA':
        if (!this.reminderInfo.canConfirm) {
          this.goToError();
        }
        break;
      default:
        this.goToError();
    }
  }

  private buildReminder(response: ReminderResponse) {
    this.reminderInfo = new ReminderResponse();
    this.reminderInfo.clientName = response.clientBusinessName ? response.clientBusinessName : response.clientName;
    this.reminderInfo.clientBusinessName = response.clientBusinessName;
    this.reminderInfo.bookingStatus = response.bookingStatus;
    this.reminderInfo.from = response.from;
    this.reminderInfo.customerCenterName = response.customerCenterName;
    this.reminderInfo.customerCenterAddress = response.customerCenterAddress;
    this.reminderInfo.processName = response.processName;
    this.reminderInfo.canConfirm = response.canConfirm;
    this.reminderInfo.attentionType = response.attentionType;
  }

  public attentionTypeIsVirtual(): boolean {
    return this.reminderInfo.attentionType === 'VIRTUAL';
  }

  private showLoading = () => {
    this.processing = true;
  }

  private hideLoading = () => {
    this.processing = false;
  }

  private goToError = () => {
    this.router.navigate([Paths.error]);
  }
}
