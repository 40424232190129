import { Component, HostBinding, OnInit } from '@angular/core';
import { Process } from '../../model/process/process.model';
import { Router } from '@angular/router';
import { ReservationService } from '../../services/reservation/reservation.service';
import { Reservation } from '../../model/reservation/reservation.model';
import { Paths } from '../../global';
import { ConfigService } from '../../services/config/config.service';
import { customizations } from '../../../web-customizations/customizations';

@Component({
  selector: 'app-attention-type',
  templateUrl: './attention-type.component.html',
  styleUrls: ['./attention-type.component.scss']
})
export class AttentionTypeComponent implements OnInit {
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly buttonsContent = {
    VIRTUAL: {
      icon: 'icon-llamada',
      text: 'attentionType.placeholders.VIRTUAL',
      order: 1
    },
    FACE_TO_FACE: {
      icon: 'icon-tienda',
      text: 'attentionType.placeholders.FACE_TO_FACE',
      order: 2
    }
  };

  readonly title: string = 'Tipo de Atención';
  public departmentSelectionEnabled = false;
  public removeThisPageFromNavigation = false;
  public reservation: Reservation;
  public processAttentionTypes: string[] = [];
  public processing = true;

  constructor(private reservationService: ReservationService,
              private configService: ConfigService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.reservation = this.reservationService.getReservation();
    this.departmentSelectionEnabled = this.configService.getDepartmentSelectionEnabled();
    this.setAttentionTypes(this.reservation.process);
    this.routeIfSingleAttentionType();
    this.hideLoading();
  }

  public typeSelection(attentionType: string): void {
    if (this.supportsAttentionTypeOf(attentionType)) {
      this.updateReservationAttentionType(attentionType);

      if (attentionType === 'VIRTUAL' && this.departmentSelectionEnabled) {
        this.routeToDepartmentSelectionPage();
      } else {
        this.routeToNearbyCustomerCenterPage();
      }
    }
  }

  public supportsAttentionTypeOf(attentionType: string) {
    return this.processAttentionTypes.some(type => attentionType === type);
  }

  public retrieveIconFor(attentionType: string) {
    return this.buttonsContent[attentionType]?.icon;
  }

  public returnPlaceholderFor(attentionType: string) {
    return this.buttonsContent[attentionType]?.text;
  }

  public shouldShowButton(attentionType: string): boolean {
    return customizations.attentionType.shouldShowButtons[attentionType];
  }

  private updateReservationAttentionType(attentionType: string) {
    this.reservation.process.attentions.forEach(value => {
      if (value.attentionType === attentionType) {
        this.reservation.selectedAttention = value;
        this.reservationService.setReservation(this.reservation);
      }
    });
  }

  private routeIfSingleAttentionType() {
    if (this.processAttentionTypes.length === 1) {
      const selectedAttentionType = this.processAttentionTypes[0];
      this.removeThisPageFromNavigation = true;
      this.typeSelection(selectedAttentionType);
    }
  }

  private setAttentionTypes(process: Process) {
    const atList = process.attentions.map(at => at.attentionType);
    atList.sort((a, b) => this.buttonsContent[a]?.order - this.buttonsContent[b]?.order);
    this.processAttentionTypes = atList;
  }

  private hideLoading = () => {
    this.processing = false;
  }

  private routeToNearbyCustomerCenterPage() {
    this.router.navigate([Paths.nearbyCustomerCenter], { replaceUrl: this.removeThisPageFromNavigation });
  }

  private routeToDepartmentSelectionPage() {
    this.router.navigate([Paths.departmentSelection], { replaceUrl: this.removeThisPageFromNavigation });
  }
}
