<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="bg-main bg-gradient d-flex flex-column">
    <app-header [showGoBack]="false"></app-header>
    <div class="container-xl">
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
          <span class="icon icon-64 icon-white icon-schedule-2-2 align-items-center w-auto"></span>
          <h1 class="mt-4 text-white text-center text-light custom-main-title">
            {{'active.title' | translate}}
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <app-booking-details [reservation]="reservation"></app-booking-details>
        </div>
      </div>
    </div>
  </div>
</section>
