<app-loading *ngIf="isLoading()"></app-loading>
<div class="bg-white rounded shadow-custom overflow-hidden p-4">
  <div class="d-flex flex-column">
    <ng-container *ngIf="hasReachedMaxAttempts(); then noAttemptsLeft; else attemptsLeft"></ng-container>

    <ng-template #noAttemptsLeft>
      <div class="flex-grow-1 d-flex flex-column justify-content-center align-items-center my-2">
        <span class="fas fa-exclamation-circle fa-4x text-main"></span>
        <h2 class=" text-center py-3 px-1">
          {{ ('cancellationByCode.errors.' + errorStatus | translate) }}
        </h2>
      </div>
    </ng-template>

    <ng-template #attemptsLeft>
      <div class="rounded mt-4 d-flex flex-column justify-content-center">
        <div class="w-auto m-auto">
          <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center p-4">
            <ng-container *ngIf="channels; then withChannels; else withoutChannels"></ng-container>
            <ng-template #withChannels>
              <p>{{ 'cancellationByCode.body.withChannels' | translate: getChannelsAndContact() }}</p>
            </ng-template>
            <ng-template #withoutChannels>
              <p>{{ 'cancellationByCode.body.withoutChannels' | translate: getChannelsAndContact() }}</p>
            </ng-template>
          </div>
          <div class="input-group justify-content-center px-lg-5">

            <div class="col-2 d-flex justify-content-center">
              <input type="number" [ngModel]='splitCode[0]'
                     class="input-code bg-grey rounded text-center" maxlength="1" readonly/>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <input type="number" [ngModel]='splitCode[1]'
                     class="input-code bg-grey rounded text-center" maxlength="1" readonly/>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <input type="number" [ngModel]='splitCode[2]'
                     class="input-code bg-grey rounded text-center" maxlength="1" readonly/>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <input id="code3" type="string" [(ngModel)]='splitCode[3]' class="input-code bg-grey rounded text-center"
                     maxlength="1"
                     (ngModelChange)="autoTab(3, 3, 4)"
                     (keypress)="disableNanChars($event); fillNextValue($event, 3,4, 5)"
                     (paste)="onPaste($event)"/>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <input id="code4" type="string" [(ngModel)]='splitCode[4]' class="input-code bg-grey rounded text-center"
                     maxlength="1"
                     (ngModelChange)="autoTab(4, 3, 5)"
                     (keypress)="disableNanChars($event);fillNextValue($event, 4,5, 5)"
                     (paste)="onPaste($event)"/>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <input id="code5" type="string" [(ngModel)]='splitCode[5]' class="input-code bg-grey rounded text-center"
                     maxlength="1"
                     (ngModelChange)="autoTab(5, 4, 5)"
                     (keypress)="disableNanChars($event)"
                     (paste)="onPaste($event)"/>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="errorStatus != null" class="row justify-content-center">
        <div class="text-error d-flex align-items-start justify-content-center my-3 px-1">
          <h5 class="text-left">{{ ('cancellationByCode.errors.' + errorStatus | translate) }}</h5>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="!hasReachedMaxAttempts()">
  <div class="row justify-content-center my-2">
    <div class="col-10 col-lg-8">
      <button [disabled]="!isCodeComplete" class="btn btn-block btn-main py-2 mt-3" (click)="cancelBooking()">
        {{'cancellationByCode.cancel' | translate }}
      </button>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="col-10 col-lg-8">
      <button href="" class="btn btn-link btn-xl py-2 px-4" [ngClass]="{disabled: !enableResendOption}" (click)="resendVerificationCode($event)">
        {{'cancellationByCode.requestNewCode' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<app-single-button-modal #modalDialog
                         [headerMessage]="('cancellationByCode.success.title' | translate)"
                         [bodyMessage]="('cancellationByCode.success.body' | translate)"
                         [onClickCallback]="resolveRedirection"
                       >
</app-single-button-modal>
