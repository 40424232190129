import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Process } from '../../model/process/process.model';
import { ProcessGroup } from '../../model/process/process-group.model';
import { ProcessListComponent } from '../process-list/process-list.component';

@Component({
  selector: 'app-process-accordion',
  templateUrl: './process-accordion.component.html'
})
export class ProcessAccordionComponent {
  @Input() groups: ProcessGroup[];
  @Input() processes: Process[];
  @Input() onSelect: (process: Process) => void;

  @ViewChildren(ProcessListComponent) processLists: QueryList<ProcessListComponent>;

  getAccordionIcon(isOpen: boolean) {
    return isOpen ? 'fa-chevron-up' : 'fa-chevron-down';
  }

  filterProcessesByGroup(groupId: number): Process[] {
    return this.processes
      .filter(p => p.groupId === groupId);
  }

  clearSelection(list: ProcessListComponent, process: Process): void {
    this.processLists.forEach(l => {
      l.clearProcessSelection();
    });

    list.selectedProcess = process;
  }
}
