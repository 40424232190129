<app-modal #modal class="modal-body-size">
  <div class="modal-header bg-main text-white text-center d-flex justify-content-center">
    <h4 class="modal-title text-center px-4 font-weight-bold">
      {{headerMessage}}
    </h4>
  </div>
  <div class="modal-body py-3 d-flex justify-content-center">
    <div class="p-0 row d-flex justify-content-center">
      <span
        class="col-12 icon icon-64 w-auto my-4  {{type?.iconClass}} d-flex justify-content-center"></span>
      <p class="col-12 pb-4 px-4 modal-text text-wrap text-center">
        {{bodyMessage}}
      </p>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <div class="mb-2">
      <button id="continueBtn" class="btn btn-main rounded-btn px-5 py-2" (click)="onClick()">
        {{'modals.single-button.button-message' | translate}}
      </button>
    </div>
  </div>
</app-modal>
