import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from 'src/app/global';
import { ModalsComponent } from '../modals/modals.component';

@Component({
  selector: 'app-modal-retry-schedule',
  templateUrl: './modal-retry-schedule.component.html',
  styleUrls: ['./modal-retry-schedule.component.css']
})
export class ModalRetryScheduleComponent implements OnInit {
  @ViewChild(ModalsComponent) defaultModal: ModalsComponent;
  readonly modalId: string = 'modalretryschedule';
  
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  show() {
    this.defaultModal.show();
  }

  retrySchedule() {
    this.router.navigate([Paths.bookingSchedule])
  }

}
