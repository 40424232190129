import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config/config.service';
import { ReservationService } from '../services/reservation/reservation.service';
import { ClientService } from '../services/client/client.service';
import { Paths } from '../global';
import { isVirtualBooking } from '../utils/Utils';

/**
 * Validar que exista departamento seleccionado.
 * <p>
 * Si la funcionalidad no esta activa, no es requerido, por lo que retorna true.
 * <p>
 * Si no se encuentra un objeto <code>Reservation</code> con un <code>Process</code>, redirecciona a home.
 * <p>
 * Si la funcionalidad esta activa:
 * <p>
 * <ul>
 *  <li>Si el proceso seleccionado no es virtual, no es requerido, por lo que retorna true.</li>
 *  <li>Si el proceso es virtual:</li>
 *  <ul>
 *    <li><b>Si el departamento no fue seleccionado</b>, redirecciona a home. (Se intento navegar a alguna pantalla posterior
 *      de la selección de departamento, sin haber seleccionado uno previamente)</li>
 *      <p>
 *    <li>Si el departamento fue seleccionado, retorna true.</li>
 *    <ul>
 * </ul>
 */
@Injectable()
export class DepartmentSelectedGuard implements CanActivate {

  constructor(private router: Router,
              private configService: ConfigService,
              private reservationService: ReservationService,
              private clientService: ClientService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree {
    const departmentSelectionEnabled = this.configService.getDepartmentSelectionEnabled();
    if (!departmentSelectionEnabled) {
      return true;
    }
    const booking = this.reservationService.getReservation();
    if (booking) {
      const process = booking.process;
      if (process) {
        if (isVirtualBooking(booking.selectedAttention?.attentionType)) {
          const client = this.clientService.getNewClient();
          if (client?.departmentId) {
            return true;
          } else {
            return this.router.parseUrl(Paths.home);
          }
        } else {
          return true;
        }
      }
    }
    return this.router.parseUrl(Paths.home);
  }
}
