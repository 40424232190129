import { Router } from '@angular/router';
import { BookingRegisteredCalendarService } from '../../../../utils/calendar/booking-registered-calendar.service';
import { Paths } from '../../../../global';
import { Input } from '@angular/core';
import { customizations } from '../../../../../web-customizations/customizations';

export class DefaultBookingSuccessButtons {

  readonly showSaveOnCalendar = customizations.success.showSaveOnCalendar;
  readonly showCancelButton = customizations.success.showCancelButton;
  @Input() showCancelModal: () => void;

  constructor(public router: Router,
              public bookingRegisteredCalendarService: BookingRegisteredCalendarService) {}

  backToHome() {
    this.router.navigate([Paths.home]);
  }

  saveOnCalendar() {
    this.bookingRegisteredCalendarService.download();
  }
}
