import { ReservationService } from '../../../services/reservation/reservation.service';
import { ClientService } from '../../../services/client/client.service';
import { environment } from '../../../../environments/environment';
import { CPEBookingSuccessSurvey } from './cpe/CPE-booking-success-survey';
import { customizations } from '../../../../web-customizations/customizations';

export function bookingSuccessSurveyFactory(reservationService: ReservationService,
                                            clientService: ClientService) {
  if (environment.environmentName === 'cpe' && !!customizations.success.showSurvey) {
    return new CPEBookingSuccessSurvey(
      reservationService,
      clientService);
  }
}
