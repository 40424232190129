import { Component, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsDatepickerDirective, BsLocaleService, defineLocale, esLocale } from 'ngx-bootstrap';
import { Paths } from '../../global';
import { Router } from '@angular/router';
import { ReservationService } from '../../services/reservation/reservation.service';
import { BookingsService } from '../../services/bookings/bookings.service';
import { Reservation } from '../../model/reservation/reservation.model';
import { ValidDay } from '../../model/reservation/validDay.model';
import { InitialAvailability } from '../../model/reservation/initialAvailability.model';
import { includes, isVirtualBooking } from '../../utils/Utils';
import * as moment from 'moment';
import { DateUtils } from 'src/app/utils/date-utils.service';
import { LoadAvailableTimesRequest } from '../../services/bookings/http/LoadAvailableTimesRequest';
import { LoadAvailabilityRequest } from '../../services/bookings/http/LoadAvailabilityRequest';
import { customizations } from '../../../web-customizations/customizations';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';

defineLocale('es', esLocale);

@Component({
  selector: 'app-booking-schedule',
  templateUrl: './booking-schedule.component.html',
  styleUrls: ['./booking-schedule.component.css']
})
export class BookingScheduleComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  @ViewChild('datePicker')
  datePicker: BsDatepickerDirective;

  readonly title: string = 'Horario de llegada';
  readonly showMainTitle = customizations.schedule.showMainTitle;
  private readonly includeZoneInAddress = customizations.schedule.includeZoneInAddress;
  datepickerModel: Date;
  minDate: Date;
  maxDate: Date;
  readonly datePickerConfig = {
    showWeekNumbers: false,
    isAnimated: true,
    dateInputFormat: 'DD/MM/YYYY'
  };
  daysDisabled = [];
  disabledDates = [];
  availableTimes: string[];
  validDay: ValidDay = null;
  isValidDayError = false;
  reservation: Reservation;
  isVirtualBooking: boolean;
  selectedTime;
  processing = false;
  showProcessInformation: boolean;
  initialDate: Date;
  mobileMargins = 30;
  minWebWindowWidth = 720;
  currentWindowWidth = window.innerWidth;

  @HostListener('window:resize')
  onResizeEvent(): void {
    // This event is required due to the calendar losing placement when the windows width is changed
    const newWidth = document.body.offsetWidth;
    if (this.changedBetweenWebAndMobileWidth(newWidth, this.currentWindowWidth)
      || this.changedBetweenWebAndMobileWidth(this.currentWindowWidth, newWidth)) {
      this.datePicker.hide();
    } else {
      this.adjustCalendarSize();
    }

    this.currentWindowWidth = newWidth;
  }

  constructor(private router: Router,
              readonly bsLocaleService: BsLocaleService,
              private reservationService: ReservationService,
              private bookingsService: BookingsService,
              private dateUtils: DateUtils
  ) {}

  ngOnInit(): void {
    this.bsLocaleService.use('es');
    this.reservation = this.reservationService.getReservation();
    this.isVirtualBooking = isVirtualBooking(this.reservation.selectedAttention?.attentionType);
    this.showProcessInformation = this.reservationService.getProcessInformation();
    this.loadAvailability();
  }

  private loadAvailability() {
    this.processing = true;
    this.getAvailability().subscribe(response => {
        this.setInitialData(response);
        this.isValidDayError = false;
      },
      (error) => {
        this.handleError(error);
      },
      () => this.processing = false);
  }

  private setInitialData(response: InitialAvailability) {
    this.disabledDates = this.obtainDisabledDates(response.notAvailableDates);
    this.minDate = this.dateUtils.toZonedDateAtStartOfDay(response.minAvailableDate);
    this.maxDate = this.dateUtils.toZonedDateAtStartOfDay(response.maxAvailableDate);
    this.datepickerModel = this.dateUtils.toZonedDateAtStartOfDay(response.firstAvailableDate);
    this.initialDate = this.datepickerModel;
    this.daysDisabled = this.calculateDisabledDays(response.validDays);
    this.setTimes(this.datepickerModel, response.times);
  }

  private setTimes(selectedDate: Date, times: string[]) {
    if (this.isValidDay(selectedDate)) {
      this.availableTimes = times;
    } else {
      this.availableTimes = [];
    }
  }

  private loadAvailableTimes(selectedDate: Date) {
    this.processing = true;
    this.getAvailableTimes(selectedDate).subscribe(response => {
        if (response == null) {
          this.availableTimes = [];
        } else {
          this.setTimes(selectedDate, response.availableHours);
        }
        this.isValidDayError = false;
      },
      (error) => {
        this.handleError(error);
      },
      () => this.processing = false);
  }

  private isValidDay(selectedDate: Date) {
    return moment(selectedDate).isSameOrAfter(this.minDate) && !includes(this.daysDisabled, this.datepickerModel.getDay());
  }

  onSelectTime(): void {
    this.reservation.reservationDate = new Date(this.selectedTime);
    this.reservationService.setReservation(this.reservation);
    this.router.navigate([Paths.bookingConfirmation]);
  }

  onDateChange(selectedDate: Date): void {
    if (this.initialDate !== selectedDate) {
      this.initialDate = null;
      this.selectedTime = null;

      if (selectedDate) {
        this.loadAvailableTimes(selectedDate);
      }
    }
  }

  private calculateDisabledDays(validDays: number[]): number[] {
    const fixedDays = validDays.map(day => day === 7 ? 0 : day);
    const allDays = [0, 1, 2, 3, 4, 5, 6];
    return allDays.filter(aD => !includes(fixedDays, aD));
  }

  getAvailableTimes(selectedDate: Date) {
    const stringDate = moment(selectedDate).format('YYYY-MM-DD').toString();
    const request = new LoadAvailableTimesRequest(
      this.reservation.selectedAttention?.id,
      this.reservation.customerCenter.id,
      this.reservation.client.documentNumber, stringDate);
    return this.bookingsService.loadAvailableTimes(request);
  }

  getAvailability() {
    const request = new LoadAvailabilityRequest(
      this.reservation.selectedAttention?.id,
      this.reservation.customerCenter.id,
      this.reservation.client.documentNumber);
    return this.bookingsService.loadAvailability(request);
  }

  private obtainDisabledDates(notAvailableDates: string[]) {
    return notAvailableDates.map(d => moment(d));
  }

  private handleError(error) {
    if (this.isInvalidDateByDocument(error)) {
      this.isValidDayError = true;
      this.availableTimes = [];
      this.processing = false;
    } else {
      this.router.navigate([Paths.error]);
    }
  }

  private isInvalidDateByDocument(e): boolean {
    return e.status === 400 && e.error.errorCode === 'INVALID_DATE_BY_DOCUMENT';
  }

  setAsOpened(): void {
    document.getElementsByTagName('bs-datepicker-container')[0].classList.add('datepickerCalendarContainer');
    document.getElementById('datepickerInputContainer').classList.add('drop-opened');

    this.adjustCalendarSize();
  }

  setAsHidden(): void {
    document.getElementById('datepickerInputContainer').classList.remove('drop-opened');
  }

  private isMobileView(): boolean {
    return this.currentWindowWidth < this.minWebWindowWidth;
  }

  getPlacement(): string {
    return this.isMobileView() ? 'bottom' : 'right';
  }

  private adjustCalendarSize() {
    const calendar = document.getElementsByClassName('bs-datepicker')[0] as HTMLElement;
    const calendarContent = document.getElementsByClassName('bs-datepicker-container')[0] as HTMLElement;

    if (this.isMobileView() && calendar) {
      calendar.style.width = window.innerWidth - this.mobileMargins + 'px';
      calendarContent.style.width = calendar.style.width;
    }
  }

  private changedBetweenWebAndMobileWidth(firstWidth, secondWidth) {
    return firstWidth < this.minWebWindowWidth && secondWidth >= this.minWebWindowWidth;
  }

  buildCustomerCenterAddress(customerCenter: CustomerCenter): string {
    return CustomerCenter.buildFullAddress(customerCenter, this.includeZoneInAddress);
  }
}
