import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BookingRegisteredCalendarService } from '../../../../../utils/calendar/booking-registered-calendar.service';
import { DefaultBookingSuccessButtons } from '../default-booking-success-buttons';

@Component({
  selector: 'app-default-booking-success-mobile-buttons',
  templateUrl: './default-booking-success-mobile-buttons.component.html'
})
export class DefaultBookingSuccessMobileButtonsComponent extends DefaultBookingSuccessButtons {

  constructor(public router: Router,
              public bookingRegisteredCalendarService: BookingRegisteredCalendarService) {
    super(router, bookingRegisteredCalendarService);
  }
}
