import { ReCaptchaV3Service } from "ng-recaptcha";
import { Injectable, Optional } from "@angular/core";

import { SessionStorageService } from "../session-storage/session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class BookingsCaptchaService {
  private readonly _tokenStorageKey = "recaptcha-token";

  constructor(
    private sessionStorageService: SessionStorageService,
    @Optional() private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  /**
   * Executes the reCAPTCHA v3 validation and stores the encoded token in sessionStorage if validation succeeds.
   * @param action - The action name to be used for the reCAPTCHA v3 validation.
   * @param onContinue - The callback function to be executed if validation succeeds.
   * @param onError - The callback function to be executed if validation fails.
   */
  execute(action: string, onContinue: () => void, onError: () => void): void {
    if (!this.reCaptchaV3Service) {
      return onContinue();
    }

    this.reCaptchaV3Service
      .execute(action)
      .subscribe(
        (token) =>
          token ? (this.storeEncodedToken(token), onContinue()) : onError(),
        onError
      );
  }

  /**
   * Encodes the received reCAPTCHA v3 token and stores it in sessionStorage.
   * @param token - The reCAPTCHA v3 token to be encoded and stored.
   */
  private storeEncodedToken(token: string): void {
    const encodedToken = btoa(token);
    this.sessionStorageService.setItem(this._tokenStorageKey, encodedToken);
  }

  /**
   * Retrieves the encoded reCAPTCHA v3 token from sessionStorage and removes it.
   * @returns The decoded reCAPTCHA v3 token, or null if the token is not found.
   */
  getDecodedToken(): string | null {
    const encodedToken = this.sessionStorageService.getItem(
      this._tokenStorageKey
    );
    if (encodedToken) {
      this.sessionStorageService.removeItem(this._tokenStorageKey);
      return atob(encodedToken);
    }
    return null;
  }
}
