import { InjectionToken } from '@angular/core';

export const ERROR_INTERCEPTOR_API_WHITE_LIST = new InjectionToken<string[]>('errorInterceptorAPIWhiteList');

export const URLS = (baseURL: string) => {
  const findAllCustomerCenters = `${baseURL}/public/v2/customer-centers`;

  return {
    client: {
      login: `${baseURL}/public/v1/booking-validation`
    },
    customerServices: {
      filterByLocationAndProcess: (locationId: number, attentionId: number) =>
        `${findAllCustomerCenters}?locationId=${locationId}&attentionId=${attentionId}`,
      filterByProcess: (attentionId: number) => `${findAllCustomerCenters}?attentionId=${attentionId}`,
      filterNearby: `${baseURL}/public/v1/customer-centers/nearby`
    },
    locationsByProcess: {
      all: (attentionId: number) => `${baseURL}/public/v1/locations?attentionId=${attentionId}`
    },
    bookings: {
      getAvailableTimes: `${baseURL}/public/v1/processes/available-hours`,
      getInitialAvailability: `${baseURL}/public/v1/processes/initial-availability`,
      save: `${baseURL}/public/v1/bookings/registration`,
      edit: (docType: string, docNumber: string) => `${baseURL}/clients/${docType}/${docNumber}/bookings/reschedule`,
      cancel: `${baseURL}/public/v1/bookings/active/cancellation`,
      confirm: `${baseURL}/public/v1/bookings/active/confirmation`,
      get: (docType: string, docNumber: string) => `${baseURL}/clients/${docType}/${docNumber}/bookings/active/customer-center`,
      findByEncryptedId: `${baseURL}/public/v1/bookings/reminder`,
      cancelBookingByEncryptedId: `${baseURL}/public/v1/bookings/reminder/cancel`,
      confirmBookingByEncryptionId: `${baseURL}/public/v1/bookings/reminder/confirm`,
      resendNotification: `${baseURL}/public/v1/bookings/active/resend-notifications`,
      findActiveByPhoneNumber: `${baseURL}/public/v1/bookings/active/validation-by-phone`,
      cancelByCode: {
        request: `${baseURL}/public/v1/bookings/cancellation-code/request`,
        cancel: `${baseURL}/public/v1/bookings/cancellation-code/cancel`
      }
    },
    process: {
      loadProcesses: `${baseURL}/public/v3/processes/active`
    },
    countryRules: {
      loadConfig: `${baseURL}/public/v1/config`
    },
    phoneVerification: {
      resendCode: `${baseURL}/public/v1/phone-number/code/forward`,
      generateCode: `${baseURL}/public/v1/phone-number/code/request`,
      validateCode: `${baseURL}/public/v1/phone-number/code/validate`
    },
    recaptcha: {
      validate: `${baseURL}/public/v1/recaptchav3/validate`
    },
    departments: {
      active: `${baseURL}/public/v1/departments/active`
    },
    externalClient: {
      validateBooking: `${baseURL}/public/v1/external-client/booking-validation`,
      registerBooking: `${baseURL}/public/v1/external-client/booking-registration`,
      cancelBooking: `${baseURL}/public/v1/external-client/booking-cancellation`,
      cancelByCode: {
        request: `${baseURL}/public/v1/external-client/bookings/cancellation-code/request`,
        cancel: `${baseURL}/public/v1/external-client/bookings/cancellation-code/cancel`
      }
    }
  };
};
