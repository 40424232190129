import { DateUtils } from 'src/app/utils/date-utils.service';
import { OriginDetectorService } from '../../origin-detector/origin-detector.service';
import { GTMBookingEventRequest } from '../gtm-booking-event-request';
import { GTMClient } from '../gtm-client';
import { GTMEventPublisher } from '../gtm-event-publisher';
import { EntelCLGTMBookingEvent } from './entel-cl-gtm-booking-event';
import { EntelCLGTMEvent } from './entel-cl-gtm-event';

export class EntelCLGTMEventPublisher implements GTMEventPublisher {

  constructor(private gtmClient: GTMClient,
              private originDetectorService: OriginDetectorService,
              private dateUtil: DateUtils) {
  }

  publishHomePageLandingEvent() {
    const origin = this.originDetectorService.inSessionOrGet();
    const entelGTMEvent = new EntelCLGTMEvent();
    entelGTMEvent.event = 'GARDNIevent';
    entelGTMEvent.eventCategory = 'agendamiento - ingresa';
    entelGTMEvent.eventAction = 'info';
    entelGTMEvent.eventLabel = 'carga';
    entelGTMEvent.origen = origin;
    entelGTMEvent.segmento = 'otros';
    entelGTMEvent.mercado = 'pe';
    entelGTMEvent.page_owner = 'publico';
    entelGTMEvent.page_type = 'registro';
    entelGTMEvent.form_step_name = 'paso 0 - ingresar numero de documento';
    entelGTMEvent.journey_type = 'solicitud';
    this.gtmClient.pushEvent(entelGTMEvent);
  }

  publishBookingEvent(request: GTMBookingEventRequest): void {
    const origin = this.originDetectorService.inSessionOrGet();
    const client = request.client;
    const booking = request.booking;
    const event = new EntelCLGTMBookingEvent();
    event.event = 'GARDNIevent';
    event.eventCategory = 'agendamiento - resumen - conversion';
    event.eventAction = 'info';
    event.eventLabel = 'carga';
    event.origen = origin;
    event.segmento = 'otros';
    event.mercado = 'pe';
    event.page_owner = 'publico';
    event.page_type = request.bookingResult.successful ? 'exito' : 'error';
    event.form_step_name = 'paso 7 - resumen de tu agendamiento';
    event.journey_type = 'solicitud';
    event.rut = this.encodeBase64(client.docNumber);
    event.nombre = this.encodeBase64(client.firstName + ' ' + client.lastName);
    event.email = this.encodeBase64(client.email);
    event.telefono_cliente = this.encodeBase64(client.phoneNumber);
    event.variant = booking.process.name?.toLowerCase();
    event.region = booking.customerCenter.location.name?.toLowerCase();
    event.comuna = booking.customerCenter.zone?.toLowerCase();
    event.direccion = this.encodeBase64(booking.customerCenter.address?.toLowerCase());
    event.variant2 = booking.customerCenter.name?.toLowerCase();
    event.date = this.dateUtil.toZonedDateWithFormat(booking.reservationDate, 'dd-MM-yyyy');
    event.horario = this.dateUtil.toZonedDateWithFormat(booking.reservationDate, 'hh:mm a').toLowerCase();
    event.variant3 = this.resultMessage(request);
    event.p_interacciones = 'true';
    this.gtmClient.pushEvent(event);
  }

  private encodeBase64(str: string): string {
    return str ? btoa(str) : '';
  }

  private resultMessage(request: GTMBookingEventRequest) {
    const bookingResult = request.bookingResult;
    if (bookingResult.successful) {
      return 'agendamiento ejecutado con éxito';
    } else {
      return 'codigo: ' + bookingResult.errorCode + ' mensaje: ' + bookingResult.errorMessage?.toLowerCase();
    }
  }
}
