import { Client } from '../client/client.model';
import { AttentionService } from '../customerAttention/attentionService.model';
import { Process } from '../process/process.model';
import { CustomerCenter } from '../customerAttention/customerCenter.model';
import { Attention } from '../process/attention.model';

export class Reservation {
  id: number;
  client: Client;
  service: AttentionService;
  process: Process;
  customerCenter: CustomerCenter;
  reservationDate: Date;
  currentlyConfirmed: boolean;
  selectedAttention: Attention;
}
