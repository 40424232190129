import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DocumentType } from '../../../model/client/documenttype.model';

export function businessNameValidator(documentType: DocumentType): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const businessName = control.value;

    return !(documentType.enumName === 'CUIT' && businessName) ? { 'invalidDocumentNumber': true } : null;
  };
}
