import { AbstractControl, ValidatorFn } from '@angular/forms';
import { validatePattern } from '../../../utils/validators/validatePattern';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailPattern = /^[^.].*[^.]@[^.](\w|\w-\w)*(\.[a-z]{2,})+$/i;
    const email = control.value;
    let valid = validatePattern(email, emailPattern);
    return !valid ? { 'emailFormat': true } : null;
  };
}
