<div class="row justify-content-center">
  <div
    class="flex-grow-1 d-flex row d-lg-none justify-content-center btn-cont mt-3">
    <button *ngIf="!showSaveOnCalendar" class="btn btn-main d-flex justify-content-center align-items-center col-10" (click)="backToHome()">
      Volver al inicio
    </button>
    <button *ngIf="showSaveOnCalendar" class="btn btn-main d-flex justify-content-center align-items-center col-10" (click)="saveOnCalendar()">
      <span class="icon icon-lg icon-save-on-calendar w-auto text-white d-flex align-self-center mr-2"></span>
      Agregar a tu calendario
    </button>
    <app-booking-actions *ngIf="showCancelButton" [showCancelModalCallback]="showCancelModal" [isCreating]="false"></app-booking-actions>
  </div>
</div>
