import { Component, HostBinding } from '@angular/core';
import { CPEBookingSuccessButtons } from '../cpe-booking-success-buttons';
import { BookingRegisteredCalendarService } from '../../../../../utils/calendar/booking-registered-calendar.service';
import { ExternalClientService } from '../../../../../services/externalClient/external-client.service';
import { DeviceDetectorService } from '../../../../../utils/device-detector.service';
import { CPEOuterFunctionService } from '../../../../../utils/cpe-outer-function.service';
import { IllegalComponentBehaviourNotifier } from '../../../../../utils/illegal-component-behaviour-notifier.service';

@Component({
  selector: 'app-cpe-booking-success-desk-buttons',
  templateUrl: './cpe-booking-success-desk-buttons.component.html'
})
export class CpeBookingSuccessDeskButtonsComponent extends CPEBookingSuccessButtons {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  constructor(public bookingRegisteredCalendarService: BookingRegisteredCalendarService,
              public externalClientService: ExternalClientService,
              public deviceDetectorService: DeviceDetectorService,
              public cpeOuterFunctionService: CPEOuterFunctionService,
              public icbNs: IllegalComponentBehaviourNotifier) {
    super(bookingRegisteredCalendarService, externalClientService, deviceDetectorService, cpeOuterFunctionService, icbNs);
  }
}
