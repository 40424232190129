import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingActionsComponent } from './components/booking-actions/booking-actions.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { CustomerCenterItemComponent } from './components/customer-center-item/customer-center-item.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalCancelBookingComponent } from './components/modal-cancel-booking/modal-cancel-booking.component';
import { ModalConditionsComponent } from './components/modal-conditions/modal-conditions.component';
import { ModalLogoutComponent } from './components/modal-logout/modal-logout.component';
import { ModalsComponent } from './components/modals/modals.component';
import { ProcessAccordionComponent } from './components/process-accordion/process-accordion.component';
import { ProcessListComponent } from './components/process-list/process-list.component';
import { ActiveBookingComponent } from './pages/active-booking/active-booking.component';
import { BookingConfirmationComponent } from './pages/booking-confirmation/booking-confirmation.component';
import { BookingScheduleComponent } from './pages/booking-schedule/booking-schedule.component';
import { BookingSuccessComponent } from './pages/booking-success/booking-success.component';
import { CustomerCenterComponent } from './pages/customer-center/customer-center.component';
import { ErrorComponent } from './pages/error/error.component';
import { HomeComponent } from './pages/home/home.component';
import { ProcessComponent } from './pages/process/process.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { BookingsService } from './services/bookings/bookings.service';
import { ClientService } from './services/client/client.service';
import { ReservationService } from './services/reservation/reservation.service';
import { DateToStringPipe } from './utils/pipes/DateToStringPipe';
import { ShortTimePipe } from './utils/pipes/ShortTimePipe';
import { StringToDatePipe } from './utils/pipes/StringToDatePipe';
import { StringToColorPipe } from './utils/StrToColorPipe';

import { DatePipe, DOCUMENT } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { deviceType, isAndroid, isIOS, isMobile } from 'mobile-device-detect';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AccordionModule, CollapseModule } from 'ngx-bootstrap';
import { NgxHotjarModule } from 'ngx-hotjar';
import { environment } from '../environments/environment';
import { GTMConfig } from '../environments/gtm-config';
import { ERROR_INTERCEPTOR_API_WHITE_LIST } from '../environments/urls';
import { CancelBookingCodeboxComponent } from './components/cancel-booking-codebox/cancel-booking-codebox.component';
import { FooterComponent } from './components/footer/footer.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { ModalRetryScheduleComponent } from './components/modal-retry-schedule/modal-retry-schedule.component';
import { SingleButtonModalComponent } from './components/modals/single-button-modal/single-button-modal.component';
import { VerificationNumberDetailsComponent } from './components/verification-number-details/verification-number-details.component';
import { AttentionTypeComponent } from './pages/attention-type/attention-type.component';
import { BookingCancellationByCodeComponent } from './pages/booking-cancellation-by-code/booking-cancellation-by-code.component';
import { CpeBookingSuccessDeskButtonsComponent } from './pages/booking-success/buttons/cpe/cpe-booking-success-desk-buttons/cpe-booking-success-desk-buttons.component';
import { CpeBookingSuccessMobileButtonsComponent } from './pages/booking-success/buttons/cpe/cpe-booking-success-mobile-buttons/cpe-booking-success-mobile-buttons.component';
import { DefaultBookingSuccessDeskButtonsComponent } from './pages/booking-success/buttons/default/default-booking-success-desk-buttons/default-booking-success-desk-buttons.component';
import { DefaultBookingSuccessMobileButtonsComponent } from './pages/booking-success/buttons/default/default-booking-success-mobile-buttons/default-booking-success-mobile-buttons.component';
import { BookingSuccessSurvey } from './pages/booking-success/survey/booking-success-survey';
import { bookingSuccessSurveyFactory } from './pages/booking-success/survey/booking-success-survey-factory';
import { ClientDepartmentComponent } from './pages/client-location/client-department.component';
import { OrderDepartmentByNamePipe } from './pages/client-location/OrderDepartmentByNamePipe';
import { ConfirmationExpiredComponent } from './pages/confirmation-expired/confirmation-expired.component';
import { ConfirmationReminderComponent } from './pages/confirmation-reminder/confirmation-reminder.component';
import { ConfirmationSuccessComponent } from './pages/confirmation-success/confirmation-success.component';
import { CpeWelcomePageComponent } from './pages/cpe-langing-page/cpe-welcome-page.component';
import { ErrorPageExitButtonBehaviour } from './pages/error/exit-button/behaviour/error-page-exit-button-behaviour';
import { errorPageExitButtonBehaviourFactory } from './pages/error/exit-button/behaviour/error-page-exit-button-behaviour-factory';
import { ErrorPageExitButtonVisibility } from './pages/error/exit-button/visibility/error-page-exit-button-visibility';
import { errorPageExitButtonVisibilityFactory } from './pages/error/exit-button/visibility/error-page-exit-button-visibility-factory';
import { ExternalClientComponent } from './pages/external-client/external-client.component';
import { NearbyCustomerCentersComponent } from './pages/nearby-customer-centers/nearby-customer-centers.component';
import { PhoneVerificationComponent } from './pages/phone-verification/phone-verification.component';
import { ReminderActiveBookingSuccessComponent } from './pages/reminder-active-booking-success/reminder-active-booking-success.component';
import { ReminderActiveBookingComponent } from './pages/reminder-active-booking/reminder-active-booking.component';
import { BookingsCaptchaService } from './services/captcha/BookingsCaptchaService';
import { ConfigService } from './services/config/config.service';
import { ConfigLoaderService } from './services/config/ConfigLoaderService';
import { ExternalClientService } from './services/externalClient/external-client.service';
import { EntelCLGTMEventPublisher } from './services/gtm/customs/entel-cl-gtm-event-publisher';
import { GTMClient } from './services/gtm/gtm-client';
import { GTMEventPublisher } from './services/gtm/gtm-event-publisher';
import { EntelCLOriginDetectorService } from './services/origin-detector/customs/entel-cl-origin-detector.service';
import { OriginDetectorService } from './services/origin-detector/origin-detector.service';
import { BookingRegisteredCalendarService } from './utils/calendar/booking-registered-calendar.service';
import { CalendarIcsFileService } from './utils/calendar/calendar-ics-file-service';
import { ClientWithBookingResolverService } from './utils/client-with-booking/client-with-booking-resolver.service';
import { CPEOuterFunctionService } from './utils/cpe-outer-function.service';
import { DeviceDetectorService, DEVICE_TYPE, IS_ANDROID, IS_IOS, IS_MOBILE } from './utils/device-detector.service';
import { AlphasOnlyDirective } from './utils/directives/AlphasOnlyDirective';
import { NumbersOnlyDirective } from './utils/directives/NumbersOnlyDirective';
import { NewRelicErrorHandler } from './utils/handlers/new-relic-error-handler.service';
import { IllegalComponentBehaviourNotifier } from './utils/illegal-component-behaviour-notifier.service';
import { ErrorHandlerHttpInterceptor } from './utils/interceptors/error-handler-http-interceptor.service';
import { TokenInterceptor } from './utils/interceptors/token.interceptor';
import { appendFacebookVerificationMetadata } from './utils/Utils';
import { DateUtils } from './utils/date-utils.service';

export const GTM_CONFIG = new GTMConfig(environment.gtmId);

@NgModule({
  declarations: [
    StringToColorPipe,
    DateToStringPipe,
    StringToDatePipe,
    ShortTimePipe,
    OrderDepartmentByNamePipe,
    AppComponent,
    RegistrationComponent,
    ActiveBookingComponent,
    HomeComponent,
    ProcessComponent,
    ProcessListComponent,
    ProcessAccordionComponent,
    CustomerCenterComponent,
    BookingScheduleComponent,
    BookingConfirmationComponent,
    BookingSuccessComponent,
    ErrorComponent,
    HeaderComponent,
    LoadingComponent,
    CustomerCenterItemComponent,
    ModalCancelBookingComponent,
    ModalLogoutComponent,
    BookingActionsComponent,
    BookingDetailsComponent,
    ModalConditionsComponent,
    TermsAndConditionsComponent,
    ModalsComponent,
    SingleButtonModalComponent,
    PhoneVerificationComponent,
    VerificationNumberDetailsComponent,
    ConfirmationReminderComponent,
    ConfirmationSuccessComponent,
    ConfirmationExpiredComponent,
    ReminderActiveBookingComponent,
    ReminderActiveBookingSuccessComponent,
    ModalRetryScheduleComponent,
    AlphasOnlyDirective,
    NumbersOnlyDirective,
    ClientDepartmentComponent,
    ItemListComponent,
    FooterComponent,
    AttentionTypeComponent,
    ExternalClientComponent,
    CpeWelcomePageComponent,
    NearbyCustomerCentersComponent,
    BookingCancellationByCodeComponent,
    CancelBookingCodeboxComponent,
    DefaultBookingSuccessMobileButtonsComponent,
    CpeBookingSuccessMobileButtonsComponent,
    DefaultBookingSuccessDeskButtonsComponent,
    CpeBookingSuccessDeskButtonsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    environment.recaptchaV3SiteKey ? RecaptchaV3Module : [],
    environment.gtmId ? GoogleTagManagerModule.forRoot({ id: GTM_CONFIG.id }) : [],
    environment.hotjarid ? NgxHotjarModule.forRoot(environment.hotjarid) : []
  ],
  providers: [
    BookingsService,
    ClientService,
    ReservationService,
    ConfigService,
    ConfigLoaderService,
    BookingsCaptchaService,
    ExternalClientService,
    IllegalComponentBehaviourNotifier,
    DeviceDetectorService,
    ClientWithBookingResolverService,
    CPEOuterFunctionService,
    CalendarIcsFileService,
    BookingRegisteredCalendarService,
    DatePipe,
    { provide: Document, useExisting: DOCUMENT },
    {
      provide: IS_MOBILE,
      useValue: isMobile
    },
    {
      provide: IS_IOS,
      useValue: isIOS
    },
    {
      provide: IS_ANDROID,
      useValue: isAndroid
    },
    {
      provide: DEVICE_TYPE,
      useValue: deviceType
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ERROR_INTERCEPTOR_API_WHITE_LIST,
      useValue: [
        environment.services.externalClient.validateBooking,
        environment.services.externalClient.registerBooking
      ]
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    {
      provide: GTMConfig,
      useValue: GTM_CONFIG
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey
    },
    {
      provide: BookingSuccessSurvey,
      useFactory: bookingSuccessSurveyFactory,
      deps: [
        ReservationService,
        ClientService
      ]
    },
    {
      provide: GTMEventPublisher,
      useFactory: gtmEventPublisherFactory,
      deps: [
        Injector,
        OriginDetectorService,
        DateUtils
      ]
    },
    {
      provide: OriginDetectorService,
      useFactory: originDetectorServiceFactory,
      deps: [
        ActivatedRoute,
        ConfigService
      ]
    },
    {
      provide: ErrorPageExitButtonBehaviour,
      useFactory: errorPageExitButtonBehaviourFactory,
      deps: [
        Router,
        ExternalClientService,
        DeviceDetectorService,
        CPEOuterFunctionService,
        IllegalComponentBehaviourNotifier
      ]
    },
    {
      provide: ErrorPageExitButtonVisibility,
      useFactory: errorPageExitButtonVisibilityFactory,
      deps: [
        ExternalClientService,
        DeviceDetectorService
      ]
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector,
              private gtmConfig: GTMConfig) {
    if (gtmConfig.isEnabled()) {
      const gts = this.injector.get(GoogleTagManagerService);
      gts.addGtmToDom();
    }

    appendFacebookVerificationMetadata(environment.facebookDomainCode);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/messages/', '.json');
}

export function errorHandlerFactory() {
  if (environment.newrelic) {
    return new NewRelicErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

export function gtmEventPublisherFactory(inj: Injector, ods: OriginDetectorService, du: DateUtils) {
  if (environment.gtmId) {
    if (environment.clientName === 'Entel-CL') {
      return new EntelCLGTMEventPublisher(new GTMClient(inj.get(GoogleTagManagerService)), ods, du);
    }
  }
}

export function originDetectorServiceFactory(activatedRouter: ActivatedRoute, configService: ConfigService) {
  if (environment.clientName === 'Entel-CL') {
    return new EntelCLOriginDetectorService(activatedRouter, configService);
  }
}
