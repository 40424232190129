import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { ConfigService } from '../services/config/config.service';
import { Paths } from '../global';

@Injectable()
export class PhoneVerificationEnabledGuard implements CanActivate {

  constructor(private router: Router, private clientService: ClientService, private configService: ConfigService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const shouldVerifyPhoneNumber = this.configService.getShouldVerifyPhoneNumber();
    const phoneVerification = this.clientService.getPhoneVerification();
    const isCodeRequestedAndNotVerified = phoneVerification && !phoneVerification.isVerified;

    if (shouldVerifyPhoneNumber && isCodeRequestedAndNotVerified) {
      return true;
    }

    return this.router.parseUrl(Paths.home);
  }
}
