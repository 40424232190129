<app-modal #modal [modalId]="modalId">
  <div class="modal-body">
    <div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.hide()">
        <h3><span class="fas fa-times"></span></h3>
      </button>
    </div>
    <div class="pt-3 m-3">
      <h3 class="text-secondary text-nowrap text-center mb-3">
        ¿Está seguro que quiere cancelar la visita?
      </h3>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-10 col-lg-8">
        <button id="acceptBtn" class="btn btn-block btn-main py-2" data-dismiss="modal" (click)="cancelBookingOnClick()">
          Aceptar
        </button>
        <button id="cancelBtn" class="btn btn-block btn-link" data-dismiss="modal" (click)="modal.hide()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</app-modal>
