import { GTMBookingEventRequest } from './gtm-booking-event-request';

/**
 * Publicador de eventos a GTM.
 * Abstracto por que cada cliente tendra su propia implementación de como envíar los mensajes a GTM
 */
export abstract class GTMEventPublisher {

  /**
   * Publica evento a GTM de llegada a página de Inicio: "/home"
   */
  abstract publishHomePageLandingEvent(): void;

  /**
   * Publica evento a GTM de registración de reserva.
   */

  abstract publishBookingEvent(gtmBookingEvent: GTMBookingEventRequest): void;

}
