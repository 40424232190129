export class LoadAvailableTimesRequest {
  attentionId: number;
  customerCenterId: number;
  documentNumber: string;
  date: string;

  constructor(attentionId: number, customerCenterId: number, documentNumber: string, date: string) {
    this.attentionId = attentionId;
    this.customerCenterId = customerCenterId;
    this.documentNumber = documentNumber;
    this.date = date;
  }
}
