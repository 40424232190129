import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { replaceAll } from '../../utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  public buildAreaHeader() {
    const area = this.getAreaFromURL();

    if (area) {
      return {
        'X-Area': area
      }
    }

    return null;
  }

  private getAreaFromURL(): string {
    const frontURL = environment.frontURL;
    const hostname = this.getHostname().replace('www.', '');

    let area: string = hostname.replace(frontURL.domain, '');

    if (frontURL.subdomains.length > 0) {
      for(let subdomain of frontURL.subdomains) {
        if (area.includes(subdomain)) {
          area = area.replace(subdomain, '');

          break;
        }
      }
    }

    area = replaceAll(area, '.', '');

    return area.length == 0 ? null : area;
  }

  private getHostname(): string {
    return window.location.hostname;
  }
}
