import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { LoginResponse } from '../../model/client/loginResponse.model';
import { Paths } from '../../global';

@Injectable({
  providedIn: 'root'
})
export class ClientWithBookingResolverService {

  constructor(private router: Router,
              private sessionStorageService: SessionStorageService) {
  }

  resolve(loginResponse: LoginResponse): void {
    if (loginResponse.canConfirm) {
      this.sessionStorageService.setItem('canConfirm', true);
      this.router.navigate([Paths.reminderActiveBooking]);
    } else {
      this.router.navigate([Paths.activeBooking]);
    }
  }
}
