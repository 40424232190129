/**
 * Implementar para definir segun reglas de Negocio de cada cliente si mostrar o no el botón salir de la pantalla
 * de error por defecto.
 */
export abstract class ErrorPageExitButtonVisibility {

    /**
     * True o False dependiendo si el botón Salir de la página de error genérica debe mostrarse o no.
     * Solo invocado si el botón no debe ocultarse de forma explícita
     */
    abstract showExitButton(): boolean;
}
