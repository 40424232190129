import { Location } from '../locations/location.model';

export class CustomerCenter {
  id: number;
  name: string;
  address: string;
  zone: string;
  location?: Location;

  public static buildFullAddress(customerCenter: CustomerCenter, includeZone): string {
    return includeZone && !!customerCenter.zone
      ? `${customerCenter.address} - ${customerCenter.zone}`
      : customerCenter.address;
  }
}
