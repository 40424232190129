import { Inject, Injectable, InjectionToken } from '@angular/core';

export const IS_MOBILE = new InjectionToken<boolean>('isMobile');
export const IS_IOS = new InjectionToken<boolean>('isIOs');
export const IS_ANDROID = new InjectionToken<boolean>('isAndroid');
export const DEVICE_TYPE = new InjectionToken<boolean>('deviceType');

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  private readonly mobileFlag;
  private readonly iosFlag;
  private readonly androidFlag;
  private readonly deviceType;

  constructor(@Inject(IS_MOBILE) isMobile: boolean,
              @Inject(IS_IOS) isIOS: boolean,
              @Inject(IS_ANDROID) isAndroid: boolean,
              @Inject(DEVICE_TYPE) deviceType: string) {
    this.mobileFlag = isMobile;
    this.iosFlag = isIOS;
    this.androidFlag = isAndroid;
    this.deviceType = deviceType;
  }

  isMobile(): boolean {
    return this.mobileFlag;
  }

  isIOS(): boolean {
    return this.iosFlag;
  }

  isAndroid(): boolean {
    return this.androidFlag;
  }

  isAndroidOrIOS(): boolean {
    return this.isMobile()
      && (this.isAndroid() || this.isIOS());
  }

  getDeviceType(): string {
    return this.deviceType;
  }
}
