import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { ModalLogoutComponent } from '../modal-logout/modal-logout.component';
import { ExternalClientService } from '../../services/externalClient/external-client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() showGoBack = true;
  @Input() showLogout = undefined;
  @ViewChild(ModalLogoutComponent) modalLogout: ModalLogoutComponent;

  constructor(private location: Location,
              private locationStrategy: LocationStrategy,
              private externalClientService: ExternalClientService) {
  }

  ngOnInit(): void {
    if (!this.externalClientService.isValidForExternalClientBookingOperation()) {
      if (!this.showGoBack) {
        history.pushState(null, null, window.location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
      }
    }
  }

  goBack(): boolean {
    this.location.back();
    return false;
  }

  shouldShowLogoutButton(): boolean {
    if (this.showLogout === undefined) {
      return !this.externalClientService.isValidForExternalClientBookingOperation();
    }
    return this.showLogout;
  }

  shouldShowGoBackLabel(): boolean {
    if (this.showGoBack) {
      return this.externalClientService.isValidForExternalClientBookingOperation();
    }
    return false;
  }

  showLogoutModal() {
    this.modalLogout.show();
  }
}
