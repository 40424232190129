<app-loading *ngIf="processing"></app-loading>
<section class="bg-two-colors bg-two-colors-media flex-grow-1 d-flex flex-column">
  <div class="bg-main d-flex flex-column">
    <app-header [showGoBack]="enableBackButton"></app-header>
    <div class="info-cont w-100">
      <div class="container-xl text-white">
        <div class="row">
          <div class="col text-center">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2 mt-3">
    <div class="container-xl d-flex flex-column flex-grow-1">
      <div class="row h-100 justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="h-100 d-flex flex-column flex-grow-1">
            <div class="justify-content-left d-none d-sm-block ">
              <h1 class="mb-3 text-left text-white text-title custom-main-title">
                {{'process.title' | translate}}
              </h1>
            </div>
            <div class="ios-content-filler bg-white rounded-box shadow-custom overflow-hidden d-flex flex-grow-1 d-flex flex-column pb-3 mb-3 process-box">
              <h1 class="justify-content-center text-center text-black-50 text-title d-sm-none my-4 custom-main-title">
                {{'process.title' | translate}}
              </h1>
              <div class="flex-grow-1 d-flex flex-column">
                <div class="flex-grow-1 d-flex flex-column">
                  <ng-container>
                    <div id="processesContainer"
                      class="flex-grow-1 d-flex flex-column align-items-center position-relative px-2 my-1" style="overflow-y: auto !important;">
                      <div class="process-container position-absolute overflow-auto d-flex flex-column flex-grow-1 justify-content-left w-100">
                        <ng-container *ngIf="shouldShowSingleList(); else accordionGroup">
                          <div class="process-list-container px-2 pt-1">
                            <app-process-list [processes]="processes" (onSelect)="selectProcess($event)"></app-process-list>
                          </div>
                        </ng-container>
                        <ng-template #accordionGroup>
                          <app-process-accordion [groups]="processGroups"
                                                 [processes]="processes"
                                                 [onSelect]="selectProcess"></app-process-accordion>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center justify-content-lg-end mr-lg-4">
                      <div
                        class="flex-grow-1 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start">
                        <button class="btn btn-main btn-xl col-10 col-md-5" (click)="confirmSelection()" [disabled]="!selectedProcess">
                          Continuar
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
