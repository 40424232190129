import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Paths } from '../../global';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/services/client/client.service';
import { ReservationService } from '../../services/reservation/reservation.service';
import { BookingsService } from 'src/app/services/bookings/bookings.service';
import { NewClientRequest } from 'src/app/model/client/newClientRequest.model';
import { PhoneVerificationRequest } from 'src/app/model/sms/phoneVerificationRequest.model';
import { ValidationCodeResult } from './validationCodeResult';

@Component({
  selector: 'app-verification-number-details',
  templateUrl: './verification-number-details.component.html'
})
export class VerificationNumberDetailsComponent implements OnInit, OnDestroy {

  private readonly resendDelay = 1000 * 15;
  private resendEnabler$ = new BehaviorSubject('');
  private resendEnablerSubscription: Subscription;

  code: string[] = new Array(6);
  client: NewClientRequest;
  processing: boolean = false;
  phoneVerification: PhoneVerificationRequest;
  validationCodeError: boolean = false;
  showResendOption: boolean = false;

  constructor(private router: Router,
              private clientService: ClientService,
              private reservationService: ReservationService,
              private bookingService: BookingsService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.client = this.clientService.getNewClient();
    this.phoneVerification = this.clientService.getPhoneVerification();
    this.fillCodeInput(this.phoneVerification.code);

    this.resendEnablerSubscription = this.resendEnabler();
  }

  ngOnDestroy(): void {
    this.resendEnablerSubscription.unsubscribe();
  }

  private resendEnabler = () => {
    return this.resendEnabler$.pipe(
      delay(this.resendDelay)
    ).subscribe(_ => this.showResendOption = true);
  }

  fillCodeInput(code: string){
    [...code].forEach((pV, index) => this.code[index] = pV);
  }

  buildAndValidateCode() {
    this.phoneVerification.code = this.code.join('');
    this.validateCode(this.phoneVerification);
  }

  resendVerificationCode = (event) => {
    event.preventDefault();
    this.phoneVerification = this.clientService.getPhoneVerification();
    this.forward(this.phoneVerification);
  }

  private forward(request: PhoneVerificationRequest) {
    this.processing = true;
    this.bookingService.resendVerificationCode(request).subscribe(
      (response) => {
        this.phoneVerification.code = response.code;
        this.clientService.setPhoneVerification(this.phoneVerification);
        this.code.fill('');
        this.fillCodeInput(response.code);
      },
      (e) => {
        switch (e.status) {
          case 404:
            this.redirectToErrorPage(
              this.translate.instant('verification.expiredCodeTitle'),
              this.translate.instant('verification.expiredCodeMessage')
            );
            break;
          case 409:
            this.redirectToErrorPage(
              this.translate.instant('verification.maxAttemptsResend'),
              this.translate.instant('verification.maxAttemptsMessage')
            );
            break;
          default:
            this.router.navigate([Paths.error]);
        }
      },
      () => {
        this.processing = false;
        this.afterForward();
      }
    );
  }

  private afterForward = () => {
    this.showResendOption = false;
    this.resendEnabler$.next('');
    this.fillCodeInput(this.phoneVerification.code);
  }

  private validateCode(request: PhoneVerificationRequest) {
    this.processing = true;
    this.bookingService.validateCode(request).subscribe(response => {
        this.phoneVerification.isVerified = true;
        this.clientService.setPhoneVerification(this.phoneVerification);
        this.reservationService.clearReservation();
        this.router.navigate([Paths.process]);
      },
      e => {
        if (e.status === 409) {
          this.handleConflictError(e.error);
          this.processing = false;
        } else {
          this.redirectToErrorPage(this.translate.instant('verification.genericErrorTitle'),
            this.translate.instant('verification.genericErrorMessage'));
        }
      },
      () => this.processing = false
    )
  }

  private handleConflictError(result: ValidationCodeResult) {
    switch (result) {
      case ValidationCodeResult.EXPIRED_CODE:
        this.redirectToErrorPage(this.translate.instant('verification.expiredCodeTitle'),
        this.translate.instant('verification.expiredCodeMessage'));
        break;
      case ValidationCodeResult.MAX_ATTEMPTS_REACHED:
        this.redirectToErrorPage(this.translate.instant('verification.maxAttemptsTitle'),
        this.translate.instant('verification.maxAttemptsMessage'));
        break;
      case ValidationCodeResult.INVALID_CODE:
        this.validationCodeError = true;
        break;
      default:
        this.router.navigate([Paths.error]);
    }
  }

  private redirectToErrorPage(title: string, message: string) {
    this.router.navigate([Paths.error],
      {
        state: {
          title: title,
          message: message
        }
      });
  }

  get isCodeComplete(): boolean {
    const completeCode = this.code.join('');
    return this.isNumber(completeCode) && completeCode.length === 6;
  }

  autoTab(currentPosition: number, afterDeletePosition: number, afterChangePosition: number) {
    if (this.code.join('').length < 6) {
      let target = this.itemIsEmpty(currentPosition) ? afterDeletePosition : afterChangePosition;
      this.focusItem(target);
    }

    this.resetError();
  }

  resetError() {
    this.validationCodeError = false;
  }

  focusItem(target: number) {
    const element = document.getElementById(`code${target}`);
    if (element)
      element.focus();
  }

  disableNanChars(event: KeyboardEvent) {
    if (!this.isNumber(event.key)) {
      event.preventDefault();
    }
  }

  isNumber(value) {
    return !isNaN(Number(value));
  }

  itemIsEmpty(index: number) {
    return !!!this.code[index]
  }

  fillNextValue(event: KeyboardEvent, currentItem, nextItem, targetAfterChange) {
    const value = this.isNumber(event.key) ? event.key : '';

    if (value.length > 0 && !this.itemIsEmpty(currentItem) && this.itemIsEmpty(nextItem)) {
      this.code[nextItem] = value;
      this.focusItem(targetAfterChange);
    }
  }
}
