<div id="processList" class="process-cont">
  <div class="h-100 w-100 col-12 px-0 process-item" *ngFor="let process of processes"
       (click)="onClickOnRadioContainer(process)">
    <div [attr.id]="'process' + process.id" [ngClass]="isProcessSelected(process) ? 'process-selected' : 'process-deselected'">
      <div class="process flex-grow-1 d-flex align-items-left p-1 text-wrap text-break" [attr.aria-expanded]="isProcessSelected(process)"
           [attr.data-target]="'#collapse' + process.id" [attr.aria-controls]="'collapse' + process.id">
        <h5 class="mb-0 px-4">
          <div class="custom-control custom-radio d-flex justify-content-center align-items-center p-3">
            <input type="radio" name="customRadio" id="process_{{process.id}}" class="custom-control-input"
                   [value]="process" [(ngModel)]="selectedProcess" (ngModelChange)="selectProcess()"/>
            <label class="custom-control-label fixed" for="process_{{process.id}}">{{process.name}}</label>
          </div>
        </h5>
      </div>
      <ng-container *ngIf="processHasRequirements(process)">
        <div [attr.id]="'collapse' + process.id" class="px-3 pb-4"
             [collapse]="!isProcessSelected(process)"
             [isAnimated]="true"
             (expanded)="scrollContent()">
          <ng-container *ngFor="let at of process.attentions">
            <h4 style="text-align: justify" class="px-2 process-requirements" [innerHTML]="at.requirements"></h4>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
