import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { Paths } from '../global';
import { ConfigService } from '../services/config/config.service';

@Injectable()
export class PhoneVerifiedGuard implements CanActivate {

  constructor(private router: Router, private clientService: ClientService, private configService: ConfigService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const client = this.clientService.getClient();
    const phoneVerification = this.clientService.getPhoneVerification();

    const isCodeRequestedAndVerified = phoneVerification && phoneVerification.isVerified;

    if (client
        && ((this.configService.getShouldVerifyPhoneNumber() && isCodeRequestedAndVerified)
        || !this.configService.getShouldVerifyPhoneNumber())) {
      return true;
    }
    return this.router.parseUrl(Paths.home);
  }
}
