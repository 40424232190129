export const DocumentNumberMask = (documentType, documentNumber) => {
  return applyMask[documentType] ? applyMask[documentType](documentNumber) : documentNumber;
};

const applyMask = {
  'RUT': (documentNumber) => applyRutMask(documentNumber)
};

const applyRutMask = (documentNumber: string) => {
  let cleanDN = documentNumber.replace(/\./g, '').replace('-', '');

  if (cleanDN.match(/^(\d{2})(\d{3}){2}(\w)$/)) {
    cleanDN = cleanDN.replace(/^(\d{2})(\d{3})(\d{3})(\w)$/, '$1.$2.$3-$4');
  } else if (cleanDN.match(/^(\d)(\d{3}){2}(\w?)$/)) {
    cleanDN = cleanDN.replace(/^(\d)(\d{3})(\d{3})(\w?)$/, '$1.$2.$3-$4');
  } else if (cleanDN.match(/^(\d)(\d{3})(\d{0,2})$/)) {
    cleanDN = cleanDN.replace(/^(\d)(\d{3})(\d{0,2})$/, '$1.$2.$3');
  } else if (cleanDN.match(/^(\d)(\d{0,2})$/)) {
    cleanDN = cleanDN.replace(/^(\d)(\d{0,2})$/, '$1.$2');
  }
  return cleanDN;
};
