import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../../../global';
import { ErrorPageExitButtonBehaviour } from './error-page-exit-button-behaviour';

@Injectable()
export class DefaultErrorPageExitButtonBehaviour extends ErrorPageExitButtonBehaviour {

  constructor(private router: Router) {
    super();
  }

  exitButton(): void {
    this.router.navigate([Paths.home]);
  }
}
