import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

import { BookingsCaptchaService } from "src/app/services/captcha/BookingsCaptchaService";
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private captchaService: BookingsCaptchaService) {}

  /**
   * Intercepts an outgoing HTTP request and adds a reCAPTCHA v3 token header, if available.
   * @param request - The outgoing HTTP request.
   * @param next - The HttpHandler instance to handle the request.
   * @returns An observable of the HTTP response.
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (environment.recaptchaV3SiteKey) {
      const token = this.captchaService.getDecodedToken();
      if (token) {
        const authReq = request.clone({
          setHeaders: { "X-RecaptchaV3-Token": token },
        });
        return next.handle(authReq);
      }
    }
    return next.handle(request);
  }
}
