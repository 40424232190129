import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigLoaderService } from '../../services/config/ConfigLoaderService';
import { Paths } from '../../global';
import { ConfigService } from '../../services/config/config.service';
import { IllegalComponentBehaviourNotifier } from '../../utils/illegal-component-behaviour-notifier.service';
import { ExternalClientService } from '../../services/externalClient/external-client.service';
import { ExternalClientBookingValidationRequest } from '../../services/externalClient/http/ExternalClientBookingValidationRequest';
import { LoginResponse } from '../../model/client/loginResponse.model';
import { ClientWithBookingResolverService } from '../../utils/client-with-booking/client-with-booking-resolver.service';
import { ExternalClientSessionValuesDTO } from '../../services/externalClient/model/ExternalClientSessionValuesDTO';

@Component({
  selector: 'app-external-client',
  templateUrl: './external-client.component.html'
})
export class ExternalClientComponent implements OnInit {

  constructor(private loadConfigService: ConfigLoaderService,
              private externalClientService: ExternalClientService,
              private clientWithBookingResolver: ClientWithBookingResolverService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private configService: ConfigService,
              private illegalComponentBehaviourNotifier: IllegalComponentBehaviourNotifier) {
  }

  ngOnInit(): void {
    this.loadConfigService
      .loadConfiguration(
        this.validateExternalClientIntegrationFlagAndIdentifier,
        this.goToErrorPageHidingExitButton);
  }

  private validateExternalClientIntegrationFlagAndIdentifier = (): void => {
    const externalClientIntegrationEnabled = this.configService.getExternalClientIntegrationEnabled();
    if (externalClientIntegrationEnabled) {
      this.validateExternalClientIdentifier();
    } else {
      this.notifyIllegalComponentAccess('feature is disabled');
      this.goToErrorPage();
    }
  }

  private validateExternalClientIdentifier() {
    const identifier = this.extractIdentifierFromUrl();
    if (identifier) {
      this.validateExternalClientBooking(identifier);
    } else {
      this.notifyIllegalComponentAccess('identifier param not provided');
      this.goToErrorPageHidingExitButton();
    }
  }

  private extractIdentifierFromUrl(): string {
    return this.activatedRoute.snapshot.paramMap.get('identifier');
  }

  private validateExternalClientBooking(identifier: string) {
    const request = new ExternalClientBookingValidationRequest(identifier);
    this.externalClientService
      .validateExternalClientBooking(request)
      .subscribe(
        response => this.processExternalClientBookingValidation(response),
        this.goToErrorPageHidingExitButton
      );
  }

  private processExternalClientBookingValidation = (response: LoginResponse): void => {
    const identifier = this.extractIdentifierFromUrl();
    const dto = new ExternalClientSessionValuesDTO();
    dto.identifier = identifier;
    dto.loginResponse = response;
    this.externalClientService.saveSessionValues(dto);
    if (!response.hasBooking) {
      this.goToProcessSelectionPage();
    } else {
      this.clientWithBookingResolver.resolve(response);
    }
  }

  private goToProcessSelectionPage = () => {
    this.router.navigate([Paths.process], { replaceUrl: true });
  }
  private goToErrorPage = () => {
    this.router.navigate([Paths.error]);
  }

  private goToErrorPageHidingExitButton = () => {
    this.router.navigate([Paths.error], {
      state: {
        explicitlyHideExitButton: true
      }
    });
  }

  private notifyIllegalComponentAccess = (cause: string) => {
    this.illegalComponentBehaviourNotifier
      .notifyIllegalComponentAccess('ExternalClientComponent', cause);
  }
}
