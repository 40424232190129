<div class="bg-main bg-gradient d-flex flex-column">
  <app-header [showGoBack]="false" [showLogout]="false"></app-header>
  <div class="container-xl">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
        <span class="icon icon-64 icon-white icon-schedule_vencida align-items-center"></span>
        <h1 class="mb-4 mt-4 text-white text-center" 
        [innerHtml]="canceled ? ('autoConfirmation.canceledTitle' | translate)  : ('autoConfirmation.expiredTitle' | translate)"></h1>
      </div>
    </div>
  </div>
</div>
<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column py-2 shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 px-4">
          <div class="bg-white rounded-box shadow-custom overflow-hidden p-4 mt-4">
            <div class="d-flex flex-column">
              <div class="mt-2 d-flex flex-column justify-content-center">
                <div class="my-2">
                  <p class="text-main text-center text-light-16 reminder-subtitle" [innerHtml]="('autoConfirmation.expiredBookingMessage' | translate)"></p>
                </div>
                <div class="bg-grey rounded-info-box flex-column h-auto py-3 mt-2 mx-3">
                  <div id="dateContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-4 ml-4 justify-content-end p-0">
                        <span class="icon icon-lg icon-schedule-2 d-flex align-items-start mt-2 ml-1 mr-2 w-auto"></span>
                        <p class="text-light-16 text-right mr-2 ml-2 mt-2 text-grey">
                          {{'confirmation.dateLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 col-sm-8 p-0">
                        <p class="ml-3 mt-2">
                          {{ date | longDate}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="timeContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-4 ml-4 justify-content-end p-0">
                        <span class="icon icon-lg icon-clock-nine-2 d-flex align-items-start mt-2 mr-2 w-auto"></span>
                        <p class="text-light-16 mr-2 mt-2 text-grey">
                          {{'confirmation.timeLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 col-sm-8 p-0">
                        <p class="ml-3 mt-2">
                          {{ date | shortTime }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-4 ml-4 justify-content-end p-0">
                        <span class="icon icon-lg icon-clipboard-notes-2 d-flex align-items-start mt-2 mr-2 w-auto"></span>
                        <p class="text-light-16 mr-2 mt-2 ml-2 text-grey">
                          {{'confirmation.reasonLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 col-sm-8 p-0">
                        <p class="ml-3 mt-2">
                         {{ reminderInformation.processName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mt-2 mb-4">
                  <div class="text-center my-4 text-light-16">
                    <p>
                      {{'autoConfirmation.newBookingMessage' | translate}}
                    </p>
                  </div>
                  <div class="col-11 col-md-8">
                    <button (click)="homePageRedirection()" id="registerBtn" class="btn btn-block btn-main py-2">
                      {{'autoConfirmation.newBookingGeneration' | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
