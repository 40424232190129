import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { Reservation } from '../../model/reservation/reservation.model';
import { ReservationService } from '../../services/reservation/reservation.service';
import { BookingsService } from '../../services/bookings/bookings.service';
import { Process } from '../../model/process/process.model';
import { ClientService } from '../../services/client/client.service';
import { customizations } from '../../../web-customizations/customizations';
import { ConfigService } from '../../services/config/config.service';
import { finalize } from 'rxjs/operators';
import { ProcessGroup } from '../../model/process/process-group.model';
import { ExternalClientService } from '../../services/externalClient/external-client.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html'
})
export class ProcessComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly showSingleList: boolean;
  readonly showWhenSingleProcess: boolean;

  private reservation: Reservation;
  processes: Process[];
  processGroups: ProcessGroup[];
  processing = true;
  enableBackButton: boolean;
  selectedProcess: Process;

  constructor(private router: Router,
              private reservationService: ReservationService,
              private bookingsService: BookingsService,
              private clientService: ClientService,
              private configService: ConfigService,
              private externalClientService: ExternalClientService) {

    this.showSingleList = customizations.process.showSingleList;
    this.showWhenSingleProcess = customizations.process.showWhenSingleProcess;
  }

  ngOnInit(): void {
    this.loadAvailableProcesses();
    this.enableBackButton = this.shouldShowGoBackButton();
  }

  loadAvailableProcesses() {
    this.showLoading();
    this.bookingsService
      .loadProcesses()
      .pipe(finalize(this.hideLoading))
      .subscribe(response => {
          const processes: Process[] = response?.processes;
          const processGroups: ProcessGroup[] = response?.processesGroupers;
          this.resolveProcessInfo(processes, processGroups);
        },
        this.goToErrorPage);
  }

  confirmSelection() {
    this.updateReservationProcess(this.selectedProcess);
    this.goToAttentionTypePage(false);
  }

  shouldShowSingleList() {
    return this.showSingleList || this.hasProcessWithoutGroup();
  }

  hasProcessWithoutGroup() {
    const noProcessGroups = (this.processGroups || []).length == 0;
    const processesWithoutGroup = this.processes ? this.processes.filter(p => p.groupId == null) : [];

    return noProcessGroups || processesWithoutGroup.length > 0
  }

  selectProcess = (process) => {
    this.selectedProcess = process;
  }

  shouldShowGoBackButton() {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      return false;
    }
    return !this.configService.getShouldVerifyPhoneNumber();
  }

  private resolveProcessInfo(processes: Process[], processesGroups: ProcessGroup[]) {
    if (!!!processes || processes.length === 0) {
      this.goToErrorPage();
    } else {
      if (this.shouldShowProcessPage(processes)) {
        this.processes = processes;
        this.processGroups = processesGroups;

        this.reservationService.showProcessInformation();
      } else {
        this.reservationService.hideProcessInformation();
        const process = processes[0];
        this.updateReservationProcess(process);
        this.goToAttentionTypePage(true);
      }
    }
  }

  private shouldShowProcessPage(processes: Process[]) {
    return processes?.length > 1 || this.showWhenSingleProcess;
  }

  private updateReservationProcess(process: Process) {
    const newReservation: Reservation = new Reservation();
    newReservation.process = process;
    newReservation.client = this.clientService.getClient();

    this.reservation = newReservation;
    this.reservationService.setReservation(this.reservation);
  }

  private showLoading = () => {
    this.processing = true;
  }

  private hideLoading = () => {
    this.processing = false;
  }

  private goToErrorPage = () => {
    this.router.navigate([Paths.error]);
  }

  private goToAttentionTypePage = (removeThisPageFromNavigation: boolean) => {
    this.router.navigate([Paths.attentionType], {replaceUrl: removeThisPageFromNavigation});
  }
}
