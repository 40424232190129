<div class="row justify-content-end mt-3">
  <div
    class="col-12 flex-grow-1 d-none d-lg-flex flex-row-reverse justify-content-start align-items-center mb-3 btn-cont">
    <button *ngIf="this.showFinalizeButton()"
            class="btn btn-main btn-xl justify-content-center d-flex align-items-center col-5">
      Finalizar
    </button>
    <button
      [ngClass]="this.showFinalizeButton() ? 'btn-link' : 'btn-main'"
      class="btn btn-xl justify-content-center d-flex align-items-center col-5"
    (click)="this.addToCalendar()">
      <span
        [ngClass]="{'text-white':!this.showFinalizeButton()}"
        class="icon icon-lg icon-save-on-calendar d-flex align-self-center"></span>
      Agregar a tu calendario
    </button>
  </div>
</div>
