<app-modal #modal [modalId]="modalId">
  <div class="modal-body d-flex flex-column scrolleable-modal">
    <div class="flex-grow-1 position-relative overflow-auto pt-3 custom-scroll">
      <ng-container *ngIf="hasBullets()">
        <div class="d-flex align-items-start mx-3 mb-3" *ngFor="let bullet of bullets">
          <span class="far fa-dot-circle text-sec mt-1 mr-2"></span>
          <h4 class="text-left text-main">
            {{bullet.mainText}}
            <ng-container *ngIf="bullet.details?.length > 0">
              <br>
            </ng-container>
            <ng-container *ngFor="let bulletDetail of bullet.details">
              {{bulletDetail}}
              <br>
            </ng-container>
          </h4>
        </div>
      </ng-container>
      <div *ngIf="hasHtml()" [outerHTML]="html"></div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-10 col-lg-8">
        <button id="acceptBtn" class="btn btn-block btn-main py-2 mb-2" data-dismiss="modal" (click)="modal.hide()">
          Continuar
        </button>
      </div>
    </div>
  </div>
</app-modal>
