import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IllegalComponentBehaviourNotifier {

  constructor(private errorHandler: ErrorHandler) {
  }

  notifyIllegalComponentAccess(componentName: string,
                               cause: string) {
    this.notify('Illegal access', componentName, cause);
  }

  notifyIllegalGuardAccess(guardName: string,
                           originPage: string,
                           cause: string) {
    this.notifyAnyError(`Illegal guard access in ${guardName} from ${originPage}. Cause: ${cause}`);
  }

  notify(title: string,
         componentName: string,
         cause: string) {
    this.notifyAnyError(`${title} in ${componentName}. Cause: ${cause}`);
  }

  notifyAnyError(error: any): void {
    this.errorHandler.handleError(error);
  }
}
