export class ExternalClientBookingCancellationByCodeRequest {

  identifier: string;
  code: string;

  constructor(identifier: string, code: string) {
    this.identifier = identifier;
    this.code = code;
  }
}
