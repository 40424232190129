import { Component, HostBinding, ViewChild } from '@angular/core';
import { ModalCancelBookingComponent } from 'src/app/components/modal-cancel-booking/modal-cancel-booking.component';
import { Router } from '@angular/router';
import { Paths } from 'src/app/global';

@Component({
  selector: 'app-reminder-active-booking-success',
  templateUrl: './reminder-active-booking-success.component.html'
})
export class ReminderActiveBookingSuccessComponent {
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';
  @ViewChild(ModalCancelBookingComponent)
  cancelModal: ModalCancelBookingComponent;

  constructor(
    private router: Router,
  ) {}

  homePageRedirection() {
    this.router.navigate([Paths.home]);
  }
}
