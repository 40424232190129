<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="bg-main bg-gradient d-flex flex-column">
    <app-header [showGoBack]="true"></app-header>
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
          <h1 class="mb-2 text-white text-center">
            {{ 'cancellationByCode.title' | translate }}
          </h1>
          <p class="mt-2 mb-1 text-white text-center mb-2">
            {{ 'cancellationByCode.subtitle' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <app-cancel-booking-codebox></app-cancel-booking-codebox>
        </div>
      </div>
    </div>
  </div>
</section>
