import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Process } from '../../model/process/process.model';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html'
})
export class ProcessListComponent {
  @Input() processes: Process[];
  @Output() onSelect = new EventEmitter<Process>();

  selectedProcess: Process;

  onClickOnRadioContainer(process: Process) {
    this.selectedProcess = process;
    this.selectProcess();
  }

  selectProcess() {
    this.onSelect.emit(this.selectedProcess);
  }

  clearProcessSelection() {
    this.selectedProcess = null;
  }

  isProcessSelected(process) {
    return this.selectedProcess == process;
  }

  scrollContent() {
    if (this.selectedProcess) {
      const details = document.getElementById('process' + this.selectedProcess.id)
      details.scrollIntoView({behavior:"smooth", block: 'start'})
    }
  }

  processHasRequirements(process: Process): boolean {
    return process.attentions
      .filter(at => !!at.requirements)
      .length > 0;
  }
}
