<div class="bg-main bg-gradient d-flex flex-column">
  <app-header [showGoBack]="false" [showLogout]="false"></app-header>
  <div class="container-xl">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
        <span class="icon icon-64 icon-white icon-check align-items-center"></span>
        <h1 class="mb-4 mt-4 text-white text-center">
          {{'autoConfirmation.successTitle' | translate}}
        </h1>
      </div>
    </div>
  </div>
</div>
<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column py-2 shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 px-4">
          <div class="bg-white rounded-box shadow-custom overflow-hidden p-4 mt-4">
            <div class="d-flex flex-column">
              <div class="mt-2 d-flex flex-column justify-content-center">
                <div class="my-2">
                  <p class="text-light-16 text-main text-center reminder-subtitle">
                    {{ 'autoConfirmation.successInfo' | translate }}
                  </p>
                </div>
                <div class="flex-column w-auto h-auto">
                  <div id="dateContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span class="icon icon-lg icon-schedule-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                          {{'confirmation.dateLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2 booking-date">
                          {{ date | longDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="timeContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span class="icon icon-lg icon-clock-nine-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                          {{'confirmation.timeLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2">
                          {{ date | shortTime }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="processContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <ng-container *ngIf="attentionTypeIsVirtual(); else attentionTypeIsFaceToFace">
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span class="icon icon-lg icon-schedule-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                            {{'confirmation.attentionTypeLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2 booking-date">
                            {{ 'confirmation.attentionTypeValue' | translate }} <br>
                          </p>
                        </div>
                      </ng-container>
                      <ng-template #attentionTypeIsFaceToFace>
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span class="icon icon-lg icon-clock-nine-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                            {{'confirmation.customerCenterLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2">
                            {{ reminderInformation.customerCenterName }} <br>
                            <small>{{ reminderInformation.customerCenterAddress }}</small>
                          </p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center bd-highlight my-1">
                  <ng-container *ngIf="attentionTypeIsVirtual(); else faceToFaceReminder">
                    <div class="flex-grow-1 d-flex py-2 justify-content-center">
                      <div class="col-12 col-sm-10 my-1 px-lg-5 ml-lg-2 d-flex flex-grow-1 justify-content-center">
                        <div class="color-dot mr-3 mt-2"></div>
                        <div class="d-flex w-auto text-left">
                          <p class="text-light-16" [innerHTML]="('success.virtualReminder') | translate"></p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #faceToFaceReminder>
                    <div class="d-flex col-12 col-sm-6 p-4">
                      <div class="color-dot mr-2 mt-2"></div>
                      <div class="flex-grow-1">
                        <p class="text-center" [innerHtml]="('autoConfirmation.reminder' | translate)"></p>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <app-modal-cancel-booking [encryptedId]="encryptedId"></app-modal-cancel-booking>
            <div class="row justify-content-center">
              <div class="col-12 col-sm-10 col-md-8 px-4">
                <button (click)="homePageRedirection()" id="registerBtn" class="btn btn-block btn-main py-2">
                  {{ 'autoConfirmation.backToHome' | translate }}
                </button>
              </div>
              <div class="mt-3 col-12 col-sm-10 col-md-8 text-center text-black-50">
                <button (click)="showCancelModal()" class="btn btn-link btn-xl px-4" data-toggle="modal" data-target="#modalcanceldate">
                  {{ 'autoConfirmation.bookingCancelation' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
