import { Component, HostBinding, OnInit, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { customizations } from '../../../web-customizations/customizations';
import { BookingsService } from '../../services/bookings/bookings.service';
import { ReservationService } from '../../services/reservation/reservation.service';
import { Reservation } from '../../model/reservation/reservation.model';
import { ClientService } from '../../services/client/client.service';
import { NewBookingRequest } from '../../services/bookings/http/NewBookingRequest';
import { RescheduleBookingRequest } from '../../services/bookings/http/RescheduleBookingRequest';
import { TranslateService } from '@ngx-translate/core';
import { hidePhoneNumber, isVirtualBooking } from 'src/app/utils/Utils';
import { NotificationChannel } from '../../model/config/notificationChannel.model';
import { ConfigService } from '../../services/config/config.service';
import { getConcatenatedWithOr } from 'src/app/utils/joiners/MessageJoiner';
import { ModalRetryScheduleComponent } from 'src/app/components/modal-retry-schedule/modal-retry-schedule.component';
import { finalize } from 'rxjs/operators';
import { HttpStatusCode } from 'src/app/utils/HttpStatusCode';
import { NewExternalClientBookingRequest } from '../../services/bookings/http/NewExternalClientBookingRequest';
import { ExternalClientService } from '../../services/externalClient/external-client.service';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';
import { GTMEventPublisher } from 'src/app/services/gtm/gtm-event-publisher';
import { GTMBookingEventRequest } from 'src/app/services/gtm/gtm-booking-event-request';
import { BookingsCaptchaService } from 'src/app/services/captcha/BookingsCaptchaService';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html'
})
export class BookingConfirmationComponent implements OnInit {
  @ViewChild(ModalRetryScheduleComponent) retrySchedule: ModalRetryScheduleComponent;
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';
  reservation: Reservation;

  readonly showLowerInfo = customizations.confirmation.showLowerInfo;
  private readonly includeZoneInAddress = customizations.confirmation.includeZoneInAddress;
  processing = false;
  messageSentFailed = false;
  isVirtualBooking: boolean;
  showProcessInformation: boolean;
  notificationChannels: NotificationChannel[];
  confirmationInfo = '';

  constructor(private router: Router,
              private bookingsService: BookingsService,
              private bookingsCaptchaService: BookingsCaptchaService,
              private clientService: ClientService,
              private reservationService: ReservationService,
              private translate: TranslateService,
              private configService: ConfigService,
              private externalClientService: ExternalClientService,
              @Optional() private gtmPublisher: GTMEventPublisher) {
  }

  ngOnInit(): void {
    this.loadNotificationChannels();
    this.resolveInfoMessage();
    this.reservation = this.reservationService.getReservation();
    this.isVirtualBooking = isVirtualBooking(this.reservation.selectedAttention?.attentionType);
    this.showProcessInformation = this.reservationService.getProcessInformation();
  }

  confirm = ($event?) => {
    if ($event) {
      $event.preventDefault();
    }
    const gtmBookingEventRequest = new GTMBookingEventRequest();
    gtmBookingEventRequest.booking = this.reservation;
    gtmBookingEventRequest.client = this.clientService.getNewClient();

    this.bookingsCaptchaService.execute(
      'booking_confirmation',
      () => this.handleConfirmation(gtmBookingEventRequest),
      () => {
        this.publishBookingErrorGTMEvent('401', 'Error de autenticación', gtmBookingEventRequest);
        this.redirectToErrorPage();
      }
    );
  }

  private redirectToErrorPage = () => {
    this.reservationService.clearReservation();
    this.router.navigate([Paths.error],
      { state: { message: this.translate.instant('confirmation.errors.saveBooking') } });
  }

  private handleConfirmation(gtmBookingEventRequest: GTMBookingEventRequest) {
    this.processing = true;
    const editOrSave = this.reservationService.isEditing()
      ? () => this.rescheduleBooking()
      : () => this.registerBooking();

    editOrSave()
      .pipe(finalize(() => this.processing = false))
      .subscribe(b => {
        gtmBookingEventRequest.bookingResult = {
          successful: true
        };

        this.reservationService.clearEditing();
        this.reservation.currentlyConfirmed = true;
        this.reservation.id = b.id;
        this.reservationService.setReservation(this.reservation);
        this.gtmPublisher?.publishBookingEvent(gtmBookingEventRequest);
        this.router.navigate([Paths.bookingRoot]);
      }, e => {
        switch (e.status) {
          case HttpStatusCode.GATEWAY_TIMEOUT: // Notification timeout
            this.publishBookingErrorGTMEvent('504', 'Error de timeout al notificar reserva', gtmBookingEventRequest);
            this.handleGatewayTimeout();
            break;
          case HttpStatusCode.PRECONDITION_FAILED: // No slot available
            this.publishBookingErrorGTMEvent('412', 'El slot seleccionado fue ocupado', gtmBookingEventRequest);
            this.retrySchedule.show();
            break;
          default:
            this.publishBookingErrorGTMEvent('500', 'Error interno del servidor', gtmBookingEventRequest);
            this.redirectToErrorPage();
            break;
        }
      });
  }

  private publishBookingErrorGTMEvent(code: string,
                                      message: string,
                                      event: GTMBookingEventRequest) {
    event.bookingResult = {
      successful: false,
      errorCode: code,
      errorMessage: message
    };
    this.gtmPublisher?.publishBookingEvent(event);
  }

  private handleGatewayTimeout() {
    if (this.messageSentFailed) {
      this.redirectToErrorPage();
    }

    this.messageSentFailed = true;
  }

  private registerBooking() {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      const newBooking: NewExternalClientBookingRequest = {
        identifier: this.clientService.getExternalClientIdentifier(),
        departmentId: this.clientService.getNewClient().departmentId,
        customerCenterId: this.reservation.customerCenter.id,
        bookingDate: this.reservation.reservationDate,
        attentionId: this.reservation.selectedAttention.id
      };
      return this.bookingsService.registerExternalClientBooking(newBooking);
    } else {
      const newClient = this.clientService.getNewClient();

      const newBooking: NewBookingRequest = {
        client: newClient || null,
        customerCenterId: this.reservation.customerCenter.id,
        bookingDate: this.reservation.reservationDate,
        attentionId: this.reservation.selectedAttention?.id
      };

      return this.bookingsService.registerBooking(newBooking);
    }
  }

  private rescheduleBooking() {
    const rescheduleRequest: RescheduleBookingRequest = {
      customerCenterId: this.reservation.customerCenter.id,
      bookingDate: this.reservation.reservationDate
    };

    return this.bookingsService.rescheduleBooking(this.clientService.getClient(), rescheduleRequest);
  }

  hiddenPhoneNumber() {
    return hidePhoneNumber(this.clientService.getNewClient().phoneNumber);
  }

  private loadNotificationChannels() {
    this.notificationChannels = this.configService.getRegistrationNotificationChannels();
  }

  private resolveInfoMessage() {
    if (this.notificationChannels?.length > 0) {
      const channelParam = {
        channels: getConcatenatedWithOr(this.notificationChannels)
      };
      this.translate.get('confirmation.info', channelParam).subscribe(
        msg => this.confirmationInfo = msg);
    }
  }

  shouldShowConfirmationInfo() {
    return this.showLowerInfo && this.notificationChannels?.length > 0;
  }

  shouldShowPhoneNumber(): boolean {
    const newClient = this.clientService.getNewClient();
    return !!newClient && !!newClient.phoneNumber;
  }

  buildCustomerCenterAddress(customerCenter: CustomerCenter): string {
    return CustomerCenter.buildFullAddress(customerCenter, this.includeZoneInAddress);
  }
}
