import { URLS } from './urls';
import { version } from '../../package.json';

const environmentName = 'cpe';
const backURL = 'https://api.qa-reservas.claro.turnosaloha.com/api';
const frontURL = {
  domain: 'qa-reservas.claro.turnosaloha.com/home',
  subdomains: []
};

export const environment = {
  appVersion: `${version}-${environmentName}`,
  environmentName,
  clientName: 'Claro',
  production: true,
  country: 'PE',
  locale: 'es-AR',
  timeFormat: 'AM/PM',
  applicationTitle: 'Agendamientos Claro',
  backURL,
  frontURL,
  services: URLS(backURL),
  hotjarid: null,
  gtmId: null,
  newrelic: false,
  recaptchaV3SiteKey: null,
  facebookDomainCode: null,
  defaultOrigin: null
};
