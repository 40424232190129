import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReservationService } from '../services/reservation/reservation.service';
import { Paths } from '../global';

@Injectable()
export class AttentionTypeSelectedGuard implements CanActivate {

  constructor(private router: Router,
              private reservationService: ReservationService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const booking = this.reservationService.getReservation();

    if (booking.selectedAttention?.attentionType != null && booking.selectedAttention?.id != null) {
      return true;
    }

    return this.router.parseUrl(Paths.home);
  }
}
