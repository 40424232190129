import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../date-utils.service';

@Pipe({
  name: 'shortTime'
})
export class ShortTimePipe implements PipeTransform {
  // en-US is used to show "AM" and "PM" instead of "a.m." and "p.m."
  readonly locale = 'en-US';

  constructor(private dateUtils: DateUtils) {
  }

  // Output "16:55 hs" OR "04:55 PM" depending on the environment configuration
  transform(date: Date): string {
    const timeFormat: string = environment.timeFormat;
    return this.getDateAsLongString(date, timeFormat);
  }

  private getDateAsLongString(date: Date, timeFormat): string {
    const zonedDate = this.dateUtils.toZonedDate(date);
    switch (timeFormat) {
      case '24H': {
        return `${zonedDate.toLocaleString(this.locale, { hour12: false, hour: '2-digit', minute: '2-digit' })} hs`;
      }
      case 'AM/PM':
      default: {
        return zonedDate.toLocaleString(this.locale, { hour12: true, hour: '2-digit', minute: '2-digit' });
      }
    }
  }
}
