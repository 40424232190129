import { Component, HostBinding, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GTMEventPublisher } from 'src/app/services/gtm/gtm-event-publisher';
import { customizations } from '../../../web-customizations/customizations';
import { Paths } from '../../global';
import { Client } from '../../model/client/client.model';
import { DocumentType } from '../../model/client/documenttype.model';
import { LoginResponse } from '../../model/client/loginResponse.model';
import { BookingsService } from '../../services/bookings/bookings.service';
import { ValidateClientAndBookingRequest } from '../../services/bookings/http/ValidateClientAndBookingRequest';
import { BookingsCaptchaService } from '../../services/captcha/BookingsCaptchaService';
import { ClientService } from '../../services/client/client.service';
import { ConfigLoaderService } from '../../services/config/ConfigLoaderService';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { ClientWithBookingResolverService } from '../../utils/client-with-booking/client-with-booking-resolver.service';
import { DocumentNumberMask } from '../../utils/masks/DocumentNumberMask';
import { DocumentNumberExample } from '../../utils/phoneExamples/DocumentNumberExample';
import { TermsAndConditionsService } from '../../utils/terms-and-conditions.service';
import { DocumentValidators } from '../../utils/validators/DocumentValidators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly shouldShowBusinessHeader = customizations.home.showBusinessHeader;

  documentNumber: string;
  validDocumentTypes: DocumentType[] = [];

  selectedDocumentType: DocumentType = null;
  documentHasError = false;
  processing = false;
  loadTermsAndConditions = false;

  constructor(
    private router: Router,
    private bookingsService: BookingsService,
    private clientService: ClientService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private loadConfigService: ConfigLoaderService,
    private termsAndConditionsService: TermsAndConditionsService,
    private bookingsCaptchaService: BookingsCaptchaService,
    private clientWithBookingResolver: ClientWithBookingResolverService,
    @Optional() private gtmEventPublisher: GTMEventPublisher
  ) {
  }

  ngOnInit(): void {
    this.sessionStorageService.clearSessionStorage();
    this.loadConfig();
  }

  validateDocumentFormat() {
    this.documentNumber = DocumentNumberMask(this.selectedDocumentType?.enumName, this.documentNumber);
    this.documentHasError = this.documentNumber && this.selectedDocumentType?.enumName &&
      !DocumentValidators(this.selectedDocumentType?.enumName)(this.documentNumber, this.selectedDocumentType.pattern);
  }

  getDocNumberPlaceholder() {
    const defaultValue = this.translate.instant('home.docNumberPlaceholder');
    return this.selectedDocumentType ? DocumentNumberExample(this.selectedDocumentType?.enumName, defaultValue) : defaultValue;
  }

  clearDocumentNumber() {
    this.documentNumber = '';
    this.documentHasError = false;
  }

  isLegalPerson(): boolean {
    return this.selectedDocumentType?.enumName === 'CUIT';
  }

  loginOnClick() {
    this.processing = true;
    this.bookingsCaptchaService
      .execute('Login',
        this.validateClient,
        this.goToError);
  }

  private loadConfig() {
    this.processing = true;
    this.loadConfigService
      .loadConfiguration(
        (response) => {
          this.validDocumentTypes = response.availableDocumentTypes;
          this.selectedDocumentType = this.validDocumentTypes[0];
          this.gtmEventPublisher?.publishHomePageLandingEvent();
        },
        this.goToError,
        () => {
          this.processing = false;
        }
      );
  }

  private validateClient = () => {
    const request = new ValidateClientAndBookingRequest(this.selectedDocumentType.enumName, this.documentNumber.toUpperCase());
    this.bookingsService
      .validateClientAndBooking(request)
      .subscribe(
        response => {
          const client = this.buildClient(response);
          this.clientService.setClient(client);
          this.processing = false;
          if (response.hasBooking) {
            this.clientWithBookingResolver.resolve(response);
          } else {
            this.loadTermsAndConditions = this.termsAndConditionsService.shouldLoadTermsAndConditions();
            if (this.loadTermsAndConditions) {
              this.router.navigate([Paths.termsAndConditions]);
            } else {
              this.router.navigate([Paths.registration]);
            }
          }
        },
        error => {
          this.processing = false;
          if (error.status === 400) {
            this.documentHasError = true;
          } else {
            this.goToError();
          }
        });
  }

  private buildClient(response: LoginResponse) {
    const client = new Client();
    client.documentNumber = this.documentNumber.toUpperCase();
    client.documentType = this.selectedDocumentType;
    client.phoneNumber = response.phoneNumber;
    client.email = response.email;
    client.hasActiveBooking = response.hasBooking;
    client.activeBookingAttentionType = response.bookingAttentionType;
    return client;
  }

  private goToError = () => {
    this.router.navigate([Paths.error]);
  }
}
