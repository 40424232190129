export class BookingCancellationByCodeRequest {

  docType: string;
  docNumber: string;
  code: string;

  constructor(docType: string, docNumber: string, code: string) {
    this.docType = docType;
    this.docNumber = docNumber;
    this.code = code;
  }
}
