import { customizations } from '../../../web-customizations/customizations';
import { environment } from '../../../environments/environment';

export const DocumentNumberExample = (documentType, defaultValue) => {
  const country = environment.country;

  if (customizations.home.showDocumentPlaceholderExample) {
    if (documentExamples[country] && documentExamples[country][documentType]) {
      return documentExamples[country][documentType];
    }
  }

  return defaultValue;
}

const documentExamples = {
  'CL': {
    'RUT': '12.345.678-9',
    'PASAPORTE': 'A123456'
  }
}
