import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'stringToDate'
})
export class StringToDatePipe implements PipeTransform {
  transform(stringDate: string): Date {
    return new Date(stringDate);
  }
}
