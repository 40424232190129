<footer *ngIf="showFooter()">
  <div id="page-footer" class="container-fluid">
    <div class="row h-100">
      <div class="col">
        <div class="d-flex h-100 justify-content-center align-items-center text-center">
          <span>© {{ getYear(currentDate) }} - Powered by </span>
          <p class="branch-name">ESolutions Aloha</p>
        </div>
      </div>
    </div>
  </div>
</footer>

