export class GTMConfig {
  private readonly GTM_PREFIX = 'GTM-';
  readonly id: string;

  constructor(id: string) {
    this.id = id;
  }

  isEnabled(): boolean {
    if (this.id) {
      return this.id.startsWith(this.GTM_PREFIX);
    }
    return false;
  }
}
