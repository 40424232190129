import { Component, HostBinding, OnInit } from '@angular/core';
import { BookingsService } from '../../services/bookings/bookings.service';
import { ReservationService } from '../../services/reservation/reservation.service';
import { Reservation } from '../../model/reservation/reservation.model';
import { finalize } from 'rxjs/operators';
import { DepartmentModel } from '../../model/client/DepartmentModel';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { ClientService } from '../../services/client/client.service';

@Component({
  selector: 'app-client-location',
  templateUrl: './client-department.component.html'
})
export class ClientDepartmentComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  booking: Reservation;
  activeDepartments: DepartmentModel[] = [];
  loading = true;
  selectedDepartment: DepartmentModel;

  constructor(private bookingsService: BookingsService,
              private clientService: ClientService,
              private reservationService: ReservationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.showLoading();
    this.booking = this.reservationService.getReservation();
    this.loadActiveDepartments();
  }

  processName() {
    return this.booking?.process?.name;
  }

  isDepartmentSelected(): boolean {
    return !!this.selectedDepartment;
  }

  selectDepartment(departmentSelected: DepartmentModel) {
    this.selectedDepartment = departmentSelected;
  }

  departmentIdFn = (department: DepartmentModel) => {
    return department.id;
  }

  departmentDescriptionFn = (department: DepartmentModel) => {
    return department.name;
  }

  confirmDepartmentSelected() {
    this.updateClientDepartment();
    this.router.navigate([Paths.nearbyCustomerCenter]);
  }

  private loadActiveDepartments(): void {
    this.bookingsService
      .findActiveDepartments()
      .pipe(finalize(this.hideLoading))
      .subscribe(
        response => {
          if (response.departments && response.departments.length !== 0) {
            this.activeDepartments = response.departments;
          } else {
            this.goToError();
          }
        },
        this.goToError
      );
  }

  private showLoading = () => {
    this.loading = true;
  }

  private hideLoading = () => {
    this.loading = false;
  }

  private goToError = () => {
    this.router.navigate([Paths.error]);
  }

  private updateClientDepartment() {
    const newClient = this.clientService.getNewClient();
    newClient.departmentId = this.selectedDepartment.id;
    newClient.departmentName = this.selectedDepartment.name;
    this.clientService.setNewClient(newClient);
  }
}
