import { Pipe, PipeTransform } from '@angular/core';
import { DepartmentModel } from '../../model/client/DepartmentModel';

@Pipe({
  name: 'orderDepartmentByName'
})
export class OrderDepartmentByNamePipe implements PipeTransform {

  transform(departments: DepartmentModel[]): DepartmentModel[] {
    return departments.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return (a.name > b.name) ? 1 : 0;
    });
  }
}
