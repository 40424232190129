import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config/config.service';
import { Paths } from '../global';
import { ReservationService } from '../services/reservation/reservation.service';
import { isVirtualBooking } from '../utils/Utils';

/**
 * Validar que pueda navegarse a la pantalla de seleccion de departamento (Suponiendo que la aplicación redirecciona de
 * forma incorrecta a la pantalla, o que un cliente conoce la URL e intenta navegar a la misma de forma manual).
 * <p>
 * Si la funcionalidad no esta activa, no puede navegarse a la pantalla, por lo que redirecciona a home.
 * <p>
 * Si la funcionalidad esta activa:
 * <p>
 * <ul>
 *  <li>Si no hay un objeto <code>Reservation</code> con un <code>Process</code> en sesion, se redirecciona a home.</li>
 *  <li>Si el proceso seleccionado no es virtual, no es requerida la seleccion de departamento, se redirecciona a home..</li>
 *  <li>Si el proceso es virtual, se permite navegar a la pantalla, por lo que retorna true</li>
 * </ul>
 */
@Injectable()
export class CanNavigateDepartmentSelectionGuard implements CanActivate {

  constructor(private router: Router,
              private configService: ConfigService,
              private reservationService: ReservationService) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree {
    const departmentSelectionEnabled = this.configService.getDepartmentSelectionEnabled();
    if (departmentSelectionEnabled) {
      const booking = this.reservationService.getReservation();
      if (booking) {
        const process = booking.process;
        if (process && isVirtualBooking(booking.selectedAttention?.attentionType)) {
          return true;
        }
      }
    }
    return this.router.parseUrl(Paths.home);
  }
}
