import { AbstractControl, ValidatorFn } from '@angular/forms';
import { validatePattern } from '../../../utils/validators/validatePattern';

export function phoneNumberValidator(patternPhoneRule: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = control.value;
    let valid = validatePattern(phoneNumber, patternPhoneRule);
    return !valid ? { 'phoneNumberFormat': true } : null;
  };
}
