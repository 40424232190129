import { validatePattern } from './validatePattern';

export const DocumentValidators = (documentType) => {
  return customValidators[documentType] || validatePattern;
};

const customValidators = {
  'RUT': (document, pattern) => validateRUT(document, pattern)
};

const validateRUT = (document, pattern) => {
  if (!validatePattern(document, pattern)) {
    return false;
  }

  const cleanRut = document.toUpperCase().replace(/\./gi, '').replace('-', '');
  const rutNumber = cleanRut.substring(0, cleanRut.length - 1);
  const rutVerificationDigit = cleanRut.charAt(cleanRut.length - 1);

  return expectedValidationDigit(rutNumber) == rutVerificationDigit;
};

const expectedValidationDigit = (rutNumber) => {
  let M = 0, S = 1;
  for (; rutNumber; rutNumber = Math.floor(rutNumber / 10))
    S = (S + rutNumber % 10 * (9 - M++ % 6)) % 11;
  return S ? S - 1 : 'K';
};

