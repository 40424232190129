import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ModalsComponent } from '../modals.component';

@Component({
  selector: 'app-single-button-modal',
  templateUrl: './single-button-modal.component.html'
})
export class SingleButtonModalComponent implements AfterViewInit {

  @ViewChild(ModalsComponent) defaultModal: ModalsComponent;

  type: ModalTypeNotification;
  @Input() onClickCallback: () => void;
  @Input() headerMessage: string;
  @Input() bodyMessage: string;
  @Input() hideOnBackgroundClick = false;

  ngAfterViewInit() {
    if (!this.hideOnBackgroundClick) {
      this.defaultModal['onContainerClicked'] = () => {return;};
    }
  }

  success = () => {
    this.type = ModalTypeNotification.SUCCESS;
    this.defaultModal.show();
  };

  warning = () => {
    this.type = ModalTypeNotification.WARNING;
    this.defaultModal.show();
  };

  error = () => {
    this.type = ModalTypeNotification.ERROR;
    this.defaultModal.show();
  };

  onClick = () => {
    this.defaultModal.hide();

    if (this.onClickCallback) {
      this.onClickCallback();
    }
  };
}

export class ModalTypeNotification {
  public static SUCCESS = new ModalTypeNotification('fas fa-check-circle fa-4x text-success');
  public static WARNING = new ModalTypeNotification('fas fa-exclamation-triangle fa-4x text-warning');
  public static ERROR = new ModalTypeNotification('fas fa-times-circle fa-4x text-danger');

  iconClass: string;

  constructor(iconClass: string) {
    this.iconClass = iconClass;
  }
}
