<app-modal #modal [modalId]="modalId">
  <div class="modal-body">
    <div class="pb-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-backdrop="false" (click)="modal.hide()">
        <span class="fas fa-times"></span>
      </button>
    </div>
    <div class="pt-3">
      <h3 class="text-secondary text-center mb-3">
        ¿Está seguro que quiere salir?
      </h3>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-10 col-lg-8">
        <button id="logOutBtn" class="btn btn-block btn-main py-2" data-dismiss="modal" data-backdrop="false" (click)="logout()">
          Aceptar
        </button>
        <button id="cancelBtn" class="btn btn-block btn-link py-2" data-dismiss="modal" data-backdrop="false" (click)="modal.hide()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</app-modal>
