import {Injectable} from '@angular/core';
import {SessionStorageService} from '../session-storage/session-storage.service';
import {NotificationChannel} from '../../model/config/notificationChannel.model';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private readonly regNotificationChannels: string = 'regNotificationChannels';
  private readonly resendRegNotificationChannels: string = 'resendRegNotificationChannels';
  private readonly cancellationCodeNotificationChannels: string = 'cancellationCodeNotificationChannels';
  private readonly uniquePhoneNumberRestriction: string = 'uniquePhoneNumberRestriction';
  private readonly timeZone: string = 'timeZone';
  private readonly shouldVerifyPhoneNumber: string = 'shouldVerifyPhoneNumber';
  private readonly departmentSelectionEnabled: string = 'departmentSelectionEnabled';
  private readonly externalClientIntegrationEnabled: string = 'externalClientIntegrationEnabled';
  private readonly nearbyCustomerCentersEnabled = 'nearbyCustomerCentersEnabled';
  private readonly accessFromExternalClientIntegration = 'accessFromExternalClientIntegration';
  private readonly bookingCancellationCodeEnabled = 'bookingCancellationCodeEnabled';
  private readonly origin = 'origin';
  private readonly appBackVersionKey = 'appBackVersionKey';

  constructor(private sessionStorage: SessionStorageService) {
  }

  setTimeZone(timeZone: string): void {
    this.sessionStorage.setItem(this.timeZone, timeZone);
  }

  getTimeZone(): string {
    return this.sessionStorage.getItem(this.timeZone);
  }

  setRegistrationNotificationChannels(channels: NotificationChannel[]): void {
    this.sessionStorage.setItem(this.regNotificationChannels, channels);
  }

  getRegistrationNotificationChannels(): NotificationChannel[] {
    return this.sessionStorage.getItem(this.regNotificationChannels);
  }

  setResendRegistrationNotificationChannels(channels: NotificationChannel[]): void {
    this.sessionStorage.setItem(this.resendRegNotificationChannels, channels);
  }

  getResendRegistrationNotificationChannels(): NotificationChannel[] {
    return this.sessionStorage.getItem(this.resendRegNotificationChannels);
  }

  setCancellationCodeNotificationChannels(channels: NotificationChannel[]): void {
    this.sessionStorage.setItem(this.cancellationCodeNotificationChannels, channels);
  }

  getCancellationCodeNotificationChannels(): NotificationChannel[] {
    return this.sessionStorage.getItem(this.cancellationCodeNotificationChannels);
  }

  setUniquePhoneNumberRestriction(enabled: boolean) {
    this.sessionStorage.setItem(this.uniquePhoneNumberRestriction, (enabled) ? enabled : false);
  }

  getUniquePhoneNumberRestriction(): boolean {
    return this.sessionStorage.getItem(this.uniquePhoneNumberRestriction);
  }

  setShouldVerifyPhoneNumber(enabled: boolean) {
    this.sessionStorage.setItem(this.shouldVerifyPhoneNumber, (enabled) ? enabled : false);
  }

  getShouldVerifyPhoneNumber(): boolean {
    return this.sessionStorage.getItem(this.shouldVerifyPhoneNumber);
  }

  setDepartmentSelectionEnabled(enabled: boolean) {
    this.sessionStorage.setItem(this.departmentSelectionEnabled, enabled);
  }

  getDepartmentSelectionEnabled(): boolean {
    return this.sessionStorage.getItem(this.departmentSelectionEnabled);
  }

  setExternalClientIntegrationEnabled(enabled: boolean): void {
    this.sessionStorage.setItem(this.externalClientIntegrationEnabled, enabled ? enabled : false);
  }

  getExternalClientIntegrationEnabled(): boolean {
    return this.sessionStorage.getItem(this.externalClientIntegrationEnabled);
  }

  setAccessFromExternalClientIntegration(flag: boolean): void {
    this.sessionStorage.setItem(this.accessFromExternalClientIntegration, flag);
  }

  getAccessFromExternalClientIntegration(): boolean {
    const flag = this.sessionStorage.getItem(this.accessFromExternalClientIntegration);
    return flag ? flag : false;
  }

  setNearbyCustomerCentersEnabled(enabled: boolean): void {
    this.sessionStorage.setItem(this.nearbyCustomerCentersEnabled, enabled);
  }

  getNearbyCustomerCentersEnabled(): boolean {
    return !!this.sessionStorage.getItem(this.nearbyCustomerCentersEnabled);
  }

  setBookingCancellationCodeEnabled(flag: boolean): void {
    this.sessionStorage.setItem(this.bookingCancellationCodeEnabled, flag ? flag : false);
  }

  getBookingCancellationCodeEnabled(): boolean {
    return !!this.sessionStorage.getItem(this.bookingCancellationCodeEnabled);
  }

  setOrigin(o: string): void {
    this.sessionStorage.setItem(this.origin, o);
  }

  getOrigin(): string {
    return this.sessionStorage.getItem(this.origin);
  }

  setAppBackVersion(appVersion: string): void {
    this.sessionStorage.setItem(this.appBackVersionKey, appVersion);
  }

  getAppBackVersion(): string {
    return this.sessionStorage.getItem(this.appBackVersionKey);
  }
}
