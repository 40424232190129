<app-loading *ngIf="processing"></app-loading>
<section class="bg-two-colors bg-two-colors-media flex-grow-1 d-flex flex-column">

  <div class="bg-main">
    <app-header [title]="title"></app-header>
  </div>

  <div class="container-fluid">

    <div class="pt-4">
      <div class="bg-main container">
        <div class="col-lg-8 mx-auto text-white-50">
          <h4>{{'attentionType.title' | translate}}</h4>
        </div>
      </div>

      <div class="bg-main container">
        <div class="col-lg-8 mx-auto text-white text-wrap text-break">
          <h3>{{reservation.process.name}}</h3>
        </div>
      </div>

      <div class="bg-main container">
        <div class="col-lg-8 mx-auto pt-4 text-title">
          <h1 class="text-left text-white-50">{{'attentionType.subtitle' | translate}}</h1>
        </div>
      </div>
    </div>

    <div class="p-1">
      <ng-container *ngFor="let at of processAttentionTypes">
        <div class="buttonsHeight pt-2">
          <div class="container h-100">
            <div class="h-100 p-2 col-lg-7 mx-auto">
              <ng-container *ngIf="shouldShowButton(at); else showHyperlink">
                <button type="button"
                        class="btn btn-light rounded-box shadow w-100 h-100"
                        [ngClass]="{'disabled': !supportsAttentionTypeOf(at)}"
                        (click)="typeSelection(at)">
                <span class="w-50 button-font-size icon"
                      [ngClass]="retrieveIconFor(at)"></span>
                  <br> {{returnPlaceholderFor(at) | translate}}
                </button>
              </ng-container>
              <ng-template #showHyperlink>
                <div class="p-3">
                  <div class="buttonsHeight pt-2">
                    <div class="container h-100">
                      <a href="javascript:" (click)="typeSelection(at)">
                        <h3 class="text-center font-weight-bold text-info">Quiero visitar un centro de atención
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</section>
