export class ICSContent {
  email: string;
  from: Date;
  durationInMinutes: number;
  summary: string;
  description: string;
  location: string;

  constructor(email: string,
              from: Date,
              duration: number,
              summary: string,
              description: string,
              location: string) {
    this.email = email;
    this.from = from;
    this.durationInMinutes = duration;
    this.summary = summary;
    this.description = description;
    this.location = location;
  }
}
