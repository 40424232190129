<app-loading *ngIf="resending"></app-loading>
<app-modal-cancel-booking></app-modal-cancel-booking>
<div *ngIf="reservation" class="d-flex flex-column">
  <div class="rounded-box mt-4 d-flex flex-column justify-content-center w-100">
    <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <div class="bg-white rounded-box shadow-custom overflow-hidden p-5 w-100">

        <ng-container *ngIf="!resendRequested"> <!--!resendRequested-->
          <div class="rounded-box details-box p-lg-4 m-lg-3">
            <ng-container *ngIf="hasChannels()">
              <h3 class="mb-3 mt-3">
                {{'details.title' | translate}}
              </h3>
              <div class="d-flex justify-content-center">
                <div class="bg-grey rounded-box my-2 p-3 fit-content d-flex align-self-center">
                  {{detailInfo}}
                  <ng-container *ngIf="hasContactInfo()">
                    ({{contactInfo}})
                  </ng-container>
                </div>
              </div>
              
            </ng-container>
  
            <div class="flex-grow-1 d-flex py-2 justify-content-center">
              <div class="col-lg-8 my-2 d-flex flex-grow-1 text-left justify-content-center p-0">
                <span class="color-dot mr-2 mt-2 text-nowrap"></span>
                <div class="d-flex w-auto">
                  <p [innerHTML]="(isVirtualBooking ? 'details.virtualReminder' : 'details.reminder') | translate"
                      class="text-light-16"></p>
                </div>
              </div>
            </div>
          </div>
          

        </ng-container> <!--!resendRequested-->

        <ng-container *ngIf="resendRequested && !resending"> <!--resendRequested && !resending-->
          <div class="rounded-box details-box p-lg-4 m-lg-3">
            <ng-container *ngIf="messageSent; else retryMessage">
                <span class="icon icon-64 icon-check align-items-center w-auto"></span>
                <h3 class="mb-2 mt-3">¡Listo!</h3>
                <h3>{{'details.forwarded' | translate}}</h3>
            </ng-container>
            <ng-template #retryMessage>
              <span class="far fa-times-circle fa-4x text-danger my-2"></span>
              <h3 class="text-danger">
                {{retryMessage}}
                <a href="" (click)="retryResendNotification($event)"
                  class="font-weight-bold text-danger">{{'details.retry.here' | translate}}</a>
              </h3>
            </ng-template>

            <div *ngIf="showLowerInfo && hasRegisterNotificationChannels() && messageSent && !isVirtualBooking" class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <p class="text-center text-main mt-3 pt-4" [innerHTML]="lowerInfo"></p>
              </div>
            </div>
          </div>
        </ng-container> <!--resendRequested && !resending-->
        <div class="d-lg-none">
          <ng-container *ngIf="hasChannels()">
            <button class="btn btn-main btn-xl col-10 col-md-6 mt-3"
                    (click)="resendNotification()"
                    [disabled]="resending"
                    *ngIf="!resendRequested">
              {{resendButtonMessage}}
            </button>
          </ng-container>
          <app-booking-actions [showCancelModalCallback]="showCancelModal" [isCreating]="false"></app-booking-actions>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-flex justify-content-end">
      <app-booking-actions  [showCancelModalCallback]="showCancelModal" [isCreating]="false"></app-booking-actions>
      <ng-container *ngIf="hasChannels()">
        <button class="btn btn-main btn-xl col-5 mt-4"
                (click)="resendNotification()"
                [disabled]="resending"
                *ngIf="!resendRequested">
          {{resendButtonMessage}}
        </button>
      </ng-container>
    </div>
  </div>
</div>

