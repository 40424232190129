import { BookingRegisteredCalendarService } from '../../../../utils/calendar/booking-registered-calendar.service';
import { ExternalClientService } from '../../../../services/externalClient/external-client.service';
import { DeviceDetectorService } from '../../../../utils/device-detector.service';
import { ExternalClientChannel } from '../../../../services/externalClient/model/ExternalClientChannel';
import { CPEOuterFunctionService } from '../../../../utils/cpe-outer-function.service';
import { IllegalComponentBehaviourNotifier } from '../../../../utils/illegal-component-behaviour-notifier.service';

export class CPEBookingSuccessButtons {

  constructor(public bookingRegisteredCalendarService: BookingRegisteredCalendarService,
              public externalClientService: ExternalClientService,
              public deviceDetectorService: DeviceDetectorService,
              public cpeOuterFunctionService: CPEOuterFunctionService,
              public icbNs: IllegalComponentBehaviourNotifier) { }

  /**
   * Solo mostrar el botón finalizar cuando sea una integración external, el cliente provenga de una APP nativa
   * y el dispoitivo sea Android o IOS
   */
  showFinalizeButton(): boolean {
    if (this.externalClientService.isValidForExternalClientBookingOperation()) {
      if (this.externalClientService.clientChannel() === ExternalClientChannel.NATIVE_APP) {
        return this.deviceDetectorService.isAndroid() || this.deviceDetectorService.isIOS();
      }
    }
    return false;
  }

  finalize() {
    if (this.deviceDetectorService.isAndroid()) {
      this.cpeOuterFunctionService.callAndroidOuterFunction();
    } else if (this.deviceDetectorService.isIOS()) {
      this.cpeOuterFunctionService.callIOSOuterFunction();
    } else {
      this.icbNs.notify(
        'Accesso illegal botón Finalizar CPE',
        'CPEBookingSuccessButtons',
        `Se accede al botón finalizar para un dispositivo distinto de Android o IOS: ${this.deviceDetectorService.getDeviceType()}`);
    }
  }

  addToCalendar(): void {
    this.bookingRegisteredCalendarService.download();
  }

  showAddToCalendarButton(): boolean {
    return !this.externalClientService.isExternalClientMobileAppIntegration();
  }
}
