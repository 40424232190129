<section class="flex-grow-1 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column py-2">
    <div class="container-xl flex-grow-1 d-flex">
      <div class="row flex-grow-1 h-100">
        <div class="col h-100 d-flex flex-column d-flex justify-content-between">
          <div class="error-cont flex-grow-1 d-flex align-items-center justify-content-center flex-column py-3">
            <span class="fas fa-times-circle fa-4x text-danger"></span>
            <h2 class="my-3 text-danger font-weight-light">
              {{ title ? title : 'error.title' | translate}}
            </h2>
            <h3 class="text-center">
              {{ message ? message : 'error.message' | translate }}
            </h3>
            <ng-container *ngIf="this.showExitButton">
              <button class="btn btn-main btn-xl py-2 px-5 mt-4" (click)="exit()">
                Salir
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
