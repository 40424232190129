import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { ReminderResponse } from 'src/app/model/booking/reminderResponse.model';
import { Paths } from 'src/app/global';

@Component({
  selector: 'app-confirmation-expired',
  templateUrl: './confirmation-expired.component.html'
})
export class ConfirmationExpiredComponent implements OnInit {
  reminderInformation: ReminderResponse;
  date: Date;
  canceled: boolean;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    
    this.reminderInformation = this.sessionStorageService.getItem('reminderInfo');
    if(!this.reminderInformation){
      this.homePageRedirection();
    }
    this.infoBuilder();
  }

  infoBuilder() {
    this.date = new Date(this.reminderInformation.from);
    this.canceled = this.reminderInformation.bookingStatus === 'CANCELADA';
  }

  homePageRedirection() {
    this.sessionStorageService.clearSessionStorage();
    this.router.navigate([Paths.home]);
  }

}
