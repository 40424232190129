import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DocumentType } from '../../../model/client/documenttype.model';

export function clientNameValidator(documentType: DocumentType, errorKey: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    return !(documentType.enumName !== 'CUIT' && value) ? { [errorKey]: true } : null;
  };
}
