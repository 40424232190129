<div class="bg-main bg-gradient d-flex flex-column">
  <app-header [showGoBack]="false"></app-header>
  <div class="container-xl">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
        <span class="icon icon-64 icon-white icon-check align-items-center"></span>
        <h1 class="mt-4 text-white text-center text-light">
          {{ 'autoConfirmation.successTitle' | translate }}
        </h1>
      </div>
    </div>
  </div>
</div>
<section class="bg-two-colors  bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="d-flex flex-column">
            <div class="rounded-box mt-4 d-flex flex-column justify-content-center w-100">
              <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
                <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
                  <div class="bg-white rounded-box shadow-custom overflow-hidden p-2 mb-2 h-100 w-100 flex-grow-1">
                    <div class="flex-grow-1 d-flex flex-column mb-3">
                      <div class="container-xl">
                        <div class="row justify-content-center">
                          <div class="col-12 col-lg-8">
                            <div class="px-4 py-5 mb-3 mt-4 mt-lg-5 w-100 d-flex flex-column justify-content-center">
                              <div class="p-3">
                                <h1 [innerHtml]="('autoConfirmation.readyTitle' | translate )"></h1>
                              </div>
                              <div class="p-3">
                                <h1>{{ 'autoConfirmation.fullReminder' | translate }}</h1>
                              </div>
                              <div class="pt-4">
                                <p>{{ 'autoConfirmation.willWaiting' | translate }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center pb-5">
                      <div class="col-8">
                        <a href="#" (click)="homePageRedirection()" class="btn btn-link btn-xl px-4 text-grey">
                          {{ 'autoConfirmation.backToHome' | translate }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
