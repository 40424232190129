import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})

export class GTMClient {

  constructor(private gtm: GoogleTagManagerService) {
  }

  public pushEvent(event: any): void {
    this.gtm.pushTag(event);
  }
}
