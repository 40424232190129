import { GTMEvent } from "../gtm-event";

/**
 * Propiedades comunes de Entel CL de eventos GTM
 */
export class EntelCLGTMEvent extends GTMEvent {
    origen: string;
    segmento: string;
    mercado: string;
    page_owner: string;
    page_type: string;
    form_step_name: string;
    journey_type: string;
}