<div class="row justify-content-end mt-3">
  <div
    class="col-12 flex-grow-1 d-none d-lg-flex flex-row-reverse justify-content-start align-items-center mb-3 btn-cont">
    <button *ngIf="!showSaveOnCalendar"
            class="btn btn-main btn-xl justify-content-center d-flex align-items-center col-5"
            (click)="backToHome()">
      Volver al inicio
    </button>
    <button *ngIf="showSaveOnCalendar"
            class="btn btn-main btn-xl justify-content-center d-flex align-items-center col-5"
            (click)="saveOnCalendar()">
      <span class="icon icon-lg icon-save-on-calendar text-white d-flex align-self-center"></span>
      Agregar a tu calendario
    </button>
    <app-booking-actions *ngIf="showCancelButton"
                         [showCancelModalCallback]="showCancelModal"
                         [isCreating]="false"></app-booking-actions>
  </div>
</div>
