<div class="w-100 my-4"></div>
<div *ngIf="canEdit(); then cancellation; else creation"></div>
<ng-template #cancellation>
  <div class="d-flex justify-content-center">
    <div
      class="col-12 flex-grow-1 d-flex flex-column flex-lg-row-reverse justify-content-center align-items-strecth mb-lg-4 btn-cont">
      <a (click)="executeCancellation()" href="#" class="btn btn-link btn-xl py-2 px-4" data-toggle="modal" data-target="#modalcanceldate" data-backdrop="false">
        {{'buttons.cancelBooking' | translate}}
      </a>
    </div>
  </div>
</ng-template>
<ng-template #creation>
  <div class="row justify-content-center justify-content-lg-end">
    <div
      class="col-10 m-3 flex-grow-1 d-flex flex-column flex-lg-row-reverse justify-content-center justify-content-lg-start align-items-strecth align-items-md-center mb-3 btn-cont">
      <button class="btn btn-main btn-xl col-12 col-md-8" (click)="createEvent()" [disabled]="disableActions">
        {{'buttons.confirm' | translate}}
      </button>
    </div>
  </div>
</ng-template>
