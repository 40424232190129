import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';
import { ReservationService } from '../../services/reservation/reservation.service';
import { BookingsService } from '../../services/bookings/bookings.service';
import { Location } from '../../model/locations/location.model';
import { Reservation } from '../../model/reservation/reservation.model';
import { finalize } from 'rxjs/operators';
import { LocationService } from '../../services/customerAttention/location.service';
import { ConfigService } from '../../services/config/config.service';
import { ExternalClientService } from '../../services/externalClient/external-client.service';
import { customizations } from '../../../web-customizations/customizations';

@Component({
  selector: 'app-customer-center',
  templateUrl: './customer-center.component.html'
})
export class CustomerCenterComponent implements OnInit {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly title: string = 'Centro de Atención';
  customerCenters: CustomerCenter[] = [];
  locations: Location[] = [];
  selectedLocation: Location;
  selectedCustomerCenter: CustomerCenter;
  loadingLocations: boolean;
  loadingCustomerCenters: boolean;
  attentionId: number;
  reservation: Reservation;
  showProcessInformation: boolean;
  attentionType: string;
  shouldDisplayLocationsSelector = true;
  locationGranted = false;

  private readonly includeZoneInAddress = customizations.customerCenters.includeZoneInAddress;

  constructor(private router: Router,
              private configService: ConfigService,
              private locationService: LocationService,
              private reservationService: ReservationService,
              private bookingsService: BookingsService,
              private externalClientService: ExternalClientService
  ) {
  }

  ngOnInit(): void {
    this.showLoadingLocations();

    if (this.isNearbyCustomerCentersEnabled()) {
      this.locationService.validateAndExecute(
        () => this.locationGranted = true,
        () => this.locationGranted = false
      );
    } else {
      this.locationGranted = false;
    }

    this.loadInitialStatus();
  }

  private loadInitialStatus() {
    this.reservation = this.reservationService.getReservation();
    this.setAttentionType(this.reservation.selectedAttention?.attentionType);
    this.showProcessInformation = this.reservationService.getProcessInformation();
    this.loadInitialLocations();
  }

  private loadInitialLocations() {
    this.showLoadingLocations();
    this.attentionId = this.reservation.selectedAttention?.id;
    this.bookingsService
      .getLocations(this.attentionId)
        .pipe(finalize(this.hideLoadingLocations))
      .subscribe(
        response => {
          this.resolveLocations(response);
        },
        this.goToErrorPage
      );
  }

  onSelectLocation(newSelectedLocation: Location) {
    this.selectedLocation = newSelectedLocation;
    this.selectedCustomerCenter = null;
    this.loadAvailableCustomerCenters();
  }

  isLoadingData() {
    return this.loadingLocations || this.loadingCustomerCenters;
  }

  private resolveLocations(response: Location[]) {
    if (this.listExistsAndHaveItems(response)) {
      if (this.listHasSingleItem(response)) {
        this.selectedLocation = response[0];
        this.locations = response;
        if (this.isProcessAttentionTypeFaceToFace()) {
          this.loadAvailableCustomerCenters();
        } else {
          this.resolveCustomerCentersForVirtualProcess();
        }
      } else {
        this.locations = this.sortByName(response);
      }
    } else {
      this.goToErrorPage();
    }
  }

  private resolveCustomerCentersForVirtualProcess() {
    this.showLoadingCustomerCenters();
    this.bookingsService
      .getCustomerCentersByLocationAndProcess(this.selectedLocation.id, this.reservation.selectedAttention?.id)
      .pipe(finalize(this.hideLoadingCustomerCenters))
      .subscribe(customerCentersResponse => {
          if (this.listExistsAndHaveItems(customerCentersResponse)) {
            if (this.listHasSingleItem(customerCentersResponse)) {
              this.updateReservationCustomerCenter(customerCentersResponse[0]);
              this.goToBookingSchedulePage(true);
            } else {
              this.shouldDisplayLocationsSelector = false;
              this.customerCenters = this.sortByName(customerCentersResponse);
            }
          } else {
            this.goToErrorPage();
          }
        },
        this.goToErrorPage
      );
  }

  private sortByName(response: any[]) {
    return response.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  private loadAvailableCustomerCenters() {
    this.showLoadingCustomerCenters();
    const locationId = this.selectedLocation.id;
    this.bookingsService
      .getCustomerCentersByLocationAndProcess(locationId, this.attentionId)
      .pipe(finalize(this.hideLoadingCustomerCenters))
      .subscribe(response => {
          if (this.listExistsAndHaveItems(response)) {
            this.customerCenters = this.sortByName(response);
          } else {
            this.goToErrorPage();
          }
        },
        this.goToErrorPage
      );
  }

  private updateReservationCustomerCenter(customerCenter: CustomerCenter) {
    this.reservation.customerCenter = customerCenter;
    this.reservation.reservationDate = null;
    this.reservationService.setReservation(this.reservation);
  }

  public isProcessAttentionTypeFaceToFace(): boolean {
    return this.attentionType === 'FACE_TO_FACE';
  }

  private setAttentionType(attentionType: string) {
    this.attentionType = attentionType;
  }

  private listExistsAndHaveItems(l: any[]): boolean {
    return l?.length > 0;
  }

  private listHasSingleItem(l: any[]): boolean {
    return l.length === 1;
  }

  private showLoadingLocations = () => {
    this.loadingLocations = true;
  }

  private hideLoadingLocations = () => {
    this.loadingLocations = false;
  }

  private showLoadingCustomerCenters = () => {
    this.loadingCustomerCenters = true;
  }

  private hideLoadingCustomerCenters = () => {
    this.loadingCustomerCenters = false;
  }

  private goToErrorPage = () => {
    this.router.navigate([Paths.error]);
  }

  private goToBookingSchedulePage = (removeThisPageFromNavigation: boolean) => {
    this.router.navigate([Paths.bookingSchedule], {replaceUrl: removeThisPageFromNavigation});
  }

  customerCenterId = (customerCenter: CustomerCenter): number => {
    return customerCenter.id;
  }

  customerCenterName = (customerCenter: CustomerCenter): string => {
    return customerCenter.name;
  }

  customerCenterAddress = (customerCenter: CustomerCenter): string => {
    return CustomerCenter.buildFullAddress(customerCenter, this.includeZoneInAddress);
  }

  selectCustomerCenter(customerCenter: CustomerCenter): void {
    this.selectedCustomerCenter = customerCenter;
  }

  isCustomerCenterSelected(): boolean {
    return !!this.selectedCustomerCenter;
  }

  confirmSelection(): void {
    this.updateReservationCustomerCenter(this.selectedCustomerCenter);
    this.goToBookingSchedulePage(false);
  }

  goToNearbyCustomerCenters(deleteFromHistory: boolean): boolean {
    this.router.navigate([Paths.nearbyCustomerCenter], {replaceUrl: deleteFromHistory}).then(() =>
      location.reload());
    return false;
  }

  isNearbyCustomerCentersEnabled() {
    return this.configService.getNearbyCustomerCentersEnabled();
  }

  isExternalClientMobileIntegration(): boolean {
    return this.externalClientService.isExternalClientMobileAppIntegration();
  }
}
