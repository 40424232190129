export class ConfirmBookingRequest {

  docType: string;
  docNumber: string;

  constructor(docType: string, docNumber: string) {
    this.docType = docType;
    this.docNumber = docNumber;
  }
}
