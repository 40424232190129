import { Component, HostBinding, OnInit } from '@angular/core';
import { Client } from 'src/app/model/client/client.model';
import { ClientService } from '../../services/client/client.service';
import { Reservation } from 'src/app/model/reservation/reservation.model';
import { Process } from '../../model/process/process.model';

@Component({
  selector: 'app-active-booking',
  templateUrl: './active-booking.component.html'
})
export class ActiveBookingComponent implements OnInit {
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';
  reservation: Reservation;
  client: Client;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.client = this.clientService.getClient();
    this.reservation = new Reservation();
    this.reservation.client = this.client;
    this.reservation.process = new Process();
  }
}
