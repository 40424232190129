import { Component, HostBinding, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Term } from '../../model/termsAndConditions/term.model';
import { Paths } from '../../global';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client/client.service';
import { ReservationService } from '../../services/reservation/reservation.service';
import { customizations } from '../../../web-customizations/customizations';
import { TermsAndConditionsService } from '../../utils/terms-and-conditions.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.css']
})
export class TermsAndConditionsComponent implements OnInit {
  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  readonly shouldShowBusinessHeader = customizations.termsAndConditions.showBusinessHeader;

  endOfScroll = false;
  terms: Term[] = [];
  readAndAccepted = false;
  processing = true;

  constructor(private clientService: ClientService,
              private reservationService: ReservationService,
              private router: Router,
              private location: Location,
              private termsAndConditionsService: TermsAndConditionsService) {
  }

  ngOnInit(): void {
    this.clientService.setHasAcceptedTermsAndConditions(this.readAndAccepted);
    this.loadTermsAndConditions();
  }

  private loadTermsAndConditions() {
    this.processing = false;

    this.termsAndConditionsService.retrieveTermsAndConditions().subscribe(
      response => {
        if (response?.length > 0) {
          this.terms = response;
        } else {
          this.router.navigate([Paths.error]);
        }
      },
      () => this.router.navigate([Paths.error])
    );
  }

  acceptTermsAndConditions() {
    if (this.readAndAccepted) {
      this.clientService.setHasAcceptedTermsAndConditions(true);
      this.clientService.clearNewClient();
      this.clientService.clearPhoneVerification();
      this.reservationService.clearReservation();
      this.router.navigate([Paths.registration]);
    }
  }

  goBack() {
    this.location.back();
  }

  removeColorStyle(event: Event) {
    let element = <HTMLElement>event.target;
    this.endOfScroll = (element.offsetHeight + element.scrollTop >= element.scrollHeight);
  }
}
