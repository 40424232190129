<header class="text-white">
  <div class="container-fluid">
    <div class="py-2">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-between pt-3">
            <div class="flex-even">
              <a href="" (click)="goBack()" *ngIf="showGoBack">
                <span class="fas fa-arrow-left fa-lg text-white logout-label"> </span>
                <span *ngIf="this.shouldShowGoBackLabel()" class="ml-1 text-white"> Editar </span>
              </a>
            </div>
            <div class="d-flex align-items-center justify-content-center">
            </div>
            <div class="flex-even d-flex justify-content-end">
              <div *ngIf="this.shouldShowLogoutButton()"
                   class="d-flex text-right text-white justify-content-end fit-content p-0" (click)="showLogoutModal()">
                <div class="text-right mr-2 logout-label">
                  <span data-toggle="modal" data-target="#modalsession">{{'header.exit' | translate}}</span>
                </div>
                <div class="d-flex text-right justify-content-end logout-label" data-toggle="modal"
                     data-target="#modalsession">
                  <span class="icon icon-file-export-solid-2 w-auto text-white"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<app-modal-logout></app-modal-logout>
