import { ErrorHandler, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from '../../model/client/loginResponse.model';
import { NewBookingRequest } from './http/NewBookingRequest';
import { Client } from '../../model/client/client.model';
import { RescheduleBookingRequest } from './http/RescheduleBookingRequest';
import { Location } from '../../model/locations/location.model';
import { CustomerCenter } from '../../model/customerAttention/customerCenter.model';
import { ProcessesResponse } from '../../model/process/processesResponse.model';
import { ConfigResponse } from '../../model/config/configResponse.model';
import { InitialAvailability } from '../../model/reservation/initialAvailability.model';
import { AvailableTimesResponse } from '../../model/reservation/availableTimesResponse.model';
import { VerificationCode } from 'src/app/model/sms/verificationCode.model';
import { PhoneVerificationRequest } from 'src/app/model/sms/phoneVerificationRequest.model';
import { ReminderResponse } from 'src/app/model/booking/reminderResponse.model';
import { ActiveBookingByPhoneNumberResponse } from '../../model/booking/activeBookingByPhoneNumberResponse';
import { AreasService } from '../areas/areas.service';
import { CancelBookingRequest } from './http/CancelBookingRequest';
import { ConfirmBookingRequest } from './http/ConfirmBookingRequest';
import { FindByPhoneNumberRequest } from './http/FindByPhoneNumberRequest';
import { ResendNotificationRequest } from './http/ResendNotificationRequest';
import { ValidateClientAndBookingRequest } from './http/ValidateClientAndBookingRequest';
import { LoadAvailableTimesRequest } from './http/LoadAvailableTimesRequest';
import { LoadAvailabilityRequest } from './http/LoadAvailabilityRequest';
import { BookingsRecaptchaV3ValidatorResponse } from '../../model/captcha/BookingsRecaptchaV3ValidatorResponse';
import { BookingsRecaptchaV3ValidatorRequest } from '../../model/captcha/BookingsRecaptchaV3ValidatorRequest';
import { DepartmentsResponse } from './http/DepartmentsResponse';
import { NearbyCustomerCenter } from '../../model/customerAttention/nearbyCustomerCenter.model';
import { NearbyCustomerCenterRequest } from './http/NearbyCustomerCenterRequest';
import { Coordinate } from '../../model/customerAttention/coordinate.model';
import { NewExternalClientBookingRequest } from './http/NewExternalClientBookingRequest';
import { BookingCancellationCodeRequest } from './http/BookingCancellationCodeRequest';
import { BookingCancellationCodeResponse } from './http/BookingCancellationCodeResponse';
import { BookingCancellationByCodeRequest } from './http/BookingCancellationByCodeRequest';
import { BookingCancellationByCodeResponse } from './http/BookingCancellationByCodeResponse';
import { CancelExternalClientBookingRequest } from './http/CancelExternalClientBookingRequest';
import { ExternalClientBookingCancellationCodeRequest } from './http/ExternalClientBookingCancellationCodeRequest';
import { ExternalClientBookingCancellationByCodeRequest } from './http/ExternalClientBookingCancellationByCodeRequest';
import { BookingResponse } from '../../model/booking/booking.response';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private readonly clientServiceURL = environment.services.client.login;
  private readonly cancelBookingUrl = environment.services.bookings.cancel;
  private readonly cancelExternalClientBookingUrl = environment.services.externalClient.cancelBooking;
  private readonly confirmBookingUrl = environment.services.bookings.confirm;
  private readonly saveBookingURL = environment.services.bookings.save;
  private readonly registerExternalClientBookingUrl = environment.services.externalClient.registerBooking;
  private readonly editBookingURL = environment.services.bookings.edit;
  private readonly availableAppointmentURL = environment.services.bookings.getAvailableTimes;
  private readonly initialAvailabilityURL = environment.services.bookings.getInitialAvailability;
  private readonly locationsUrl = environment.services.locationsByProcess.all;
  private readonly customerCentersByLocationUrl = environment.services.customerServices.filterByLocationAndProcess;
  private readonly customerCentersByProcessUrl = environment.services.customerServices.filterByProcess;
  private readonly nearbyCustomerCenters = environment.services.customerServices.filterNearby;
  private readonly resendNotificationUrl = environment.services.bookings.resendNotification;
  private readonly activeBookingByPhoneNumberURL = environment.services.bookings.findActiveByPhoneNumber;
  private readonly findByEncryptedIdUrl = environment.services.bookings.findByEncryptedId;
  private readonly cancelBookingByEncryptedIdUrl = environment.services.bookings.cancelBookingByEncryptedId;
  private readonly confirmBookingByEncryptionIdUrl = environment.services.bookings.confirmBookingByEncryptionId;
  private readonly loadProcessesUrl = environment.services.process.loadProcesses;
  private readonly loadConfigUrl = environment.services.countryRules.loadConfig;
  private readonly generateCodeUrl = environment.services.phoneVerification.generateCode;
  private readonly validateCodeUrl = environment.services.phoneVerification.validateCode;
  private readonly resendVerificationCodeURL = environment.services.phoneVerification.resendCode;
  private readonly validateCaptchaUrl = environment.services.recaptcha.validate;
  private readonly activeDepartmentsURL = environment.services.departments.active;
  private readonly cancelBookingCodeRequestUrl = environment.services.bookings.cancelByCode.request;
  private readonly cancelBookingCodeCancelUrl = environment.services.bookings.cancelByCode.cancel;
  private readonly externalClientCancelBookingCodeRequestUrl = environment.services.externalClient.cancelByCode.request;
  private readonly externalClientBookingCodeCancelRequestUrl = environment.services.externalClient.cancelByCode.cancel;
  private readonly reminderHeaderName = 'X-Reminder-Token';

  constructor(private httpClient: HttpClient,
              private errorHandler: ErrorHandler,
              private areasService: AreasService) {
  }

  private catchErrorException = (e: any) => {
    this.errorHandler.handleError(e);
    throw e;
  }

  validateClientAndBooking(request: ValidateClientAndBookingRequest): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(this.clientServiceURL, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  cancelBooking(request: CancelBookingRequest): Observable<any> {
    return this.httpClient.post<any>(this.cancelBookingUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  cancelExternalClientBooking(request: CancelExternalClientBookingRequest): Observable<any> {
    return this.httpClient.post<any>(this.cancelExternalClientBookingUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  confirmBooking(request: ConfirmBookingRequest): Observable<any> {
    return this.httpClient.post<any>(this.confirmBookingUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  registerBooking(booking: NewBookingRequest): Observable<BookingResponse> {
    const areaHeader = this.areasService.buildAreaHeader();

    if (areaHeader) {
      return this.httpClient.post<BookingResponse>(this.saveBookingURL, booking, { headers: areaHeader })
        .pipe(catchError(e => this.catchErrorException(e)));
    }

    return this.httpClient.post<BookingResponse>(this.saveBookingURL, booking)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  registerExternalClientBooking(request: NewExternalClientBookingRequest): Observable<BookingResponse> {
    const areaHeader = this.areasService.buildAreaHeader();
    if (areaHeader) {
      return this.httpClient
        .post<BookingResponse>(this.registerExternalClientBookingUrl, request, { headers: areaHeader });
    }
    return this.httpClient.post<BookingResponse>(this.registerExternalClientBookingUrl, request);
  }

  rescheduleBooking(client: Client, request: RescheduleBookingRequest): Observable<any> {
    return this.httpClient.post(this.editBookingURL(client.documentType.enumName, client.documentNumber), request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  loadAvailableTimes(request: LoadAvailableTimesRequest): Observable<AvailableTimesResponse> {
    return this.httpClient.post<AvailableTimesResponse>(this.availableAppointmentURL, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  getLocations(attentionId: number): Observable<Location[]> {
    return this.httpClient.get<Location[]>(this.locationsUrl(attentionId))
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  getCustomerCentersByLocationAndProcess(locationId: number, attentionId: number): Observable<CustomerCenter[]> {
    return this.httpClient.get<CustomerCenter[]>(this.customerCentersByLocationUrl(locationId, attentionId))
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  getCustomerCentersByProcess(attentionId: number): Observable<CustomerCenter[]> {
    return this.httpClient.get<CustomerCenter[]>(this.customerCentersByProcessUrl(attentionId))
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  getNearbyCustomerCenters(pId: number, coordinates: Coordinate): Observable<NearbyCustomerCenter[]> {
    const request: NearbyCustomerCenterRequest = {
      attentionId: pId,
      origin: coordinates
    };

    return this.httpClient.post<NearbyCustomerCenter[]>(this.nearbyCustomerCenters, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  resendNotification(request: ResendNotificationRequest) {
    return this.httpClient.post(this.resendNotificationUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  findBookingByEncryptedId(encryptedBookingId: string): Observable<ReminderResponse> {
    return this.httpClient.get<ReminderResponse>(this.findByEncryptedIdUrl,
      {
        headers: {
          [this.reminderHeaderName]: encryptedBookingId
        }
      })
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  cancelBookingByEncryptedId(encryptedBookingId: string): Observable<any> {
    return this.httpClient.post(this.cancelBookingByEncryptedIdUrl, null,
      {
        headers:
          {
            [this.reminderHeaderName]: encryptedBookingId
          }
      })
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  confirmBookingByEncryptionId(encryptedBookingId: string): Observable<any> {
    return this.httpClient.post(this.confirmBookingByEncryptionIdUrl, null,
      {
        headers: {
          [this.reminderHeaderName]: encryptedBookingId
        }
      })
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  loadProcesses(): Observable<ProcessesResponse> {
    return this.httpClient.get<ProcessesResponse>(this.loadProcessesUrl)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  loadConfig(): Observable<ConfigResponse> {
    return this.httpClient.get<ConfigResponse>(this.loadConfigUrl)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  loadAvailability(request: LoadAvailabilityRequest): Observable<InitialAvailability> {
    return this.httpClient.post<InitialAvailability>(this.initialAvailabilityURL, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  getPhoneVerificationCode(phoneVerificationRequest: PhoneVerificationRequest): Observable<VerificationCode> {
    return this.httpClient.post<VerificationCode>(this.generateCodeUrl, phoneVerificationRequest)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  validateCode(phoneVerificationRequest: PhoneVerificationRequest): Observable<VerificationCode> {
    return this.httpClient.post<VerificationCode>(this.validateCodeUrl, phoneVerificationRequest)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  resendVerificationCode(phoneVerificationRequest: PhoneVerificationRequest): Observable<VerificationCode> {
    return this.httpClient.post<VerificationCode>(this.resendVerificationCodeURL, phoneVerificationRequest)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  findActiveBookingByPhoneNumber(request: FindByPhoneNumberRequest): Observable<ActiveBookingByPhoneNumberResponse> {
    return this.httpClient.post<ActiveBookingByPhoneNumberResponse>(this.activeBookingByPhoneNumberURL, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  validateCaptcha(token: string): Observable<BookingsRecaptchaV3ValidatorResponse> {
    const request = new BookingsRecaptchaV3ValidatorRequest();
    request.token = token;

    return this
      .httpClient
      .post<BookingsRecaptchaV3ValidatorResponse>(this.validateCaptchaUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  findActiveDepartments(): Observable<DepartmentsResponse> {
    return this
      .httpClient
      .get<DepartmentsResponse>(this.activeDepartmentsURL)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  requestCodeToCancel(request: BookingCancellationCodeRequest): Observable<BookingCancellationCodeResponse> {
    return this.httpClient
      .post<BookingCancellationCodeResponse>(this.cancelBookingCodeRequestUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  cancelByCode(request: BookingCancellationByCodeRequest): Observable<BookingCancellationByCodeResponse> {
    return this.httpClient
      .post<BookingCancellationByCodeResponse>(this.cancelBookingCodeCancelUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  requestExternalClientCodeToCancel(request: ExternalClientBookingCancellationCodeRequest): Observable<BookingCancellationCodeResponse> {
    return this.httpClient
      .post<BookingCancellationCodeResponse>(this.externalClientCancelBookingCodeRequestUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }

  externalClientCancelByCode(request: ExternalClientBookingCancellationByCodeRequest): Observable<BookingCancellationByCodeResponse> {
    return this.httpClient
      .post<BookingCancellationByCodeResponse>(this.externalClientBookingCodeCancelRequestUrl, request)
      .pipe(catchError(e => this.catchErrorException(e)));
  }
}
