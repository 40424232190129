import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html'
})
export class ItemListComponent<T> implements OnChanges {

  @Input() items: T[];
  @Input() identityFn: (item: T) => number;
  @Input() descriptionFn: (item: T) => string;
  @Input() detailsFn: (item: T) => string;
  @Input() locationFn: (item: T) => string;
  @Output() selectItem = new EventEmitter<T>();
  selectedItem: T;

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedItem = null;
  }

  onRadioContainerItemClick(item: T) {
    this.selectedItem = item;
    this.onSelectItem();
  }

  isItemSelected(item: T): boolean {
    if (this.selectedItem) {
      const itemIdentity = this.identityFn(item);
      const selectedItemIdentity = this.identityFn(this.selectedItem);
      return itemIdentity === selectedItemIdentity;
    }
    return false;
  }

  onSelectItem() {
    this.selectItem.emit(this.selectedItem);
  }
}
