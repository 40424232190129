<app-modal-retry-schedule></app-modal-retry-schedule>
<app-loading *ngIf="processing"></app-loading>
<section class="bg-two-colors bg-two-colors-gradient flex-grow-1 d-flex flex-column">
  <div class="bg-main bg-gradient d-flex flex-column">
    <app-header></app-header>
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column align-items-center ">
          <h1 class="mb-4 my-4 text-white text-center custom-main-title" [innerHTML]="'confirmation.title' | translate"></h1>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 d-flex flex-column py-2 shorter-gradient">
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-lg-8 px-4">
          <div class="bg-white rounded-box shadow-custom overflow-hidden px-4 py-3 mt-3">
            <div *ngIf="reservation" class="d-flex flex-column">
              <div class="mt-4 d-flex flex-column justify-content-center">
                <h3 class="text-main text-center summary-title mb-2"> {{'confirmation.summaryTitle' | translate}}</h3>
                <div class="flex-column w-auto h-auto icon-primary">
                  <div id="dateContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-schedule-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'confirmation.dateLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2 booking-date">
                          {{reservation.reservationDate | longDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="timeContainer" class="d-flex justify-content-center bd-highlight my-1">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-clock-nine-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'confirmation.timeLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2">
                          {{ reservation.reservationDate | shortTime }}.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="processContainer" class="d-flex justify-content-center bd-highlight my-1"
                       *ngIf="showProcessInformation">
                    <div class="d-flex col-12 col-sm-8">
                      <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                        <span
                          class="icon icon-lg icon-clipboard-notes-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                        <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                          {{'confirmation.reasonLabel' | translate}}
                        </p>
                      </div>
                      <div class="col-7 p-0">
                        <p class="ml-3 ml-lg-0 mt-2 text-wrap text-break">
                          {{reservation.process.name}} <br>
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container id="customerCenterContainer" *ngIf="!isVirtualBooking">
                    <div class="d-flex justify-content-center bd-highlight my-1">
                      <div class="d-flex col-12 col-sm-8">
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span
                            class="icon icon-lg icon-map-marker-alt-solid-2 d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                            {{'confirmation.customerCenterLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2">
                            {{reservation.customerCenter.name}} <br>
                            <small>{{buildCustomerCenterAddress(reservation.customerCenter)}}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container id="attentionTypeAndPhoneContainer" *ngIf="isVirtualBooking">
                    <div class="d-flex justify-content-center bd-highlight my-1">
                      <div class="d-flex col-12 col-sm-8">
                        <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                          <span
                            class="icon icon-lg icon-headphone d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                          <p class="mr-2 mt-2 col-7 col-sm-6 p-0 item-label">
                            {{'confirmation.attentionTypeLabel' | translate}}:
                          </p>
                        </div>
                        <div class="col-7 p-0">
                          <p class="ml-3 ml-lg-0 mt-2">
                            {{'confirmation.attentionTypeValue' | translate}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="this.shouldShowPhoneNumber()">
                      <div class="d-flex justify-content-center bd-highlight my-1">
                        <div class="d-flex col-12 col-sm-8">
                          <div class="d-flex col-5 ml-sm-2 ml-lg-4 justify-content-end p-0">
                            <span
                              class="icon icon-lg icon-mobile-android d-flex justify-content-center align-items-start mt-2 mr-2 mr-lg-3 w-auto col-2 py-1 p-0"></span>
                            <p class="mr-2 mt-2 col-7 col-sm-6 p-0">
                              {{hiddenPhoneNumber()}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

                <div *ngIf="messageSentFailed">
                  <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center mt-2">
                    <span class="far fa-times-circle fa-2x text-danger my-2"></span>
                    <h3 class="text-danger">{{'confirmation.retry.booking' | translate}}
                      <a href="#" (click)="confirm($event)"
                         class="font-weight-bold text-danger">{{'confirmation.retry.here' | translate}}</a>
                    </h3>
                  </div>
                </div>

                <div *ngIf="shouldShowConfirmationInfo()" class="row justify-content-center">
                  <div class="col-10 col-lg-8 info-box p-3 mt-3">
                    <h4 class="text-center text-main info-text p-1" [innerHTML]="confirmationInfo"></h4>
                  </div>
                </div>

              </div>
            </div>
            <div *ngIf="!messageSentFailed" class="d-lg-none">
              <app-booking-actions [isCreating]="true" [createEvent]="confirm"
                                   [disableActions]="processing"></app-booking-actions>
            </div>
          </div>

          <div class="d-none d-lg-block">
            <app-booking-actions [isCreating]="true" [createEvent]="confirm"
                                 [disableActions]="processing"></app-booking-actions>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
