export class ReminderResponse {
  clientName: string;
  clientLastName: string;
  clientBusinessName: string;
  bookingStatus: string;
  from: string;
  customerCenterName: string;
  customerCenterAddress: string;
  attentionType: string;
  processName: string;
  canConfirm: boolean;
}
