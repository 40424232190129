import { Joiner } from 'src/app/utils/joiners/Joiner';

export const customizations = {
  home: {
    showBusinessHeader: false,
    showDocumentPlaceholderExample: false
  },
  termsAndConditions: {
    shouldShow: true,
    showBusinessHeader: false
  },
  registration: {
    showBusinessHeader: false,
    infoMessageJoiner: Joiner.AND_OR
  },
  process: {
    showSingleList: false,
    showWhenSingleProcess: true
  },
  attentionType: {
    shouldShowButtons: {
      VIRTUAL: true,
      FACE_TO_FACE: false
    }
  },
  nearbyCustomerCenters: {
    includeZoneInAddress: false
  },
  customerCenters: {
    includeZoneInAddress: false
  },
  schedule: {
    showMainTitle: false,
    includeZoneInAddress: false
  },
  confirmation: {
    showLowerInfo: false,
    includeZoneInAddress: false
  },
  success: {
    showReminder: true,
    showVirtualReminder: true,
    showLowerInfo: false,
    showCancelButton: false,
    infoMessageJoiner: Joiner.AND,
    showSaveOnCalendar: true,
    showSurvey: true,
    includeZoneInAddress: false
  },
  active: {
    showLowerInfo: false
  },
  cancellationByCode: {
    channelsJoiner: Joiner.AND_OR
  },
  channels: {
    SMS: 'SMS',
    EMAIL: 'correo electrónico',
    WHATSAPP: 'WhatsApp'
  },
  header: {
    logout: {
      customUrl: null
    }
  },
  showFooter: false
};
