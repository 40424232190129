import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { replaceText, validatePattern } from '../Utils';

@Directive({
  selector: '[alphasOnly]',
})
export class AlphasOnlyDirective {

  @Input() control: AbstractControl;

  readonly validationPattern = new RegExp(/^[a-zA-ZñÑÁáÉéÍíÓóÚúÜ ]+$/i);
  readonly replacePattern = new RegExp(/[^a-zA-ZñÑÁáÉéÍíÓóÚúÜ ]/g);

  constructor() {
  }

  @HostListener('input')
  onInput() {
    const value = this.control.value;

    if (!validatePattern(value, this.validationPattern)) {
      this.control.setValue(replaceText(value, this.replacePattern));
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text') || '';

    if (!validatePattern(value, this.validationPattern)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
