import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../global';
import { ModalCancelBookingComponent } from '../modal-cancel-booking/modal-cancel-booking.component';
import { ReservationService } from '../../services/reservation/reservation.service';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'app-booking-actions',
  templateUrl: './booking-actions.component.html'
})
export class BookingActionsComponent {
  @ViewChild(ModalCancelBookingComponent)
  cancelModal: ModalCancelBookingComponent;

  @Input() isCreating: boolean;
  @Input() createEvent: Function;
  @Input() disableActions: boolean = false;
  @Input() showCancelModalCallback: () => void;

  constructor(private router: Router,
              private reservationService: ReservationService,
              private configService: ConfigService) {
  }

  canEdit(): boolean {
    return !this.isCreating;
  }

  edit() {
    this.reservationService.setEditing();
    this.router.navigate([Paths.customerCenter]);
  }

  executeCancellation(): void {
    if (this.shouldCancelByCode()) {
      this.navigateToCancellationByCode();
    } else {
      this.showCancelModal();
    }
  }

  private shouldCancelByCode(): boolean {
    return this.configService.getBookingCancellationCodeEnabled();
  }

  private navigateToCancellationByCode(): void {
    this.router.navigate([Paths.cancellationByCode])
  }

  showCancelModal() {
    this.showCancelModalCallback();
  }
}
