import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {

  @Input() control: AbstractControl;

  readonly validationPattern = new RegExp(/^[0-9]+$/i);
  readonly replacePattern = new RegExp(/[^0-9]/g);

  readonly validatePattern = (value): boolean => this.validationPattern.test(value);
  readonly replaceText = (value): string => value.replace(this.replacePattern, "");

  constructor() {
  }

  @HostListener('input')
  onInput() {
    const value = this.control.value;

    if (!this.validatePattern(value)) {
      this.control.setValue(this.replaceText(value));
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text') || '';

    if (!this.validatePattern(value)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
