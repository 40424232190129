import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { BookingRegisteredCalendarService } from '../../../../../utils/calendar/booking-registered-calendar.service';
import { DefaultBookingSuccessButtons } from '../default-booking-success-buttons';

@Component({
  selector: 'app-default-booking-success-desk-buttons',
  templateUrl: './default-booking-success-desk-buttons.component.html'
})
export class DefaultBookingSuccessDeskButtonsComponent extends DefaultBookingSuccessButtons {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';

  constructor(public router: Router,
              public bookingRegisteredCalendarService: BookingRegisteredCalendarService) {
    super(router, bookingRegisteredCalendarService);
  }
}
