import { Injectable } from '@angular/core';
import { ErrorPageExitButtonVisibility } from './error-page-exit-button-visibility';

@Injectable()
export class DefaultErrorPageExitButtonVisibility extends ErrorPageExitButtonVisibility {

  /**
   * La implementación por defecto. El botón siempre debe estar visible.
   */
  showExitButton(): boolean {
    return true;
  }
}
