import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-booking-cancellation-by-code',
  templateUrl: './booking-cancellation-by-code.component.html'
})
export class BookingCancellationByCodeComponent {

  @HostBinding('class') componentClass = 'flex-grow-1 d-flex flex-column';
}
