<app-loading *ngIf="processing"></app-loading>
<div class="bg-white rounded shadow-custom overflow-hidden p-4">
  <div class="d-flex flex-column">
    <div class="rounded mt-4 d-flex flex-column justify-content-center">
      <div class="w-auto m-auto">
        <div class="w-100 d-flex flex-column justify-content-center align-items-center text-center">
          <p>
            {{ 'verification.verificationDirective' | translate }} <br>
          </p>
          <p class="justify-content-center p-4 grey-text">
            ( {{ client.phoneNumber }} )
          </p>
        </div>
        <div class="input-group justify-content-center row">

          <div class="col-2">
            <input type="number" [ngModel]='code[0]'
                   class="input-code bg-grey rounded text-center" maxlength="1" readonly min="1" max="5"
            />
          </div>
          <div class="col-2">
            <input type="number" [ngModel]='code[1]'
                   class="input-code bg-grey rounded text-center" maxlength="1" readonly/>
          </div>
          <div class="col-2">
            <input type="number" [ngModel]='code[2]'
                   class="input-code bg-grey rounded text-center" maxlength="1" readonly/>
          </div>
          <div class="col-2">
            <input id="code3" type="string" [(ngModel)]='code[3]' class="input-code bg-grey rounded text-center"
                   (ngModelChange)="autoTab(3, 3, 4)" maxlength="1"
                   (keypress)="disableNanChars($event); fillNextValue($event, 3,4, 5)"/>
          </div>
          <div class="col-2">
            <input id="code4" type="string" [(ngModel)]='code[4]' class="input-code bg-grey rounded text-center"
                   (ngModelChange)="autoTab(4, 3, 5)" maxlength="1"
                   (keypress)="disableNanChars($event);fillNextValue($event, 4,5, 5)"/>
          </div>
          <div class="col-2">
            <input id="code5" type="string" [(ngModel)]='code[5]' class="input-code bg-grey rounded text-center"
                   (ngModelChange)="autoTab(5, 4, 5)" maxlength="1" 
                   (keypress)="disableNanChars($event)" />
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="text-error d-flex align-items-start justify-content-center my-3 px-1"
         *ngIf="validationCodeError">
      <h5 class="text-left"> {{'verification.invalidCode' | translate }}</h5>
    </div>
  </div>
</div>
<ng-container>
  <div class="row justify-content-center my-2">
    <div class="col-10 col-lg-8">
      <button [disabled]="!isCodeComplete" class="btn btn-block btn-main py-2 mt-3" (click)="buildAndValidateCode()">
        Confirmar
      </button>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="col-10 col-lg-8">
      <button href="" class="btn btn-link btn-xl py-2 px-4" [ngClass]="{disabled: !showResendOption}" (click)="resendVerificationCode($event)">
          {{'verification.resendMessage' | translate }}
      </button>
    </div>
  </div>
</ng-container>

