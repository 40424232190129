import { Injectable, NgZone } from '@angular/core';

declare var android: {
  closeMobileWebView: Function;
};
declare var window: any;

@Injectable({
  providedIn: 'root'
})

export class CPEOuterFunctionService {

  constructor(private ngZone: NgZone) {
  }

  callAndroidOuterFunction() {
    this.ngZone.runOutsideAngular(() => {
      android.closeMobileWebView();
    });
  }

  callIOSOuterFunction() {
    this.ngZone.runOutsideAngular(() => {
      window.webkit.messageHandlers.miclaroiOSListener.postMessage('closeMobileWebView');
    });
  }
}
